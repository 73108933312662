import { Business, Edit, Email, People } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, Card, CardContent, Chip, Divider, Grid, IconButton, Stack, Typography } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';

const statusColors = {
  client: {
    bg: 'var(--joy-palette-success-softBg)',
    color: 'var(--joy-palette-success-solidBg)',
  },
  prospect: {
    bg: 'var(--joy-palette-primary-softBg)',
    color: 'var(--joy-palette-primary-solidBg)',
  },
  partner: {
    bg: 'var(--joy-palette-neutral-softBg)',
    color: 'var(--joy-palette-neutral-solidBg)',
  },
} as const;

interface CompanyProfile {
  id: string;
  name: string;
  industry?: string;
  size?: string;
  location?: string;
  website?: string;
  description?: string;
  founded?: string;
  type?: 'client' | 'prospect' | 'partner';
  logoUrl?: string;
  activeJobs?: number;
}

interface CompanyHeaderProps {
  company: CompanyProfile;
}

export const CompanyDetailsHeader: React.FC<CompanyHeaderProps> = ({ company }) => {
  const type = company.type || 'prospect';
  const statusColor = statusColors[type];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {/* Left section: Logo */}
          <Grid xs={12} sm="auto">
            <Box
              component="img"
              src={company.logoUrl || '/placeholder-logo.png'}
              alt={`${company.name} logo`}
              sx={{
                width: { xs: 80, sm: 100 },
                height: { xs: 80, sm: 100 },
                objectFit: 'contain',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </Grid>

          {/* Middle section: Company Info */}
          <Grid xs={12} sm={true}>
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography level="h2" sx={{ fontSize: { xs: 'xl', sm: 'xl2' } }}>
                  {company.name}
                </Typography>
                <Chip
                  variant="soft"
                  size="sm"
                  sx={{
                    bgcolor: statusColor.bg,
                    color: statusColor.color,
                  }}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Chip>
              </Box>

              <Stack direction="row" spacing={2} useFlexGap divider={<Divider orientation="vertical" />}>
                {company.industry && (
                  <Typography level="body-sm" startDecorator={<Business />}>
                    {company.industry}
                  </Typography>
                )}
                {company.location && (
                  <Typography level="body-sm" startDecorator={<LocationOnIcon />}>
                    {company.location}
                  </Typography>
                )}
                {company.website && (
                  <Typography component="a" href={company.website} target="_blank" rel="noopener" level="body-sm">
                    {company.website}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>

          {/* Right section: Actions */}
          <Grid xs={12} sm="auto">
            <Stack direction="row" spacing={1} justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}>
              <Button
                variant="solid"
                color="primary"
                startDecorator={<AddIcon />}
                component={RouterLink}
                to={`/companies/${company.id}/jobs/new`}
              >
                Add Job
              </Button>
              <Button variant="outlined" color="neutral" startDecorator={<People />}>
                Add Contact
              </Button>
              <IconButton variant="outlined" color="neutral">
                <Email />
              </IconButton>
              <IconButton variant="outlined" color="neutral">
                <Edit />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

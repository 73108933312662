/**
 * Recursively removes keys with undefined values from an object
 * @param obj The object to clean
 * @returns A new object with all undefined values removed
 */
export function removeUndefinedValues<T extends Record<string, any>>(obj: T): Partial<T> {
  const result: Partial<T> = {};

  Object.entries(obj).forEach(([key, value]) => {
    // Skip undefined values
    if (value === undefined) {
      return;
    }

    // Recursively clean nested objects
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      result[key as keyof T] = removeUndefinedValues(value) as T[keyof T];
    } 
    // Handle arrays by filtering out undefined values and recursively cleaning objects
    else if (Array.isArray(value)) {
      result[key as keyof T] = value
        .filter(item => item !== undefined)
        .map(item => 
          item && typeof item === 'object' 
            ? removeUndefinedValues(item) 
            : item
        ) as T[keyof T];
    }
    // Keep non-undefined primitive values
    else {
      result[key as keyof T] = value;
    }
  });

  return result;
} 
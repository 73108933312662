import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CalendarRepository } from '../repositories/calendar.repository';
import { useCalendarStore } from '../store/calendar.store';

export const useCalendarEvents = (customStartDate?: Date, customEndDate?: Date, customUserId?: string) => {
  console.log('useCalendarEvents - Input Dates:', {
    customStartDate: customStartDate?.toISOString(),
    customEndDate: customEndDate?.toISOString(),
    customUserId,
  });

  const { currentWeek } = useCalendarStore();
  const { auth } = useFirebase();
  const { user } = useUser(auth);
  const { firestore } = useFirebase();
  const calendarRepository = useMemo(() => new CalendarRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { events, setEvents, addEvent, updateEvent, removeEvent, setIsLoading } = useCalendarStore();

  console.log('useCalendarEvents - Current State:', {
    currentWeek: currentWeek?.format('YYYY-MM-DD'),
    user: user?.uid,
    hasFirestore: !!firestore,
  });

  // Calculate start and end dates based on currentWeek if not provided
  const startDate = customStartDate || currentWeek.startOf('week').toDate();
  const endDate = customEndDate || currentWeek.endOf('week').toDate();
  const userId = customUserId || user?.uid;

  console.log('useCalendarEvents - Calculated Dates:', {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    userId,
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ['calendarEvents', { startDate, endDate, userId }],
    queryFn: () => calendarRepository.getFirestoreEvents(startDate, endDate, userId),
    enabled: !!userId,
  });

  console.log('useCalendarEvents - Query Results:', {
    hasData: !!data,
    dataLength: data?.length,
    isLoading,
    error,
    enabled: !!userId,
  });

  useEffect(() => {
    console.log('useCalendarEvents - Setting Events in Store:', data);
    if (data) {
      setEvents(data);
    }
  }, [data, setEvents]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const createEventMutation = useMutation({
    mutationFn: (newEvent: Omit<CalendarEvent, 'id'>) => calendarRepository.upsertEvent('', newEvent),
    onSuccess: (event) => {
      addEvent(event);
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    },
  });

  const updateEventMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<CalendarEvent> }) =>
      calendarRepository.upsertEvent(id, data),
    onSuccess: (event, variables) => {
      updateEvent(variables.id, event);
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    },
  });

  const deleteEventMutation = useMutation({
    mutationFn: (id: string) => calendarRepository.delete(id),
    onSuccess: (_, id) => {
      removeEvent(id);
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    },
  });

  return {
    events,
    isLoading,
    error,
    createEvent: createEventMutation.mutate,
    updateEvent: updateEventMutation.mutate,
    deleteEvent: deleteEventMutation.mutate,
  };
};

import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { TableLayout } from '@bluebird-monorepo/bluebird-ui';
import { useCompanies } from '@bluebird-monorepo/companies';
import { Company } from '@bluebird-monorepo/types';
import { Box, Pagination } from '@mui/material';
import CompaniesTableFilters from './CompaniesTableFilters';
import { CompaniesTableHeader } from './CompaniesTableHeader';
import { CompaniesTableRow } from './CompaniesTableRow';

function getComparator(order: 'asc' | 'desc', orderBy: keyof Company) {
  return order === 'desc'
    ? (a: Company, b: Company) => {
        const aValue = a[orderBy] ?? '';
        const bValue = b[orderBy] ?? '';
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    : (a: Company, b: Company) => {
        const aValue = a[orderBy] ?? '';
        const bValue = b[orderBy] ?? '';
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      };
}

function applyCompanyFilter({
  companies,
  comparator,
  filterName,
  statusFilter,
}: {
  companies: Company[];
  comparator: (a: Company, b: Company) => number;
  filterName: string;
  statusFilter: string;
}) {
  return companies
    .filter((company) => {
      const nameMatch = !filterName || company.name.toLowerCase().includes(filterName.toLowerCase());

      const statusMatch = !statusFilter || company.status?.toLowerCase() === statusFilter.toLowerCase();

      return nameMatch && statusMatch;
    })
    .sort(comparator);
}

function useTable() {
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState<keyof Company>('name');
  const [rowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');

  const onSort = useCallback(
    (column: string) => {
      const isAsc = orderBy === column && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(column as keyof Company);
    },
    [order, orderBy],
  );

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  return {
    onResetPage,
    onSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
  };
}

export function CompaniesTable() {
  const { companies } = useCompanies();
  const { control, watch } = useForm({
    defaultValues: {
      filterName: '',
      statusFilter: '',
    },
  });

  const table = useTable();
  const filterName = watch('filterName');
  const statusFilter = watch('statusFilter');

  const filteredCompanies = useMemo(() => {
    return applyCompanyFilter({
      companies,
      comparator: getComparator(table.order, table.orderBy),
      filterName,
      statusFilter,
    });
  }, [companies, filterName, statusFilter, table.order, table.orderBy]);

  // Calculate pagination
  const paginatedCompanies = filteredCompanies.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    table.setPage(value - 1);
  };

  return (
    <React.Fragment>
      <CompaniesTableFilters control={control} onResetPage={table.onResetPage} />

      <TableLayout
        header={<CompaniesTableHeader onSortChange={table.onSort} order={table.order} orderBy={table.orderBy} />}
        rows={paginatedCompanies.map((company) => (
          <CompaniesTableRow company={company} key={company.id} />
        ))}
        tableProps={{
          variant: 'outlined',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil(filteredCompanies.length / table.rowsPerPage)}
          page={table.page + 1}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </React.Fragment>
  );
}

import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { Job } from '@bluebird-monorepo/types';
import { Box, FormControl, FormLabel, Stack } from '@mui/joy';
import { Controller, useFormContext } from 'react-hook-form';

export function DescriptionsStep() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Job>();

  return (
    <Stack spacing={3}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box
            component="h2"
            sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}
          >
            Descriptions
          </Box>
          <Box sx={{ color: 'text.secondary' }}>
            Provide detailed descriptions about the role and company
          </Box>
        </Box>
      </Box>

      <FormControl>
        <FormLabel>Product Description</FormLabel>
        <Controller
          name="productDescription"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={field.value || 'Describe the product or service...'}
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Role Description</FormLabel>
        <Controller
          name="roleDescription"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={
                field.value || 'Provide a detailed description of the role...'
              }
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Unique About the Company</FormLabel>
        <Controller
          name="uniqueAboutCompany"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={
                field.value || 'Share unique insights about the company...'
              }
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
}

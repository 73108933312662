import { CalendarEvent, Company, EventType, EventTypeConfig, Job } from '@bluebird-monorepo/types';
import { extractID } from '@bluebird-monorepo/utils';
import { Box, Button, CircularProgress, Divider, List, ListItem, ListItemDecorator, Typography } from '@mui/joy';
import { useState } from 'react';
import CustomSelectCalendar from '../CustomSelectCalendar';

interface Participant {
  email: string;
  responseStatus?: 'accepted' | 'declined' | 'tentative';
}

export const EVENT_TYPES: EventTypeConfig[] = [
  {
    description: 'Assign to a job',
    domain: 'Candidate',
    name: 'Candidate interview',
  },
  {
    description: 'Assign to a company',
    domain: 'Client',
    name: 'BD Call',
  },
  {
    description: 'Assign to a company and job',
    domain: 'Client',
    name: 'Weekly update',
  },
  {
    description: '',
    domain: 'Internal',
    name: 'Internal meeting',
  },
] as const;

export const EVENT_COLORS = {
  Candidate: 'success',
  Client: 'primary',
  Internal: 'warning',
} as const;

interface EventDetailDialogProps {
  companies: Company[];
  event: CalendarEvent;
  jobs: Job[];
  onClose: () => void;
  onEventUpdate: (params: { id: string; data: Partial<CalendarEvent> }) => Promise<void>;
  open: boolean;
}

export function EventDetailDialog({ companies, event, jobs, onClose, onEventUpdate, open }: EventDetailDialogProps) {
  const [typeOfEvent, setTypeOfEvent] = useState<EventType | undefined>(event?.typeOfEvent);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedEvent, setUpdatedEvent] = useState<CalendarEvent>(event);

  const handleTypeChange = (value: string) => {
    setTypeOfEvent(value as EventType);
    setUpdatedEvent((prev) => ({ ...prev, typeOfEvent: value as EventType }));
  };

  const handleFieldChange = (key: keyof CalendarEvent | 'job' | 'company', value: string, id?: string) => {
    console.log('handleFieldChange', { key, value, id });
    setUpdatedEvent((prev) => ({
      ...prev,
      [key]: value,
      ...(key === 'job' && { jobId: id }),
      ...(key === 'company' && { companyId: id }),
    }));
  };

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      console.log('Saving event:', {
        id: event.id,
        updates: updatedEvent,
      });

      await onEventUpdate({
        id: event.id,
        data: updatedEvent,
      });

      console.log('Event saved successfully');
      onClose();
    } catch (error) {
      console.error('Failed to update event:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const mapJobsToItems = (jobs: Job[]) =>
    jobs.map((job) => ({
      description: job.companyName,
      name: job.jobTitle,
      id: job.id,
    }));

  console.log('mapJobsToItems', mapJobsToItems(jobs));

  const mapCompaniesToItems = (companies: Company[]) =>
    companies.map((company) => ({
      description: company.location,
      name: company.name,
      id: company.id,
    }));

  if (!event) return null;

  return (
    <>
      {isUpdating && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="title-md">{event.summary}</Typography>
      </Box>

      <Box>
        <Typography level="title-sm">Extracted ID:</Typography>
        <Typography level="body-sm">
          {event.description ? extractID(event.description) || 'No ID found' : 'No description available'}
        </Typography>
      </Box>

      <Box>
        <Typography level="title-sm">Attendees:</Typography>
        {event.attendees && event.attendees.length > 0 && (
          <List>
            {event.attendees.map((participant: Participant, index: number) => (
              <ListItem key={index}>
                <ListItemDecorator>{participant.responseStatus === 'accepted' ? '✅' : '❌'}</ListItemDecorator>
                {participant.email}
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      <CustomSelectCalendar
        colors={EVENT_COLORS}
        defaultValue={event.typeOfEvent}
        items={EVENT_TYPES.map((type) => ({ ...type, id: type.name }))}
        label="Type of event"
        onChange={handleTypeChange}
      />

      {(typeOfEvent === 'Candidate interview' || typeOfEvent === 'Weekly update') && (
        <CustomSelectCalendar
          colors={EVENT_COLORS}
          defaultValue={jobs.find((j) => j.id === updatedEvent.jobId)?.jobTitle}
          items={mapJobsToItems(jobs)}
          label="Assign to job"
          onChange={(value, item) => handleFieldChange('job', value, item?.id)}
        />
      )}

      {typeOfEvent === 'BD Call' && (
        <CustomSelectCalendar
          colors={EVENT_COLORS}
          defaultValue={companies.find((c) => c.id === updatedEvent.companyId)?.name}
          items={mapCompaniesToItems(companies) as any}
          label="Assign to company"
          onChange={(value, item) => handleFieldChange('company', value, item?.id)}
        />
      )}

      <Divider sx={{ mt: 2 }} />
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
        <Button color="neutral" disabled={isUpdating} onClick={onClose} variant="plain">
          Close
        </Button>
        <Button color="success" disabled={isUpdating} onClick={handleSave} variant="soft">
          Save
        </Button>
      </Box>
    </>
  );
}

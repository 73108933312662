import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { Block } from '@mui/icons-material';
import { Chip, Option, Select } from '@mui/joy';
import { useEffect, useState } from 'react';

export function StatusColumn({ job }: { job: Job }) {
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const { statuses, updateJob } = useJobs();

  const [currentStatus, setCurrentStatus] = useState<{
    displayName: string;
    name: string;
  }>({
    displayName: '',
    name: '',
  });

  useEffect(() => {
    if (statuses.length > 0) {
      if (!job?.status) {
        setCurrentStatus({
          displayName: 'Unassigned',
          name: 'unassigned',
        });
      } else {
        const matchedStatus = statuses.find((status) => status.name === job.status);
        if (matchedStatus) {
          setCurrentStatus({
            displayName: matchedStatus.displayName,
            name: matchedStatus.name,
          });
        }
      }
    }
  }, [statuses, job?.status]);

  const handleStatusClick = () => {
    setIsEditingStatus(true);
  };

  const handleStatusChange = (event: any, newValue: { displayName: string; name: string } | null) => {
    if (newValue) {
      setCurrentStatus(newValue);
      updateJob({ id: job.id, data: { status: newValue.name } });
    }
  };

  const handleStatusClose = () => {
    setIsEditingStatus(false);
  };

  const getStatusChipStyle = (status: string) => {
    switch (status) {
      case 'unassigned':
        return {
          bgcolor: 'var(--joy-palette-neutral-200)',
          color: 'var(--joy-palette-neutral-700)',
        };
      case 'full_pipeline':
        return { bgcolor: '#32CD32', color: '#fff' };
      case 'incoming':
        return { bgcolor: '#ADD8E6', color: '#fff' };
      case 'intros_welcome':
        return { bgcolor: 'var(--joy-palette-warning-400)', color: '#fff' };
      case 'needs_work':
        return {
          bgcolor: 'var(--red-5)',
          color: 'var(--red-9)',
        };
      case 'on_hold':
        return {
          bgcolor: 'var(--joy-palette-neutral-400)',
          color: '#fff',
        };
      case 'postponed':
        return { bgcolor: '#A9A9A9', color: '#000' };
      case 'verbally_accepted':
        return { bgcolor: '#A7D7C5', color: '#F8F8F0' };
      case 'working_on_it':
        return {
          bgcolor: 'var(--joy-palette-primary-400)',
          color: 'var(--joy-palette-primary-100)',
        };
      default:
        return { bgcolor: '#E0E0E0', color: '#000' };
    }
  };

  return (
    <td>
      {isEditingStatus ? (
        <Select
          autoFocus
          listboxOpen={isEditingStatus}
          onChange={handleStatusChange}
          onClose={handleStatusClose}
          size="md"
          sx={{ fontSize: '0.8rem' }}
          value={currentStatus}
        >
          <Option value={{ displayName: 'Unassigned', name: 'unassigned' }}>Unassigned</Option>
          {statuses.map((status) => (
            <Option key={status.name} value={status}>
              {status.displayName}
            </Option>
          ))}
        </Select>
      ) : (
        <Chip
          key={currentStatus?.name}
          onClick={handleStatusClick}
          size="sm"
          sx={{
            '& .MuiChip-action': {
              backgroundColor: getStatusChipStyle(currentStatus?.name).bgcolor,
            },
            '& .MuiChip-label': {
              alignItems: 'center',
              color: getStatusChipStyle(currentStatus?.name).color,
              display: 'flex',
              fontWeight: 'bold',
              gap: '0.25rem',
            },
            '& .MuiChip-root': {
              color: '#fff',
            },
            '&:hover .MuiChip-action': {
              backgroundColor: `${getStatusChipStyle(currentStatus?.name).bgcolor}`,
            },
            backgroundColor: getStatusChipStyle(currentStatus?.name).bgcolor,
            color: getStatusChipStyle(currentStatus?.name).color,
          }}
          variant="solid"
        >
          {currentStatus.name === 'on_hold' ? <Block /> : null}
          {currentStatus?.displayName}
        </Chip>
      )}
    </td>
  );
}

import { useJobCandidateAssignments } from '@bluebird-monorepo/candidateJobAssignments';
import { KanbanBoard } from '@bluebird-monorepo/kanban';
import { Job } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import React, { useCallback } from 'react';

const CandidatesInJob: React.FC<{ job: Job }> = ({ job }) => {
  console.log('job', job);
  const { stageMap, updateAssignment, isLoading, error } = useJobCandidateAssignments(job.id);
  console.log('stageMap', stageMap);

  const handleStatusUpdate = useCallback(
    (candidateId: string, statusId: number, statusLabel: string) => {
      console.log('candidateId', candidateId);
      console.log('statusId', statusId);
      console.log('statusLabel', statusLabel);
      updateAssignment({
        id: job.id + '_' + candidateId,
        data: {
          candidateId,
          jobId: job.id,
          status_id: statusId,
          status: statusLabel,
        },
      });
    },
    [updateAssignment, job.id],
  );

  if (isLoading) return <Typography>Loading assignments...</Typography>;
  if (error) return <Typography>Error loading assignments: {error.message}</Typography>;

  return (
    <Box>
      {stageMap && Object.keys(stageMap).length > 0 ? (
        <KanbanBoard
          initial={Object.fromEntries(
            Object.entries(stageMap).map(([key, candidates]) => [
              key,
              candidates.map((candidate) => ({
                ...candidate,
                logoUrl: candidate.logoUrl || '',
                jobId: job.id,
                jobTitle: job.jobTitle,
                candidateId: candidate.candidateId || '',
                candidateFirstName: candidate.candidateFirstName || '',
                candidateLastName: candidate.candidateLastName || '',
                companyName: candidate.companyName || '',
                currentOrganization: candidate.currentOrganization || '',
                currentPosition: candidate.currentPosition || '',
                content: candidate.content || '',
              })),
            ]),
          )}
          onStatusUpdate={handleStatusUpdate}
          withScrollableColumns={true}
        />
      ) : (
        <Typography>No candidates assigned to this job yet.</Typography>
      )}
    </Box>
  );
};

export { CandidatesInJob };

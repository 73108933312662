import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CandidateJobAssignmentRepository } from '../store/candidateJobAssignment.repository';
import { useCandidateJobAssignmentStore } from '../store/candidateJobAssignments.store';

export const useCandidateJobAssignments = () => {
  const { firestore } = useFirebase();
  const { auth } = useFirebase();
  const { user } = useUser(auth);
  const assignmentRepository = useMemo(() => new CandidateJobAssignmentRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { setAssignments, addAssignment, updateAssignment, removeAssignment } = useCandidateJobAssignmentStore();

  const getAssignmentsByOwner = (ownerId: string) => {
    const query = useQuery({
      queryKey: ['candidateJobAssignments', { ownerId }],
      queryFn: () => (ownerId ? assignmentRepository.getAssignmentsByOwner(ownerId) : Promise.resolve([])),
      enabled: !!ownerId && ownerId.length > 0,
    });

    useEffect(() => {
      if (query.data) {
        setAssignments(query.data);
      }
    }, [query.data]);

    return query;
  };

  const getAssignmentsByJob = (jobId: string) => {
    const query = useQuery({
      queryKey: ['candidateJobAssignments', { jobId }],
      queryFn: () => assignmentRepository.getAssignmentsByJob(jobId),
    });

    useEffect(() => {
      if (query.data) {
        setAssignments(query.data);
      }
    }, [query.data]);

    return query;
  };

  const createAssignmentMutation = useMutation({
    mutationFn: (newAssignment: CandidateJobAssignment) => assignmentRepository.set(newAssignment.id, newAssignment),
    onSuccess: (assignment) => {
      addAssignment(assignment);
      queryClient.invalidateQueries({ queryKey: ['candidateJobAssignments'] });
    },
  });

  const updateAssignmentMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<CandidateJobAssignment> }) =>
      assignmentRepository.upsertAssignment(id, data, user?.id),
    onSuccess: (_, variables) => {
      updateAssignment(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['candidateJobAssignments'] });
    },
  });

  return {
    getAssignmentsByJob,
    getAssignmentsByOwner,
    createAssignmentMutation,
    updateAssignmentMutation,
    createAssignment: createAssignmentMutation.mutate,
    updateAssignment: updateAssignmentMutation.mutate,
  };
};

export const getNestedFieldValue = (obj: any, path: string) => {
  const keys = path?.split('.');
  let result = obj;

  for (const key of keys) {
    if (result === undefined || result === null) return undefined;
    result = result[key];
  }
  return result;
};

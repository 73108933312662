import { Box, Typography } from "@mui/joy";
import React from "react";

type QATabProps = {};
const QATab: React.FC<QATabProps> = ({}) => {
  return (
    <Box>
      <Typography level="h4">Questions & Answers</Typography>
    </Box>
  );
};

export default QATab;

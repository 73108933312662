import { JobAssignmentSection } from '@bluebird-monorepo/candidateJobAssignments';
import { CandidateExperienceTab, useCandidates } from '@bluebird-monorepo/candidates';
import {
  AssessmentRounded,
  ContactMailRounded,
  Email,
  KeyboardArrowDown,
  LinkedIn,
  LocationOnRounded,
  PaidRounded,
  PersonOutlineRounded,
  Phone,
  WorkRounded,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Textarea,
  Typography,
} from '@mui/joy';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';

const CandidateDetailPage: React.FC = () => {
  const { candidateId } = useParams<{ candidateId: string }>();
  const { useCandidate, updateCandidate } = useCandidates();
  const [activeTab, setActiveTab] = useState<number>(0);
  const debounceTimeoutRef = useRef<NodeJS.Timeout>();
  const [localScore, setLocalScore] = useState<string>('');

  const { data: candidate, isLoading } = useCandidate(candidateId || '');
  // Update localScore when candidate data changes
  useEffect(() => {
    if (candidate?.score !== undefined) {
      setLocalScore(candidate?.score?.toString());
    }
  }, [candidate?.score]);

  // Clean up the timeout when component unmounts
  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChange = (field: string, value: any) => {
    if (!candidate) return;

    const updatedCandidate = {
      ...candidate,
    };

    const fields = field.split('.');
    let current: any = updatedCandidate;

    for (let i = 0; i < fields.length - 1; i++) {
      if (!current[fields[i]]) {
        current[fields[i]] = {};
      }
      current = current[fields[i]];
    }

    current[fields[fields.length - 1]] = value;

    // Handle score changes differently (with debounce and local state)
    if (field === 'score') {
      // Clear any existing timeout
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      // Update local score state
      setLocalScore(value?.toString() ?? '');

      // Debounce the API call for score updates
      debounceTimeoutRef.current = setTimeout(() => {
        updateCandidate({
          id: candidate.id,
          data: updatedCandidate,
        });
      }, 500);
    } else {
      // For all other fields (like checkboxes), update immediately
      updateCandidate({
        id: candidate.id,
        data: updatedCandidate,
      });
    }
  };

  const handleTabChange = (_: React.SyntheticEvent | null, newValue: number | string | null) => {
    if (typeof newValue === 'number') {
      setActiveTab(newValue);
    }
  };

  if (!candidateId) {
    return <div>No candidate ID provided</div>;
  }

  if (isLoading) {
    return (
      <Layout title="Candidate Detail">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress size="lg" />
        </Box>
      </Layout>
    );
  }

  if (!candidate) {
    return (
      <Layout title="Candidate Detail">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <Typography level="h3" color="danger">
            Candidate not found
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout title="Candidate Detail">
      {/* Header Section */}
      <Sheet
        sx={{
          bgcolor: 'primary.softBg',
          borderRadius: 'lg',
          p: 3,
          mb: 3,
          border: '1px solid',
          borderColor: 'primary.outlinedBorder',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Avatar
            src={candidate.avatar}
            sx={{
              width: 80,
              height: 80,
              border: '4px solid white',
              boxShadow: 'sm',
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Typography level="h2">
                {candidate.firstName} {candidate.lastName}
              </Typography>
              {candidate.linkedin && (
                <IconButton
                  component="a"
                  href={candidate.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="soft"
                  color="primary"
                  size="sm"
                >
                  <LinkedIn />
                </IconButton>
              )}
            </Box>
            <Typography level="body-lg" sx={{ mb: 2, color: 'text.secondary' }}>
              {candidate.currentPosition} {candidate.currentOrganization && `@ ${candidate.currentOrganization}`}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
              {candidate.score !== undefined && (
                <Chip
                  size="sm"
                  variant="soft"
                  color={candidate.score >= 70 ? 'success' : candidate.score >= 40 ? 'warning' : 'neutral'}
                >
                  Score: {candidate.score}/100
                </Chip>
              )}
              {candidate.city && (
                <Chip size="sm" variant="soft">
                  {candidate.city}
                </Chip>
              )}
              {candidate.workExperienceYears && (
                <Chip size="sm" variant="soft">
                  {candidate.workExperienceYears}+ years experience
                </Chip>
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              {candidate.contactInfo?.email && (
                <Button
                  component="a"
                  href={`mailto:${candidate.contactInfo.email}`}
                  size="sm"
                  variant="soft"
                  color="neutral"
                  startDecorator={<Email />}
                >
                  {candidate.contactInfo.email}
                </Button>
              )}
              {candidate.contactInfo?.phone && (
                <Button
                  component="a"
                  href={`tel:${candidate.contactInfo.phone}`}
                  size="sm"
                  variant="soft"
                  color="neutral"
                  startDecorator={<Phone />}
                >
                  {candidate.contactInfo.phone}
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Sheet>

      {/* Tabs and Sections */}
      <Tabs
        defaultValue={0}
        sx={{
          bgcolor: 'background.surface',
        }}
      >
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Experience</Tab>
          <Tab>Jobs</Tab>
          <Tab>Notes</Tab>
          <Tab>Timeline</Tab>
        </TabList>
        <TabPanel value={0}>
          <Accordion defaultExpanded>
            <AccordionSummary
              indicator={<KeyboardArrowDown />}
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                '&:hover': { bgcolor: 'background.level2' },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AssessmentRounded />
                <Typography level="title-md">Score & Assessment</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', gap: 3 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography level="title-sm" sx={{ mb: 2 }}>
                    Candidate Score (0-100)
                  </Typography>
                  <FormControl>
                    <Input
                      type="number"
                      value={localScore}
                      onChange={(e) => {
                        const value = e.target.value;
                        setLocalScore(value);
                        if (value === '') {
                          handleChange('score', undefined);
                        } else {
                          const numValue = parseInt(value);
                          if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                            handleChange('score', numValue);
                          }
                        }
                      }}
                      slotProps={{
                        input: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      endDecorator={
                        <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                          / 100
                        </Typography>
                      }
                    />
                  </FormControl>

                  <FormHelperText sx={{ mt: 1 }}>
                    {localScore !== '' && parseInt(localScore) >= 0 && parseInt(localScore) <= 100
                      ? parseInt(localScore) >= 70
                        ? '👍 Strong candidate'
                        : parseInt(localScore) >= 40
                          ? '👋 Potential candidate'
                          : '⚠️ May need further assessment'
                      : 'Enter a score between 0 and 100'}
                  </FormHelperText>
                </Box>

                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: 'background.level1',
                    borderRadius: 'sm',
                    border: '1px solid',
                    borderColor: 'divider',
                    flex: 1.5,
                  }}
                >
                  <Typography level="body-sm" sx={{ mb: 1 }}>
                    Score Guidelines:
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Typography level="body-xs" sx={{ color: 'success.main' }}>
                      • 70-100: Strong match for the role
                    </Typography>
                    <Typography level="body-xs" sx={{ color: 'warning.main' }}>
                      • 40-69: Potential candidate, needs discussion
                    </Typography>
                    <Typography level="body-xs" sx={{ color: 'danger.main' }}>
                      • 0-39: May not be suitable at this time
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              indicator={<KeyboardArrowDown />}
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                mt: 2,
                '&:hover': { bgcolor: 'background.level2' },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PaidRounded />
                <Typography level="title-md">Compensation</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2 }}>
                <Sheet
                  variant="outlined"
                  sx={{
                    borderRadius: 'sm',
                    mb: 2,
                  }}
                >
                  <Box sx={{ p: 2 }}>
                    <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
                      <Box>
                        <Typography level="title-sm">Prefer Not to Disclose</Typography>
                        <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                          Toggle this if the candidate prefers not to share compensation details
                        </Typography>
                      </Box>
                      <Switch
                        checked={candidate.salaryInformation?.preferNotToDisclose || false}
                        onChange={(e) => handleChange('salaryInformation.preferNotToDisclose', e.target.checked)}
                      />
                    </FormControl>
                  </Box>
                </Sheet>

                {!candidate.salaryInformation?.preferNotToDisclose && (
                  <>
                    <Typography level="title-md" sx={{ mb: 2 }}>
                      Current compensation package
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6}>
                        <FormControl>
                          <FormLabel>Monthly Base Salary</FormLabel>
                          <Input
                            type="number"
                            value={candidate.salaryInformation?.currentSalary?.monthly || ''}
                            onChange={(e) => {
                              const monthlyValue = parseInt(e.target.value) || 0;
                              handleChange('salaryInformation.currentSalary', {
                                ...candidate.salaryInformation?.currentSalary,
                                monthly: monthlyValue,
                                yearly: monthlyValue * 12,
                              });
                            }}
                            endDecorator={<Typography level="body-sm">EUR</Typography>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <FormControl>
                          <FormLabel>Yearly Base Salary</FormLabel>
                          <Input
                            type="number"
                            value={candidate.salaryInformation?.currentSalary?.yearly || ''}
                            onChange={(e) => {
                              const yearlyValue = parseInt(e.target.value) || 0;
                              handleChange('salaryInformation.currentSalary', {
                                ...candidate.salaryInformation?.currentSalary,
                                yearly: yearlyValue,
                                monthly: Math.round(yearlyValue / 12),
                              });
                            }}
                            endDecorator={<Typography level="body-sm">EUR</Typography>}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Typography level="body-xs" sx={{ mt: 3, mb: 2 }}>
                      Variable and or potential equity Compensation
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6}>
                        <FormControl>
                          <FormLabel>Annual Bonus</FormLabel>
                          <Input
                            type="number"
                            value={candidate.salaryInformation?.currentSalary?.bonus || ''}
                            onChange={(e) => {
                              const bonusValue = parseInt(e.target.value) || 0;
                              handleChange('salaryInformation.currentSalary.bonus', bonusValue);
                            }}
                            endDecorator={<Typography level="body-sm">EUR</Typography>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} sx={{ m: 0 }}>
                        <FormControl>
                          <FormLabel>Total OTE (Base + Bonus)</FormLabel>
                          <Input
                            type="number"
                            value={
                              (candidate.salaryInformation?.currentSalary?.yearly || 0) +
                              (candidate.salaryInformation?.currentSalary?.bonus || 0)
                            }
                            disabled
                            endDecorator={<Typography level="body-sm">EUR</Typography>}
                            sx={{ bgcolor: 'background.level1' }}
                          />
                          <FormHelperText>Automatically calculated from base salary + bonus</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={12}>
                        <FormControl>
                          <FormLabel>Current Equity</FormLabel>
                          <Box sx={{ mt: 1.5 }}>
                            <Checkbox
                              label="Has company shares/stock options"
                              checked={candidate.salaryInformation?.currentSalary?.hasEquity || false}
                              onChange={(e) =>
                                handleChange('salaryInformation.currentSalary.hasEquity', e.target.checked)
                              }
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Typography level="title-sm" sx={{ mt: 3, mb: 2 }}>
                      Compensation Expectations
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6}>
                        <FormControl>
                          <FormLabel>Currency</FormLabel>
                          <Select
                            value={candidate.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                            onChange={(_, value) =>
                              value && handleChange('salaryInformation.salaryExpectationRange.currency', value)
                            }
                          >
                            <Option value="EUR">EUR</Option>
                            <Option value="USD">USD</Option>
                            <Option value="GBP">GBP</Option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <FormControl>
                          <FormLabel>Minimum Expected OTE</FormLabel>
                          <Input
                            type="number"
                            value={candidate.salaryInformation?.salaryExpectationRange?.min || ''}
                            onChange={(e) =>
                              handleChange(
                                'salaryInformation.salaryExpectationRange.min',
                                parseInt(e.target.value) || 0,
                              )
                            }
                            endDecorator={
                              <Typography level="body-sm">
                                {candidate.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                              </Typography>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <FormControl>
                          <FormLabel>Maximum Expected OTE</FormLabel>
                          <Input
                            type="number"
                            value={candidate.salaryInformation?.salaryExpectationRange?.max || ''}
                            onChange={(e) =>
                              handleChange(
                                'salaryInformation.salaryExpectationRange.max',
                                parseInt(e.target.value) || 0,
                              )
                            }
                            endDecorator={
                              <Typography level="body-sm">
                                {candidate.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                              </Typography>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12}>
                        <FormControl>
                          <FormLabel>Equity Expectations</FormLabel>
                          <Box sx={{ mt: 1.5 }}>
                            <Checkbox
                              label="Expects equity compensation (shares/stock options)"
                              checked={candidate.salaryInformation?.salaryExpectationRange?.expectsEquity || false}
                              onChange={(e) =>
                                handleChange('salaryInformation.salaryExpectationRange.expectsEquity', e.target.checked)
                              }
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}

                {candidate.salaryInformation?.preferNotToDisclose && (
                  <FormControl>
                    <FormLabel>Reason for Non-Disclosure</FormLabel>
                    <Textarea
                      minRows={2}
                      value={candidate.salaryInformation?.reasonForNonDisclosure || ''}
                      onChange={(e) => handleChange('salaryInformation.reasonForNonDisclosure', e.target.value)}
                      placeholder="Explain why the candidate prefers not to disclose their compensation"
                    />
                  </FormControl>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              indicator={<KeyboardArrowDown />}
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                mt: 2,
                '&:hover': { bgcolor: 'background.level2' },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PersonOutlineRounded />
                <Typography level="title-md">Basic Information</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        value={candidate.firstName || ''}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        value={candidate.lastName || ''}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Current Position</FormLabel>
                      <Input
                        value={candidate.currentPosition || ''}
                        onChange={(e) => handleChange('currentPosition', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Current Organization</FormLabel>
                      <Input
                        value={candidate.currentOrganization || ''}
                        onChange={(e) => handleChange('currentOrganization', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              indicator={<KeyboardArrowDown />}
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                mt: 2,
                '&:hover': { bgcolor: 'background.level2' },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ContactMailRounded />
                <Typography level="title-md">Contact Information</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        value={candidate.contactInfo?.email || ''}
                        onChange={(e) => handleChange('contactInfo.email', e.target.value)}
                        startDecorator={<Email />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Phone</FormLabel>
                      <Input
                        type="tel"
                        value={candidate.contactInfo?.phone || ''}
                        onChange={(e) => handleChange('contactInfo.phone', e.target.value)}
                        startDecorator={<Phone />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>LinkedIn</FormLabel>
                      <Input
                        value={candidate.linkedin || ''}
                        onChange={(e) => handleChange('linkedin', e.target.value)}
                        startDecorator={<LinkedIn />}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              indicator={<KeyboardArrowDown />}
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                mt: 2,
                '&:hover': { bgcolor: 'background.level2' },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOnRounded />
                <Typography level="title-md">Location & Preferences</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Current Location</FormLabel>
                      <Input value={candidate.city || ''} onChange={(e) => handleChange('city', e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <FormLabel>Country of Residence</FormLabel>
                      <Input
                        value={candidate.currentCountryResidingIn || ''}
                        onChange={(e) => handleChange('currentCountryResidingIn', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <Box>
                      <FormLabel>Work Location Preferences</FormLabel>
                      <Box sx={{ mt: 1.5, display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Checkbox
                          label="Open to relocation"
                          checked={candidate.isOpenToRelocate || false}
                          onChange={(e) => handleChange('isOpenToRelocate', e.target.checked)}
                        />
                        <Checkbox
                          label="Open to remote work"
                          checked={candidate.isOpenToWorkRemotely || false}
                          onChange={(e) => handleChange('isOpenToWorkRemotely', e.target.checked)}
                        />
                        <Checkbox
                          label="Requires visa sponsorship"
                          checked={candidate.requiresVisa || false}
                          onChange={(e) => handleChange('requiresVisa', e.target.checked)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>Office Expectations</FormLabel>
                      <Textarea
                        minRows={2}
                        value={candidate.inOfficeExpectations || ''}
                        onChange={(e) => handleChange('inOfficeExpectations', e.target.value)}
                        placeholder="Describe any specific expectations regarding office presence"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              indicator={<KeyboardArrowDown />}
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                mt: 2,
                '&:hover': { bgcolor: 'background.level2' },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <WorkRounded />
                <Typography level="title-md">Professional Details</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>Years of Experience</FormLabel>
                      <Input
                        type="number"
                        value={candidate.workExperienceYears || ''}
                        onChange={(e) => handleChange('workExperienceYears', parseInt(e.target.value) || 0)}
                        endDecorator={<Typography level="body-sm">years</Typography>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>Skills</FormLabel>
                      <Autocomplete
                        multiple
                        value={candidate.skills || []}
                        onChange={(_, newValue) => handleChange('skills', newValue)}
                        options={[]}
                        freeSolo
                        placeholder="Add skills"
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>Tech Stack</FormLabel>
                      <Autocomplete
                        multiple
                        value={candidate.techStack || []}
                        onChange={(_, newValue) => handleChange('techStack', newValue)}
                        options={[]}
                        freeSolo
                        placeholder="Add technologies"
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>Languages</FormLabel>
                      <Autocomplete
                        multiple
                        value={candidate.languagesCandidateSpeak || []}
                        onChange={(_, newValue) => handleChange('languagesCandidateSpeak', newValue)}
                        options={[]}
                        freeSolo
                        placeholder="Add languages"
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>Potential Roles</FormLabel>
                      <Autocomplete
                        multiple
                        value={candidate.potentialRoles || []}
                        onChange={(_, newValue) => handleChange('potentialRoles', newValue)}
                        options={[]}
                        freeSolo
                        placeholder="Add potential roles"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </TabPanel>

        {/* Experience Tab */}
        <TabPanel value={1}>
          <CandidateExperienceTab candidate={candidate} />
        </TabPanel>

        {/* Jobs Tab */}
        <TabPanel value={2}>
          <Typography level="h3" mb={2}>
            Jobs
          </Typography>
          <JobAssignmentSection candidate={candidate} />
        </TabPanel>

        {/* Notes Tab */}
        <TabPanel value={3}>
          <Typography level="h3">Notes</Typography>
          {/* TODO: Add notes content */}
        </TabPanel>

        {/* Timeline Tab */}
        <TabPanel value={4}>
          <Typography level="h3">Timeline</Typography>
          {/* TODO: Add timeline content */}
        </TabPanel>
      </Tabs>
    </Layout>
  );
};

export default CandidateDetailPage;

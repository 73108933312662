import { TabsContentComponent, useCompanies } from '@bluebird-monorepo/companies';
import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import AddIcon from '@mui/icons-material/Add';
import EuroIcon from '@mui/icons-material/Euro';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, Card, CardContent, Chip, Skeleton, Stack, Tab, TabList, Tabs, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';

const LoadingSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    <Skeleton variant="rectangular" width="100%" height={200} />
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={48} />
    </Box>
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={400} />
    </Box>
  </Box>
);

const JobsList: React.FC<{ companyId: string }> = ({ companyId }) => {
  const { jobs } = useJobs();
  const companyJobs = jobs?.filter((job) => job.companyId === companyId) || [];

  if (!jobs) return <Skeleton variant="rectangular" width="100%" height={200} />;
  if (!companyJobs.length)
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography level="body-lg" sx={{ color: 'neutral.500' }}>
          No jobs found
        </Typography>
        <Button
          startDecorator={<AddIcon />}
          component={RouterLink}
          to={`/companies/${companyId}/jobs/new`}
          sx={{ mt: 2 }}
        >
          Create First Job
        </Button>
      </Box>
    );

  return (
    <Stack spacing={2}>
      {companyJobs.map((job: Job) => (
        <Card key={job.id} variant="outlined">
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Typography level="title-lg">{job.jobTitle}</Typography>
                <Stack direction="row" spacing={2} useFlexGap>
                  {job.location && (
                    <Typography level="body-sm" startDecorator={<LocationOnIcon />}>
                      {job.location}
                    </Typography>
                  )}
                  {job.contractType && <Typography level="body-sm">{job.contractType}</Typography>}
                  {job.salary && (
                    <Typography level="body-sm" startDecorator={<EuroIcon />}>
                      {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(job.salary)}
                    </Typography>
                  )}
                  {job.status && (
                    <Chip size="sm" variant="soft" color={job.status === 'active' ? 'success' : 'neutral'}>
                      {job.status}
                    </Chip>
                  )}
                </Stack>
              </Box>
              <Button component={RouterLink} to={`/jobs/${job.id}`} variant="plain" endDecorator="→">
                View Details
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

const CompanyDetailPage: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { useCompany } = useCompanies();
  const { data: company, isLoading, error } = useCompany(companyId!);
  const [activeTab, setActiveTab] = useState('general');

  if (isLoading)
    return (
      <Layout title="Loading...">
        <LoadingSkeleton />
      </Layout>
    );
  if (error)
    return (
      <Layout title="Error">
        <Typography color="danger">{error.message}</Typography>
      </Layout>
    );
  if (!company)
    return (
      <Layout title="Not Found">
        <Typography>Company not found</Typography>
      </Layout>
    );

  const tabs = [
    { id: 'general', label: 'General', index: 0 },
    { id: 'jobs', label: `Jobs (${company.jobCount || 0})` },
    { id: 'finance', label: 'Finance', index: 1 },
    {
      id: 'people',
      label: `People (${(company.contactPersons?.length || 0) + (company.hiringManagers?.length || 0)})`,
      index: 2,
    },
  ];

  // Map the string-based tab IDs to the numeric indices expected by TabsContentComponent
  const getTabContentIndex = (tabId: string): number => {
    const tab = tabs.find((t) => t.id === tabId);
    return tab?.index ?? 0;
  };

  return (
    <Layout title={company.name}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value as string)} sx={{ mb: 2 }}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Box sx={{ mt: 2 }}>
          {activeTab === 'jobs' && <JobsList companyId={company.id} />}
          {activeTab !== 'jobs' && <TabsContentComponent company={company} tabIndex={getTabContentIndex(activeTab)} />}
        </Box>
      </Box>
    </Layout>
  );
};

export default CompanyDetailPage;

import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Job } from '@bluebird-monorepo/types';
import { Firestore, QueryConstraint, where } from 'firebase/firestore';

interface ReferenceNumberResponse {
  referenceNumber: string;
}

export class JobsRepository extends BaseRepository<Job> {
  constructor(db: Firestore) {
    if (!db) throw new Error('Firestore instance is required');
    super(db, 'jobs');
  }

  async getJobsByCompany(companyId: string): Promise<Job[]> {
    try {
      const constraints: QueryConstraint[] = [where('companyId', '==', companyId)];
      return this.getAll(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching jobs by company');
    }
  }

  async getActiveJobs(): Promise<Job[]> {
    try {
      const constraints: QueryConstraint[] = [where('status', '==', 'active')];
      return this.getAll(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching active jobs');
    }
  }
}

import { useStages } from '@bluebird-monorepo/kanban';
import { useMemo } from 'react';
import { createStagePipelineMap, mapToPipeline } from '../utils/pipeline.utils';
import { useCandidateJobAssignments } from './useCandidateJobAssignments';

export const useJobCandidateAssignments = (jobId: string) => {
  const { stages, loading: stagesLoading, error: stagesError } = useStages();
  const { getAssignmentsByJob, updateAssignment, createAssignment } = useCandidateJobAssignments();
  const { data: rawAssignments, error: assignmentsError, isLoading: assignmentsLoading } = getAssignmentsByJob(jobId);

  const pipelineAssignments = useMemo(() => (rawAssignments ? mapToPipeline(rawAssignments) : null), [rawAssignments]);
  const stageMap = useMemo(
    () => (pipelineAssignments && stages ? createStagePipelineMap(stages, pipelineAssignments) : null),
    [pipelineAssignments, stages],
  );

  return {
    assignments: pipelineAssignments,
    stageMap,
    error: stagesError || assignmentsError,
    isLoading: stagesLoading || assignmentsLoading,
    updateAssignment,
    createAssignment,
  };
};

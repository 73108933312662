import React, { useCallback, useEffect, useMemo } from 'react';

import { TableLayout } from '@bluebird-monorepo/bluebird-ui';
import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import Pagination from '@mui/material/Pagination';
import { useForm } from 'react-hook-form';
import JobsTableFilters from './JobsTableFilters';
import { JobsTableHeader } from './JobsTableHeader';
import { JobsTableRow } from './JobsTableRow';

function applyFilter({
  companyName,
  comparator,
  feeGt,
  feeLt,
  filterName,
  inputData,
  location,
  responsible,
  salaryGt,
  salaryLt,
  statusName,
  workingOn,
}: {
  companyName: string;
  comparator: (a: any, b: any) => number;
  feeGt: string;
  feeLt: string;
  filterName: string;
  inputData: Job[];
  location: string;
  responsible: string;
  salaryGt: string;
  salaryLt: string;
  statusName: string[];
  workingOn: string[];
}) {
  let filteredData = inputData;

  if (filterName) {
    filteredData = filteredData.filter(
      (job) =>
        job.jobTitle?.toLowerCase().includes(filterName.toLowerCase()) ||
        job.responsible?.toLowerCase().includes(filterName.toLowerCase()) ||
        job.companyName?.toLowerCase().includes(filterName.toLowerCase()),
    );
  }

  if (statusName.length > 0) {
    filteredData = filteredData.filter((job) => {
      if (statusName.includes('unassigned') && !job.status) {
        return true;
      }
      return statusName.includes(job.status?.toLowerCase() ?? '');
    });
  }

  if (companyName) {
    filteredData = filteredData.filter((job) => job.companyName?.toLowerCase().includes(companyName.toLowerCase()));
  }

  if (responsible) {
    filteredData = filteredData.filter((job) => job.responsible?.toLowerCase().includes(responsible.toLowerCase()));
  }

  if (location) {
    filteredData = filteredData.filter((job) => job.location?.toLowerCase().includes(location.toLowerCase()));
  }

  if (workingOn.length > 0) {
    filteredData = filteredData.filter((job) => {
      const jobWorkingOn = job.workingOn || [];
      return workingOn.some((person) => jobWorkingOn.includes(person));
    });
  }

  if (salaryGt) {
    filteredData = filteredData.filter((job) => (job.salary ?? 0) > parseFloat(salaryGt));
  }

  if (salaryLt) {
    filteredData = filteredData.filter((job) => (job.salary ?? 0) < parseFloat(salaryLt));
  }

  if (feeGt) {
    filteredData = filteredData.filter((job) => job.feePercentage > parseFloat(feeGt));
  }

  if (feeLt) {
    filteredData = filteredData.filter((job) => job.feePercentage < parseFloat(feeLt));
  }

  filteredData.sort(comparator);

  return filteredData;
}

function getComparator(order: 'asc' | 'desc', orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

function useTable() {
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage] = React.useState(10);
  const [selected] = React.useState<string[]>([]);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');

  const onSort = useCallback(
    (id: string) => {
      const isAsc = orderBy === id && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    },
    [order, orderBy],
  );

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  return {
    onResetPage,
    onSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    handlePageChange,
    setPage,
  };
}

interface JobsTableProps {
  onFilteredCountChange?: (count: number) => void;
}

export function JobsTable({ onFilteredCountChange }: JobsTableProps) {
  const { jobs } = useJobs();
  const defaultStates = [
    'needs_work',
    'working_on_it',
    'verbally_accepted',
    'full_pipeline',
    'incoming',
    'intros_welcome',
    'on_hold',
    'postponed',
  ];

  const defaultValues = {
    companyName: '',
    feeGt: '',
    feeLt: '',
    filterName: '',
    location: '',
    responsible: '',
    salaryGt: '',
    salaryLt: '',
    statusName: defaultStates,
    workingOn: [],
  };

  const { control, watch, reset } = useForm({
    defaultValues: (() => {
      const savedFilters = localStorage.getItem('jobsTableFilters');
      return savedFilters ? JSON.parse(savedFilters) : defaultValues;
    })(),
  });

  const handleResetToDefault = () => {
    reset(defaultValues);
    localStorage.removeItem('jobsTableFilters');
  };

  const table = useTable();
  const filterName = watch('filterName');
  const statusName = watch('statusName');
  const companyName = watch('companyName');
  const responsible = watch('responsible');
  const location = watch('location');
  const workingOn = watch('workingOn');
  const salaryGt = watch('salaryGt');
  const salaryLt = watch('salaryLt');
  const feeGt = watch('feeGt');
  const feeLt = watch('feeLt');

  const dataFiltered = useMemo(() => {
    const filtered = applyFilter({
      companyName,
      comparator: getComparator(table.order, table.orderBy),
      feeGt,
      feeLt,
      filterName,
      inputData: jobs,
      location,
      responsible,
      salaryGt,
      salaryLt,
      statusName,
      workingOn,
    });

    onFilteredCountChange?.(filtered.length);
    return filtered;
  }, [
    jobs,
    filterName,
    responsible,
    statusName,
    companyName,
    location,
    workingOn,
    table.order,
    table.orderBy,
    salaryGt,
    salaryLt,
    feeGt,
    feeLt,
    onFilteredCountChange,
  ]);

  const paginatedData = useMemo(() => {
    return dataFiltered.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage);
  }, [dataFiltered, table.page, table.rowsPerPage]);

  // Save to localStorage when filters change
  useEffect(() => {
    const subscription = watch((value) => {
      localStorage.setItem('jobsTableFilters', JSON.stringify(value));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <JobsTableFilters control={control} onResetPage={table.onResetPage} />
        <button
          onClick={handleResetToDefault}
          style={{
            backgroundColor: 'var(--joy-palette-primary-500)',
            color: 'white',
            border: 'none',
            padding: '8px 16px',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          Reset Filters
        </button>
      </Box>

      <TableLayout
        header={<JobsTableHeader onSort={table.onSort} order={table.order} orderBy={table.orderBy} />}
        rows={paginatedData.map((job) => (
          <JobsTableRow job={job} key={job.id} />
        ))}
        tableProps={{
          variant: 'outlined',
          sx: {
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '12px',
            '--TableCell-paddingX': '8px',
            backgroundColor: 'white',
            borderRadius: 'md',
            '& thead th:first-child': { borderTopLeftRadius: 'var(--joy-radius-md)' },
            '& thead th:last-child': { borderTopRightRadius: 'var(--joy-radius-md)' },
            '& tr:last-child td:first-child': { borderBottomLeftRadius: 'var(--joy-radius-md)' },
            '& tr:last-child td:last-child': { borderBottomRightRadius: 'var(--joy-radius-md)' },
            '& tbody tr': {
              transition: 'background-color 0.2s ease-in-out',
            },
            '& tbody tr:hover': {
              backgroundColor: 'var(--TableRow-hoverBackground)',
            },
            '& th': {
              backgroundColor: 'var(--TableCell-headBackground)',
              borderBottom: '1px solid var(--joy-palette-divider)',
              fontWeight: '600',
            },
          },
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
          px: 1,
        }}
      >
        <Typography level="body-sm">
          Showing {table.page * table.rowsPerPage + 1} to{' '}
          {Math.min((table.page + 1) * table.rowsPerPage, dataFiltered.length)} of {dataFiltered.length} jobs
        </Typography>
        <Pagination
          count={Math.ceil(dataFiltered.length / table.rowsPerPage)}
          page={table.page + 1}
          onChange={table.handlePageChange}
          color="primary"
          shape="rounded"
          size="small"
        />
      </Box>
    </>
  );
}

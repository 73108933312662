import { PlacementsTable } from '@bluebird-monorepo/placements';
import React from 'react';
import { Layout } from '../../../layout/Layout';
const PlacementsPage: React.FC = () => {
  return (
    <Layout title="Placements">
      <PlacementsTable />
    </Layout>
  );
};

export default PlacementsPage;

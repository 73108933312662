import { Company } from '@bluebird-monorepo/types';
import { Avatar } from '@mui/joy';

export function CompanyAvatar({ company }: { company: Company }) {
  return (
    <Avatar
      alt={company.name}
      src={company.logoUrl}
      size="sm"
      variant="outlined"
      sx={{
        width: 24,
        height: 24,
        '--Avatar-size': '24px',
        border: '1px solid',
        borderColor: 'divider',
      }}
    />
  );
}

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useFirebase } from '../utils/use-firebase';

export const useFirebaseAuth = () => {
  const { auth } = useFirebase();

  const signInWithGoogle = async () => {
    if (!auth) throw new Error('Auth not initialized');

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    try {
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
  };

  const signOut = async () => {
    if (!auth) throw new Error('Auth not initialized');
    return auth.signOut();
  };

  return {
    auth,
    signInWithGoogle,
    signOut,
  };
};

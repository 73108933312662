import { Button, ButtonProps } from '@mui/joy';
import { styled } from '@mui/joy/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: 'hsl(var(--background))',
  borderColor: 'hsl(var(--input))',
  borderRadius: '0.375rem',
  borderStyle: 'dashed',
  borderWidth: '1px',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: '0.75rem',
  fontWeight: 500,
  gap: '0.5rem',
  height: '2rem',
  justifyContent: 'center',
  lineHeight: '1rem',
  outline: 'none',
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  transitionDuration: '0.15s',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  whiteSpace: 'nowrap',
}));

export interface DashedButtonProps extends ButtonProps {
  onClick: () => void;
}

export function DashedButton({ children, onClick, ...props }: DashedButtonProps) {
  return (
    <Button
      color="neutral"
      onClick={onClick}
      sx={{
        alignItems: 'center',
        backgroundColor: 'hsl(var(--background))',
        borderColor: 'hsl(var(--input))',
        borderRadius: '0.375rem' /* rounded-md */,
        borderStyle: 'dashed',
        borderWidth: '1px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        cursor: 'pointer',
        display: 'inline-flex',
        fontSize: '0.75rem' /* text-xs */,
        fontWeight: 500,
        gap: '0.5rem',
        height: '2rem' /* h-8 */,
        justifyContent: 'center',
        lineHeight: '1rem' /* text-xs */,
        outline: 'none',
        paddingLeft: '0.75rem' /* px-3 */,
        paddingRight: '0.75rem' /* px-3 */,
        transitionDuration: '0.15s',
        transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        whiteSpace: 'nowrap',
      }}
      variant="outlined"
    >
      {children}
    </Button>
  );
}

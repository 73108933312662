import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, ButtonGroup, IconButton, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { useCallback } from 'react';

interface WeekNavigationProps {
  currentWeek: dayjs.Dayjs;
  onNextWeek: () => void;
  onPreviousWeek: () => void;
}

export const WeekNavigation = ({ currentWeek, onNextWeek, onPreviousWeek }: WeekNavigationProps) => {
  const handleCurrentWeek = useCallback(() => {
    console.log('Today button clicked');
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <ButtonGroup>
        <IconButton onClick={onPreviousWeek} variant="outlined">
          <ChevronLeft />
        </IconButton>
        <Button onClick={handleCurrentWeek} variant="outlined">
          Today
        </Button>
        <IconButton onClick={onNextWeek} variant="outlined">
          <ChevronRight />
        </IconButton>
      </ButtonGroup>
      <Typography level="h4">
        {dayjs(currentWeek).format('MMMM D')} - {dayjs(currentWeek).endOf('week').format('MMMM D, YYYY')}
      </Typography>
    </Box>
  );
};

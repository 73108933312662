import { SxProps } from '@mui/joy/styles/types';
import { CSSProperties } from 'react';

interface BluebirdIconProps {
  sx?: SxProps;
}

export const BluebirdIcon = ({ sx }: BluebirdIconProps) => {
  const imgStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    ...(sx as any),
  };

  return <img src="./images/Bird.png" alt="Bluebird" style={imgStyle} />;
};

import { Job } from '@bluebird-monorepo/types';
import { Box, Tab, tabClasses, TabList, Tabs } from '@mui/joy';
import React from 'react';
import { CandidatesTab } from './Candidates';
import { EventsTab } from './Events';
import { GeneralTab } from './General';
import { NotesTab } from './Notes';
import { QATab } from './QA';

interface TabsContentProps {
  editableJob: Job;
  tabIndex: number;
}

interface TabListProps {
  job: Job;
  onTabChange: (newValue: number) => void;
  tabIndex: number;
}

export const TabListComponent: React.FC<TabListProps> = ({
  job,
  onTabChange,
  tabIndex,
}) => {
  return (
    <Tabs
      onChange={(_, newValue) => onTabChange(newValue as number)}
      sx={{ width: 'fit-content' }}
      value={tabIndex}
    >
      <TabList
        disableUnderline
        sx={{
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            bgcolor: 'background.surface',
            boxShadow: 'sm',
          },
          bgcolor: 'background.level1',
          borderRadius: 'xl',
          gap: 0.5,
          p: 0.5,
        }}
      >
        <Tab disableIndicator value={0}>
          General
        </Tab>
        <Tab disableIndicator value={1}>
          Candidates ({job.candidates})
        </Tab>
        <Tab disableIndicator value={2}>
          Notes
        </Tab>
        <Tab disableIndicator value={3}>
          QA
        </Tab>
        <Tab disableIndicator value={4}>
          Events
        </Tab>
      </TabList>
    </Tabs>
  );
};

export const TabsContentComponent: React.FC<TabsContentProps> = ({
  editableJob,
  tabIndex,
}) => {
  return (
    <Box>
      {tabIndex === 0 && <GeneralTab />}
      {tabIndex === 1 && <CandidatesTab editableJob={editableJob} />}
      {tabIndex === 2 && <NotesTab editableJob={editableJob} />}
      {tabIndex === 3 && <QATab />}
      {tabIndex === 4 && <EventsTab editableJob={editableJob} />}
    </Box>
  );
};

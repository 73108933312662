import { Placement } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface PlacementState {
  placements: Placement[];
  setPlacements: (placements: Placement[]) => void;
  addPlacement: (placement: Placement) => void;
  updatePlacement: (id: string, data: Partial<Placement>) => void;
  removePlacement: (id: string) => void;
}

export const usePlacementStore = create<PlacementState>((set) => ({
  placements: [],
  setPlacements: (placements) => set({ placements }),
  addPlacement: (placement) =>
    set((state) => ({ placements: [...state.placements, placement] })),
  updatePlacement: (id, data) =>
    set((state) => ({
      placements: state.placements.map((p) =>
        p.id === id ? { ...p, ...data } : p
      ),
    })),
  removePlacement: (id) =>
    set((state) => ({
      placements: state.placements.filter((p) => p.id !== id),
    })),
}));

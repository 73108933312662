import { ContactPerson } from '@bluebird-monorepo/types';
import { Edit, Mail, Phone } from '@mui/icons-material';
import { Avatar, Box, Card, IconButton, Typography } from '@mui/joy';

export const cardStyle = {
  '&:hover': {
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)', // Subtle hover shadow
    transform: 'scale(1.05)', // Slight lift on hover
  },
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  height: 'auto',
  padding: '24px',
  position: 'relative',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  width: '100%',
};

// Example Contact Person component
const ContactPersonCard = ({ contact }: { contact: ContactPerson }) => {
  return (
    <Card sx={cardStyle} variant="outlined">
      {/* Avatar and Name */}
      <Box alignItems="center" display="flex" gap={2}>
        <Avatar
          alt={contact.name}
          src={contact.avatar || '/default-avatar.png'} // Fallback avatar
          sx={{
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
            height: 56,
            width: 56,
          }}
        />
        <Typography fontWeight="bold" level="h4" sx={{ fontSize: '1.25rem' }}>
          {contact.name}
        </Typography>
      </Box>

      {/* Contact Information */}
      <Box mt={2}>
        <Typography startDecorator={<Mail />} sx={{ fontSize: '0.875rem' }}>
          {contact.email || 'No email provided'}
        </Typography>
        <Typography
          startDecorator={<Phone />}
          sx={{ fontSize: '0.875rem', mt: 1 }}
        >
          {contact.phone || 'No phone provided'}
        </Typography>
      </Box>

      {/* Edit button */}
      <Box sx={{ position: 'absolute', right: 16, top: 16 }}>
        <IconButton aria-label="edit contact">
          <Edit fontSize="small" />
        </IconButton>
      </Box>
    </Card>
  );
};

export default ContactPersonCard;

// TODO: Check if this has no overlap with BluebirdCard

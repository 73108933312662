import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useUser } from '@bluebird-monorepo/users';
import {
  BusinessCenterOutlined,
  CalendarMonth,
  Home,
  MoneyTwoTone,
  PeopleOutlineSharp,
  SettingsRounded,
} from '@mui/icons-material';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const menuItems = [
  { path: '/', label: 'Home', icon: <Home /> },
  { path: '/jobs', label: 'Jobs', icon: <PeopleOutlineSharp /> },
  { path: '/companies', label: 'Companies', icon: <BusinessCenterOutlined /> },
  { path: '/candidates', label: 'Candidates', icon: <PeopleOutlineSharp /> },
  { path: '/placements', label: 'Placements', icon: <MoneyTwoTone /> },
  { path: '/calendar', label: 'Calendar', icon: <CalendarMonth /> },
];

export const Sidebar = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useFirebase();
  const { user } = useUser(auth);

  const isActive = (path: string) => location.pathname === path;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: 0,
        p: 2,
        pt: 1,
      }}
    >
      <List
        size="sm"
        sx={{
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '4px',
        }}
      >
        {menuItems.map((item) => (
          <ListItem key={item.path}>
            <ListItemButton
              onClick={() => navigate(item.path)}
              selected={isActive(item.path)}
              sx={{
                gap: 1.5,
                p: 2,
                color: isActive(item.path) ? 'primary.plainColor' : 'neutral.plainColor',
                '&:hover': {
                  bgcolor: 'background.level1',
                },
                '&.Mui-selected': {
                  bgcolor: 'primary.softBg',
                  '&:hover': {
                    bgcolor: 'primary.softHoverBg',
                  },
                },
              }}
            >
              {item.icon}
              <ListItemContent>
                <Typography
                  level="title-sm"
                  sx={{
                    color: isActive(item.path) ? 'primary.plainColor' : 'inherit',
                  }}
                >
                  {item.label}
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List
        size="sm"
        sx={{
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '8px',
          mt: 'auto',
          mb: 2,
        }}
      >
        <ListItem>
          <ListItemButton
            onClick={() => navigate(`/user-settings/${user?.uid}`)}
            selected={isActive('/settings')}
            sx={{
              gap: 1.5,
              p: 2,
              color: isActive('/settings') ? 'primary.plainColor' : 'neutral.plainColor',
              '&:hover': {
                bgcolor: 'background.level1',
              },
              '&.Mui-selected': {
                bgcolor: 'primary.softBg',
                '&:hover': {
                  bgcolor: 'primary.softHoverBg',
                },
              },
            }}
          >
            <SettingsRounded />
            <Typography
              level="title-sm"
              sx={{
                color: isActive('/settings') ? 'primary.plainColor' : 'inherit',
              }}
            >
              My settings
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
});

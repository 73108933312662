import { Add, Delete } from '@mui/icons-material';
import { Box, Button, FormControl, FormHelperText, FormLabel, IconButton, Input, Stack, Typography } from '@mui/joy';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface ContactFormValues {
  contactPersons: Array<{
    name: string;
    email: string;
    phone?: string;
    position?: string;
  }>;
  hiringManagers: Array<{
    name: string;
    email: string;
    phone?: string;
    position?: string;
  }>;
}

export function ContactsStep() {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ContactFormValues>();

  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({
    control,
    name: 'contactPersons',
  });

  const {
    fields: managerFields,
    append: appendManager,
    remove: removeManager,
  } = useFieldArray({
    control,
    name: 'hiringManagers',
  });

  const renderContactPerson = (field: any, index: number, isManager = false) => {
    const fieldName = isManager ? 'hiringManagers' : 'contactPersons';
    const fieldErrors: any = isManager ? errors?.hiringManagers?.[index] : errors?.contactPersons?.[index];

    return (
      <Box
        key={field.id}
        sx={{
          p: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={() => (isManager ? removeManager(index) : removeContact(index))}
        >
          <Delete />
        </IconButton>

        <Stack spacing={2}>
          <FormControl error={!!fieldErrors?.name} required>
            <FormLabel>Name</FormLabel>
            <Input {...register(`${fieldName}.${index}.name`)} placeholder="Full name" />
            {fieldErrors?.name && <FormHelperText>{fieldErrors.name.message as string}</FormHelperText>}
          </FormControl>

          <FormControl error={!!fieldErrors?.email} required>
            <FormLabel>Email</FormLabel>
            <Input {...register(`${fieldName}.${index}.email`)} type="email" placeholder="Email address" />
            {fieldErrors?.email && <FormHelperText>{fieldErrors.email.message as string}</FormHelperText>}
          </FormControl>

          <FormControl error={!!fieldErrors?.phone}>
            <FormLabel>Phone</FormLabel>
            <Input {...register(`${fieldName}.${index}.phone`)} placeholder="Phone number" />
            {fieldErrors?.phone && <FormHelperText>{fieldErrors.phone.message as string}</FormHelperText>}
          </FormControl>

          <FormControl error={!!fieldErrors?.position}>
            <FormLabel>Position</FormLabel>
            <Input {...register(`${fieldName}.${index}.position`)} placeholder="Job title" />
            {fieldErrors?.position && <FormHelperText>{fieldErrors.position.message as string}</FormHelperText>}
          </FormControl>
        </Stack>
      </Box>
    );
  };

  return (
    <Stack spacing={4}>
      <Typography level="h4">Contact Information</Typography>

      <Stack spacing={2}>
        <Typography level="title-md">Contact Persons</Typography>
        {contactFields.map((field, index) => renderContactPerson(field, index))}
        <Button
          startDecorator={<Add />}
          variant="outlined"
          onClick={() => appendContact({ name: '', email: '', phone: '', position: '' })}
        >
          Add Contact Person
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Typography level="title-md">Hiring Managers</Typography>
        {managerFields.map((field, index) => renderContactPerson(field, index, true))}
        <Button
          startDecorator={<Add />}
          variant="outlined"
          onClick={() => appendManager({ name: '', email: '', phone: '', position: '' })}
        >
          Add Hiring Manager
        </Button>
      </Stack>
    </Stack>
  );
}

import { Box, Button } from '@mui/joy';
import { styled } from '@mui/joy/styles';

export const NavigationButton = styled(Button)(({ theme }) => ({
  border: '1px solid #ccc',
  borderRadius: '15%',
  cursor: 'pointer',
  marginLeft: '8px',
  padding: '0.5rem',
}));

export const DayColumn = styled(Box)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  border: '1px solid #ddd',
  borderRadius: '8px',
  flex: '1',
  minWidth: '250px',
  padding: theme.spacing(2),
}));

export const StyledEventCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.vars.palette.background.surface,
  border: `1px solid ${theme.vars.palette.divider}`,
  borderRadius: theme.radius.md,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.vars.palette.background.level1,
    transform: 'translateY(-1px)',
    boxShadow: theme.shadow.sm,
  },
}));

import { Company } from '@bluebird-monorepo/types';
import { Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import { CompanyAvatar } from './components/CompanyAvatar';
import { CompanyName } from './components/CompanyName';
import { JobCountLink } from './components/JobCountLink';
import { StatusChip } from './components/StatusChip';

export function CompaniesTableRow({ company }: { company: Company }) {
  return (
    <tr>
      <td>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
          <CompanyAvatar company={company} />
          <CompanyName company={company} />
        </Box>
      </td>
      <td>
        <Typography level="body-sm">{company.owner || 'Unassigned'}</Typography>
      </td>
      <td>
        <StatusChip status={company.status ?? 'inactive'} />
      </td>
      <td>
        <JobCountLink company={company} />
      </td>
    </tr>
  );
}

import { Button, Input, InputProps } from '@mui/joy';
import { forwardRef, ReactNode } from 'react';

interface DecoratedInputProps extends InputProps {
  decorator: {
    icon: ReactNode;
    text: string;
  };
}
const DecoratedInput = forwardRef<HTMLInputElement, DecoratedInputProps>(
  ({ decorator, ...props }, ref) => {
    return (
      <Input
        {...props}
        ref={ref}
        startDecorator={
          <Button
            color="neutral"
            startDecorator={decorator.icon}
            tabIndex={-1}
            variant="soft"
          >
            {decorator.text}
          </Button>
        }
      />
    );
  }
);

export { DecoratedInput };

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { useTechnologies } from '@bluebird-monorepo/candidates';
import { Job } from '@bluebird-monorepo/types';
import { languageCountryMap } from '@bluebird-monorepo/utils';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    ListItemDecorator,
    Modal,
    ModalClose,
    ModalDialog,
    ModalOverflow,
    Option,
    Select,
    Stack,
    Typography,
} from '@mui/joy';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const LoadingOverlay = () => (
  <Box
    sx={{
      alignItems: 'center',
      bgcolor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1300,
    }}
  >
    <CircularProgress />
  </Box>
);

interface TemplateResponse {
  data: {
    parsedData: Partial<Job>;
  };
}

export function DetailsStep() {
  const uniqueLanguages = Array.from(new Map(languageCountryMap.map((item) => [item.languageCode, item])).values());

  const { technologies } = useTechnologies();
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<Job>();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [newOppsTemplate, setNewOppsTemplate] = useState('');

  const { functions } = useFirebase();

  async function parseJobTemplate(functions: any, template: string) {
    const parseTemplate = httpsCallable<{ template: string }, any>(functions, 'parseJobTemplate');

    try {
      const result = await parseTemplate({ template });
      return result.data.parsedData;
    } catch (error) {
      console.error('Error parsing job template:', error);
      throw new Error('Failed to parse job template.');
    }
  }

  function changeNewOpps(value: string): void {
    setNewOppsTemplate(value);
  }

  const UpdateJobFormValues = (job: Partial<Job>) => {
    Object.keys(job).forEach((key) => {
      const value = (job as any)[key];
      if (value !== null && value !== undefined) {
        setValue(key as keyof Job, value);
      }
    });
  };

  function tryToMapValues(): void {
    const template = newOppsTemplate;

    setIsLoading(true);
    parseJobTemplate(functions, template)
      .then((job) => {
        setIsLoading(false);
        UpdateJobFormValues(job);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setIsOpen(false);
        alert('Failed to parse job template. Please ensure the template is valid.');
      });
  }

  return (
    <Stack spacing={3} sx={{ maxWidth: '80%' }}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Job Details
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Specify salary, requirements, and technical details</Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button onClick={() => setIsOpen(true)}>Import new-opps-template</Button>
      </Box>

      <FormControl error={!!errors.salaryRange}>
        <FormLabel>Salary Range</FormLabel>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Input
            {...register('salaryRange.min', { valueAsNumber: true })}
            type="number"
            placeholder="Min"
            startDecorator="€"
            color={errors.salaryRange?.min ? 'danger' : undefined}
          />
          <Input
            {...register('salaryRange.max', { valueAsNumber: true })}
            type="number"
            placeholder="Max"
            startDecorator="€"
            color={errors.salaryRange?.max ? 'danger' : undefined}
          />
        </Box>
        {errors.salaryRange?.min && (
          <FormHelperText>{errors.salaryRange.min.message}</FormHelperText>
        )}
        {errors.salaryRange?.max && (
          <FormHelperText>{errors.salaryRange.max.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.feePercentage} required>
        <FormLabel>Fee Percentage</FormLabel>
        <Input
          {...register('feePercentage', { valueAsNumber: true })}
          type="number"
          endDecorator="%"
          placeholder="This should be autofilled by company"
        />
        {errors.feePercentage && <FormHelperText>{errors.feePercentage.message}</FormHelperText>}
      </FormControl>

      <Divider />

      <FormControl error={!!errors.techStack}>
        <FormLabel>Tech Stack</FormLabel>
        <Controller
          name="techStack"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              value={field.value || []}
              onChange={(_, newValue) => {
                field.onChange(newValue);
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                  {selected.map((selectedObject) => (
                    <Chip key={selectedObject.id}>{selectedObject.value}</Chip>
                  ))}
                </Box>
              )}
              slotProps={{
                listbox: {
                  sx: { maxHeight: 300, overflow: 'auto' },
                },
              }}
            >
              {technologies?.map((tech) => (
                <Option key={tech.id} value={tech.name}>
                  {tech.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Preferred Languages</FormLabel>
        <Controller
          name="preferredLanguages"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              value={field.value || []}
              onChange={(_, newValue) => {
                field.onChange(newValue);
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                  {selected.map((langCode) => {
                    const lang = uniqueLanguages.find((l) => l.languageCode === langCode.value);
                    return <Chip key={langCode.id}>{lang?.languageName}</Chip>;
                  })}
                </Box>
              )}
              slotProps={{
                listbox: {
                  sx: { maxHeight: 300, overflow: 'auto' },
                },
              }}
            >
              {uniqueLanguages.map((lang) => (
                <Option key={lang.languageCode} value={lang.languageCode}>
                  <ListItemDecorator>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/${lang.countryCode.toLowerCase()}.svg`}
                      alt=""
                    />
                  </ListItemDecorator>
                  {lang.languageName}
                </Option>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <Modal onClose={() => setIsOpen(false)} open={isOpen} sx={{ maxWidth: 700 }}>
        <ModalOverflow>
          <ModalDialog layout="fullscreen" sx={{ padding: 3 }} variant="outlined">
            <ModalClose sx={{ m: 1 }} variant="plain" />
            <Typography level="h3">Copy paste our new-opps-template</Typography>
            <Divider inset="none" />
            {isLoading && <LoadingOverlay />}
            <RichTextEditor
              content="Please paste the new-opps template in here"
              onChange={(value) => changeNewOpps(value)}
              showMenuBar={false}
            />
            <Button onClick={() => tryToMapValues()}>Try to map</Button>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </Stack>
  );
}

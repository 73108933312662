import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { Job } from '@bluebird-monorepo/types';
import { Box, FormControl, FormHelperText, FormLabel, Stack } from '@mui/joy';
import { Controller, useFormContext } from 'react-hook-form';

export function RequirementsStep() {
  const {
    control,
    formState: { errors },
  } = useFormContext<Job>();

  return (
    <Stack spacing={3}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Job Requirements
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Define the requirements and responsibilities for this position</Box>
        </Box>
      </Box>

      <FormControl error={!!errors.mustHaves}>
        <FormLabel>Must-Have Requirements</FormLabel>
        <Controller
          name="mustHaves"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={field.value || 'Enter must-have requirements...'}
              onChange={field.onChange}
            />
          )}
        />
        {errors.mustHaves && <FormHelperText>{errors.mustHaves.message}</FormHelperText>}
      </FormControl>

      <FormControl>
        <FormLabel>Nice-to-Have Requirements</FormLabel>
        <Controller
          name="niceToHaves"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={field.value || 'Enter nice-to-have requirements...'}
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>

      <FormControl error={!!errors.keyResponsibilities}>
        <FormLabel>Key Responsibilities</FormLabel>
        <Controller
          name="keyResponsibilities"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={field.value || 'Enter key responsibilities...'}
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>

      <FormControl error={!!errors.hiringProcess}>
        <FormLabel>Hiring Process</FormLabel>
        <Controller
          name="hiringProcess"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={field.value || 'Enter hiring process...'}
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>

      <FormControl error={!!errors.howToIntro}>
        <FormLabel>How to Introduce Candidates</FormLabel>
        <Controller
          name="howToIntro"
          control={control}
          render={({ field }) => (
            <RichTextEditor
              showMenuBar={false}
              content={field.value || 'Enter hiring process...'}
              onChange={field.onChange}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
}

import { Candidate } from '@bluebird-monorepo/types';
import { create } from 'zustand';

export type CandidateFiltersGroup = {
  conditions: CandidateFiltersGroupCondition[];
  id: string;
  operator: 'and' | 'or';
  subgroups: CandidateFiltersGroup[];
};

export type CandidateFiltersGroupCondition = {
  field: string;
  id: string;
  operator: string;
  value: boolean | number | string;
};

interface CandidateState {
  candidates: Candidate[];
  filters: CandidateFiltersGroup[];
  search: string;
  setCandidates: (candidates: Candidate[]) => void;
  setFilters: (filters: CandidateFiltersGroup[]) => void;
  setSearch: (search: string) => void;
  addCandidate: (candidate: Candidate) => void;
  updateCandidate: (id: string, updatedFields: Partial<Candidate>) => void;
  removeCandidate: (id: string) => void;
  currentCandidate: Candidate | null;
  setCurrentCandidate: (candidate: Candidate | null) => void;
}

export const useCandidateStore = create<CandidateState>((set) => ({
  candidates: [],
  filters: [],
  search: '',
  currentCandidate: null,
  setCandidates: (candidates) => set({ candidates }),
  setFilters: (filters) => set({ filters }),
  setSearch: (search) => set({ search }),
  setCurrentCandidate: (candidate) => set({ currentCandidate: candidate }),
  addCandidate: (candidate) => set((state) => ({ candidates: [...state.candidates, candidate] })),
  updateCandidate: (id, updatedFields) =>
    set((state) => ({
      candidates: state.candidates.map((candidate) =>
        candidate.id === id ? { ...candidate, ...updatedFields } : candidate,
      ),
      currentCandidate:
        state.currentCandidate?.id === id ? { ...state.currentCandidate, ...updatedFields } : state.currentCandidate,
    })),
  removeCandidate: (id) =>
    set((state) => ({
      candidates: state.candidates.filter((candidate) => candidate.id !== id),
    })),
}));

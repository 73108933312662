import { Box, CircularProgress } from '@mui/joy';

export const LoadingOverlay = () => (
  <Box
    sx={{
      alignItems: 'center',
      bgcolor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1300, // Ensure it overlays everything
    }}
  >
    <CircularProgress />
  </Box>
);

export const ParentLoadingOverlay = () => (
  <Box
    sx={{
      alignItems: 'center',
      bgcolor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      position: 'absolute', // Restrict overlay to parent
      top: 0,
      width: '100%',
      zIndex: 1300, // Ensure it overlays its parent content
    }}
  >
    <CircularProgress />
  </Box>
);

import { z } from "zod";

export const CandidateValidationSchema = z.object({
  availability: z.string().optional(),
  city: z.string().optional(),
  contactInfo: z
    .object({
      email: z.string().email().optional(),
      phone: z.string().optional().optional(),
    })
    .optional(),
  currentCountryResidingIn: z.string().optional(),
  currentOrganization: z.string().optional(),
  currentPosition: z.string().optional(),
  customSource: z.string().optional(),
  firstName: z.string().optional(),
  inOfficeExpectations: z.string().optional(),
  isOpenToRelocate: z.boolean().default(false),
  isOpenToWorkRemotely: z.boolean().default(false),
  languagesCandidateSpeak: z.array(z.string()).optional(),
  lastName: z.string().optional().default(""),
  linkedin: z.string().optional(),
  potentialRoles: z.array(z.string()).optional(),
  requiresVisa: z.boolean().optional().default(false),
  resumeUrl: z.string().optional(),
  salaryInformation: z.object({
    currentSalary: z.object({
      monthly: z.number(),
      yearly: z.number(),
      bonus: z.number().optional(),
      overrideCalculation: z.boolean().optional(),
      overrideReason: z.string().optional(),
    }),
    preferNotToDisclose: z.boolean().optional(),
    reasonForNonDisclosure: z.string().optional(),
    currentSalaryRangeGuess: z
      .object({
        min: z.number(),
        max: z.number(),
        currency: z.string(),
      })
      .optional(),
    salaryExpectationRange: z
      .object({
        min: z.number(),
        max: z.number(),
        currency: z.string(),
      })
      .optional(),
  }),
  score: z.number().optional().default(0),
  skills: z.array(z.string()).optional(),
  techStack: z.array(z.string()).optional(),
  workExperienceYears: z.number().optional(),
});

export type CandidateValidationSchemaType = z.infer<
  typeof CandidateValidationSchema
>;

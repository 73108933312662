import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Candidate } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { CandidatesRepository } from '../repositories/candidate.repository';
import { useCandidateStore } from '../store/candidates.store';

export const useCandidates = () => {
  const { firestore } = useFirebase();
  const candidateRepository = useMemo(() => new CandidatesRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { candidates, setCandidates, addCandidate, updateCandidate, removeCandidate } = useCandidateStore();

  const { data, isLoading, error } = useQuery({
    queryKey: ['candidates'],
    queryFn: () => candidateRepository.getAll(),
  });

  useEffect(() => {
    if (data) {
      setCandidates(data);
    }
  }, [data, setCandidates]);

  const useCandidate = (id: string) => {
    const setCurrentCandidate = useCandidateStore((state) => state.setCurrentCandidate);

    const query = useQuery({
      queryKey: ['candidates', id],
      queryFn: () => candidateRepository.getById(id),
      enabled: !!id,
    });

    useEffect(() => {
      if (query.data) {
        setCurrentCandidate(query.data);
      }
    }, [query.data, setCurrentCandidate]);

    return query;
  };

  const useCandidateByLinkedin = (linkedin: string) => {
    return useQuery({
      queryKey: ['candidates', 'linkedin', linkedin],
      queryFn: () => candidateRepository.getByLinkedin(linkedin),
      enabled: !!linkedin,
    });
  };

  const createCandidateMutation = useMutation({
    mutationFn: (newCandidate: Omit<Candidate, 'id'>) => candidateRepository.create(newCandidate),
    onSuccess: (candidate) => {
      addCandidate(candidate);
      queryClient.invalidateQueries({ queryKey: ['candidates'] });
    },
  });

  const updateCandidateMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Candidate> }) => candidateRepository.update(id, data),
    onSuccess: (_, variables) => {
      updateCandidate(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['candidates'] });
    },
  });

  const deleteCandidateMutation = useMutation({
    mutationFn: (id: string) => candidateRepository.delete(id),
    onSuccess: (_, id) => {
      removeCandidate(id);
      queryClient.invalidateQueries({ queryKey: ['candidates'] });
    },
  });

  return {
    candidates,
    isLoading,
    error,
    useCandidate,
    useCandidateByLinkedin,
    createCandidateMutation,
    updateCandidateMutation,
    createCandidate: createCandidateMutation.mutate,
    updateCandidate: updateCandidateMutation.mutate,
    deleteCandidate: deleteCandidateMutation.mutate,
  };
};

import type { DragStart, DropResult } from '@hello-pangea/dnd';

import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Component, ReactElement } from 'react';

import { useSelectionStore } from '../store/useSelectionStore';
import { KanbanColumn, KanbanState, Stage } from '../types';
import { reorderCards } from '../utils/reorder';
import Column from './Column';

interface ParentContainerProps {
  isCombineEnabled?: boolean;
  onStatusUpdate?: (candidateId: string, statusId: number, status: string, jobId: string) => void;
  stages?: Stage[];
  useClone?: boolean;
  withScrollableColumns?: boolean;
  initial: KanbanColumn;
}

export default class KanbanBoard extends Component<ParentContainerProps, KanbanState> {
  state: KanbanState = {
    columns: this.props.initial || {},
    ordered: this.props.stages?.sort((a, b) => a.order - b.order).map((stage) => stage.name) || [],
    isDraggingSelected: false,
  };

  mapColumnsToNames = (columns: KanbanColumn): KanbanColumn => {
    if (!columns || !this.props.stages) return columns;

    const mappedColumns: KanbanColumn = {};
    Object.entries(columns).forEach(([key, value]) => {
      const stage = this.props.stages?.find((s) => s.id === key);
      if (stage) {
        mappedColumns[stage.name] = value;
      }
    });
    return mappedColumns;
  };

  // When props change, update the ordered list
  componentDidUpdate(prevProps: ParentContainerProps) {
    if (prevProps.stages !== this.props.stages) {
      this.setState({
        ordered: this.props.stages?.sort((a, b) => a.order - b.order).map((stage) => stage.name) || [],
      });
    }
    if (prevProps.initial !== this.props.initial) {
      this.setState({
        columns: this.props.initial || {},
      });
    }
  }

  onDragStart = (initial: DragStart) => {
    const selectedItems = useSelectionStore.getState().selectedItems;
    const id = initial.draggableId.split('&&').reduce((acc, curr) => {
      const [key, value] = curr.split(':');
      return key === 'jobId' ? `${value}_${acc}` : `${acc}${value}`;
    }, '');

    this.setState({
      isDraggingSelected: selectedItems.has(id),
    });
  };

  onDragEnd = (result: DropResult) => {
    // Always reset dragging state at the start
    this.setState({
      isDraggingSelected: false,
    });

    const selectedItems = useSelectionStore.getState().selectedItems;
    const destination = result.destination;
    const source = result.source;

    // nothing to do
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Handle multi-drag
    if (this.state.isDraggingSelected && selectedItems.size > 1) {
      const tempDestination = this.camelize(destination.droppableId);
      const stageId: string = tempDestination?.charAt(0)?.toUpperCase() + tempDestination?.slice(1) || '';
      const stage = this.props.stages?.find((s) => s.id == stageId);
      const statusId = Number(stage?.id);
      // Update all selected items
      selectedItems.forEach((itemId) => {
        const [itemJobId, itemCandidateId] = itemId.split('_');
        this.props.onStatusUpdate?.(itemCandidateId, statusId, stage?.name || '', itemJobId);
      });

      // Update UI for all selected items
      const data = reorderCards({
        destination,
        source,
        cardMap: this.state.columns,
        selectedCardIds: selectedItems,
      });

      this.setState({
        columns: data.cardMap,
        isDraggingSelected: false,
      });
      return;
    }

    // Handle single drag
    const draggedItemId = result.draggableId.split('&&').reduce((acc, curr) => {
      const [key, value] = curr.split(':');
      return key === 'jobId' ? `${value}_${acc}` : `${acc}${value}`;
    }, '');

    const [jobId, candidateId] = draggedItemId.split('_');
    const tempDestination = this.camelize(destination.droppableId);
    const stageId: string = tempDestination?.charAt(0)?.toUpperCase() + tempDestination?.slice(1) || '';
    const stage = this.props.stages?.find((s) => s.id == stageId);
    const statusId = Number(stage?.id);

    this.props.onStatusUpdate?.(candidateId, statusId, status, jobId);

    // Update UI for single item
    const data = reorderCards({
      destination,
      source,
      cardMap: this.state.columns,
    });

    this.setState({
      columns: data.cardMap,
      isDraggingSelected: false,
    });
  };

  // Helper function to convert status to camelCase
  camelize = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(/\s+/g, '');
  };

  render(): ReactElement {
    const columns: KanbanColumn = this.state.columns;
    const stages = this.props.stages;
    const { isCombineEnabled, useClone, withScrollableColumns } = this.props;

    // Sort stages by order and map to IDs (keeping ordered as IDs)
    const ordered = stages?.sort((a, b) => a.order - b.order).map((stage) => stage.id) || [];

    // Ensure all stages are represented in columns
    const completeColumns = { ...columns };
    stages?.forEach((stage) => {
      if (!completeColumns[stage.id]) {
        completeColumns[stage.id] = [];
      }
    });

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                padding: '1rem',
              }}
            >
              {ordered.map((stageId: string, index: number) => {
                const stage = stages?.find((s) => s.id === stageId);
                return (
                  <Column
                    key={stageId}
                    cards={completeColumns[stageId] || []}
                    stage={stage}
                    title={stage?.name || ''}
                    useClone={useClone}
                    isDraggingSelected={this.state.isDraggingSelected}
                    isCombineEnabled={isCombineEnabled}
                    isScrollable={withScrollableColumns}
                    index={index}
                    bgColor={stage?.color || '#ddd'}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DeleteOutlineRounded, DragIndicatorRounded } from '@mui/icons-material';
import { IconButton, Input, Option, Select, Stack } from '@mui/joy';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

const ConditionRow: FC<{
  conditionId: any;
  control: Control<any>;
  groupPath: string;
  index: number;
  onRemove: () => void;
}> = ({ conditionId, control, groupPath, index, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: conditionId,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Stack
      alignItems="center"
      bgcolor="neutral.200"
      border="1px solid #ccc"
      borderRadius={7}
      direction="row"
      display="grid"
      gridTemplateColumns="0.08fr 0.8fr 1fr 1fr 0.08fr"
      pl={0.5}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <DragIndicatorRounded sx={{ outline: 'none' }} {...listeners} />
      <Controller
        control={control}
        name={`${groupPath}.conditions.${index}.field` as any}
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={(_, value) => onChange(value)}
            placeholder="Field"
            sx={{ border: 'none', borderRadius: 0, minWidth: 150 }}
            value={value}
          >
            <Option value="firstName">First Name</Option>
            <Option value="lastName">Last Name</Option>
            <Option value="email">Email</Option>
            <Option value="workExperienceYears">Work Experience</Option>
            <Option value="salaryInformation.currentSalary.monthly">Monthly Gross Salary</Option>
            <Option value="salaryInformation.currentSalary.yearly">Yearly Gross Salary</Option>
            <Option value="score">Score</Option>
            <Option value="country">Country</Option>
            <Option value="city">City</Option>
            <Option value="country">Country</Option>
            <Option value="currentPosition">Current Position</Option>
            <Option value="jobStatus">Job Status</Option>
            <Option value="jobAssignmentStatusId">Assignment Status ID</Option>
            <Option value="requiresVisa">Requires Visa</Option>
          </Select>
        )}
      />
      <Controller
        control={control}
        name={`${groupPath}.conditions.${index}.operator` as any}
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={(_, value) => onChange(value)}
            placeholder="Operator"
            sx={{ border: 'none', borderRadius: 0, minWidth: 150 }}
            value={value}
          >
            <Option value="equals">Equals</Option>
            <Option value="contains">Contains</Option>
            <Option value="startsWith">Starts With</Option>
            <Option value="greaterThan">Greater Than</Option>
            <Option value="lessThan">Less Than</Option>
          </Select>
        )}
      />
      <Controller
        control={control}
        name={`${groupPath}.conditions.${index}.value` as any}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            placeholder="Value"
            sx={{ border: 'none', borderRadius: 0, minWidth: 150 }}
            value={value}
          />
        )}
      />
      <IconButton color="neutral" onClick={onRemove}>
        <DeleteOutlineRounded />
      </IconButton>
    </Stack>
  );
};

export default ConditionRow;

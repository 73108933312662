import type { DraggableProvided } from '@hello-pangea/dnd';

import { LinkedIn } from '@mui/icons-material';
import { Avatar, Badge, Box, Divider, IconButton, Link } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import React, { CSSProperties, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelectionStore } from '../store/useSelectionStore';
import { CandidateCard } from '../types';

interface CongratCardProps {
  provided: DraggableProvided;
  card: CandidateCard;
  isSelected: boolean;
  isDragging: boolean;
  columnId: string;
}

function CongratCard({ provided, card, isSelected, isDragging, columnId }: CongratCardProps) {
  const navigate = useNavigate();
  const toggleSelection = useSelectionStore((state) => state.toggleSelection);
  const selectedItems = useSelectionStore((state) => state.selectedItems);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const id = `${card.jobId}_${card.candidateId}`;

      // If card is already selected and no modifier keys are pressed,
      // clicking it again should deselect it
      if (isSelected && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
        useSelectionStore.getState().clearSelection();
        return;
      }

      toggleSelection(id, e.ctrlKey || e.metaKey, e.shiftKey, columnId);
    },
    [card.jobId, card.candidateId, toggleSelection, columnId, isSelected],
  );

  const selectedCount = selectedItems.size;
  const id = `${card.jobId}_${card.candidateId}`;
  const isPartOfSelection = selectedItems.has(id);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={handleClick}
      style={{
        ...provided.draggableProps.style,
        opacity: isDragging && !isPartOfSelection ? 0.5 : 1,
      }}
    >
      <Badge
        badgeContent={isDragging && isPartOfSelection && selectedCount > 1 ? selectedCount : undefined}
        color="primary"
        size="lg"
        sx={{
          '--Badge-ring': '0 0 0 2px transparent',
          '--Badge-minHeight': '0px',
          '& .MuiChip-root': {
            fontSize: '1rem',
            height: '24px',
            minWidth: '24px',
          },
          color: 'transparent',
        }}
      >
        <Card
          sx={{
            '--Card-padding': '0px',
            backgroundColor: isSelected ? 'var(--joy-palette-primary-50)' : 'white',
            border: isSelected ? '2px solid var(--joy-palette-primary-500)' : undefined,
            opacity: isDragging && !isPartOfSelection ? 0.5 : 1,
            transform: isDragging && isPartOfSelection ? 'rotate(-2deg)' : 'none',
            transition: 'transform 0.2s ease, opacity 0.2s ease',
            width: '250px',
          }}
        >
          <CardContent>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1.5, my: 1, px: 1 }}>
              <Avatar
                alt={card.candidateFirstName}
                src={card.avatar}
                sx={{
                  '--Avatar-size': '32px',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                    transform: 'scale(1.05)',
                  },
                  transition: 'all 0.2s ease',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/candidates/${card.candidateId}`);
                }}
              />
              <Box sx={{ flex: 1 }}>
                <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5, justifyContent: 'space-between' }}>
                  <Typography
                    level="body-sm"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'underline',
                      },
                      transition: 'all 0.2s ease',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/candidates/${card.candidateId}`);
                    }}
                  >
                    {card.candidateFirstName} {card.candidateLastName}
                  </Typography>
                  <Link href={card.link} target="_blank">
                    <IconButton
                      className="icon-button"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      size="sm"
                      variant="plain"
                    >
                      <LinkedIn />
                    </IconButton>
                  </Link>
                </Box>
                <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                  {card.currentPosition.slice(0, 10) + '...'} @ {card.currentOrganization.slice(0, 10) + '...'}
                </Typography>
              </Box>
            </Box>

            <Divider />

            <IconButton
              className="icon-button"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/jobs/' + card.jobId);
              }}
              target="_blank"
              variant="plain"
              sx={{ py: 1 }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  borderRadius: '8px', // Adjust border radius if needed
                  display: 'flex',
                  height: 35, // Height of the rectangle (adjust as needed)
                  justifyContent: 'center',
                  mr: 1,
                  width: 35, // Width of the rectangle (adjust as needed)
                }}
              >
                <img alt="Logo" src={card.logoUrl} style={{ height: '35px', width: '35px' }} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography level="body-xs" sx={{ textAlign: 'left' }}>
                  {card.jobTitle}
                </Typography>{' '}
                <Typography level="body-xs" sx={{ textAlign: 'left' }}>
                  Company: <span style={{ fontWeight: 'bold' }}> {card.companyName}</span>
                </Typography>
              </Box>
            </IconButton>

            <Divider />
          </CardContent>
        </Card>
      </Badge>
    </div>
  );
}

interface Props {
  index?: number;
  isClone?: boolean;
  isDragging: boolean;
  isGroupedOver?: boolean;
  provided: DraggableProvided;
  card: CandidateCard;
  style?: CSSProperties;
  columnId: string;
}

const CandidateAssignedToJobItem = ({ card, isDragging, isGroupedOver = false, provided, style, columnId }: Props) => {
  const isSelected = useSelectionStore((state) => state.isSelected(`${card.jobId}_${card.candidateId}`));

  return (
    <CongratCard provided={provided} card={card} isSelected={isSelected} isDragging={isDragging} columnId={columnId} />
  );
};

export default React.memo<Props>(CandidateAssignedToJobItem);

import { Job } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface JobsState {
  jobs: Job[];
  setJobs: (jobs: Job[]) => void;
  addJob: (job: Job) => void;
  updateJob: (id: string, job: Partial<Job>) => void;
  removeJob: (id: string) => void;
  setResponsibles: (responsibles: { name: string }[]) => void;
  setStatuses: (statuses: { name: string; displayName: string }[]) => void;
  statuses: { name: string; displayName: string }[];
  responsibles: { name: string }[];
  currentJob: Job | null;
  setCurrentJob: (job: Job | null) => void;
}

export const useJobsStore = create<JobsState>((set) => ({
  jobs: [],
  responsibles: [],
  statuses: [],
  currentJob: null,
  setJobs: (jobs) => set({ jobs }),
  addJob: (job) => set((state) => ({ jobs: [...state.jobs, job] })),
  updateJob: (id, updatedJob) =>
    set((state) => ({
      jobs: state.jobs.map((job) =>
        job.id === id ? { ...job, ...updatedJob } : job
      ),
    })),
  removeJob: (id) =>
    set((state) => ({
      jobs: state.jobs.filter((job) => job.id !== id),
    })),
  setResponsibles: (responsibles) => set({ responsibles }),
  setStatuses: (statuses) => set({ statuses }),
  setCurrentJob: (job) => set({ currentJob: job }),
}));

import { Stage } from '@bluebird-monorepo/kanban';
import { CandidateJobAssignmentInPipeline } from '@bluebird-monorepo/types';

export function mapToPipeline(data: any[]): CandidateJobAssignmentInPipeline[] {
  return data.map((item) => {
    const stageId = item.status_id;
    const stageName = item.status || 'Unknown Stage';
    const colors = {
      hard: '#fffA30',
      soft: '#FF5630',
    };

    return {
      avatar: item.avatar || '',
      candidateFirstName: item.candidateFirstName || '',
      candidateId: item.candidateId,
      candidateLastName: item.candidateLastName || '',
      companyName: item.companyName || '',
      content: `Candidate ${item.candidateFirstName || 'Unknown'} is currently in the stage: ${stageName}`,
      currentOrganization: item.currentOrganization || '',
      currentPosition: item.currentPosition || '',
      id: item.id || item.candidateId || 'Unknown',
      jobId: item.jobId,
      jobTitle: item.jobTitle || '',
      link: item.link || 'https://example.com',
      logoUrl: item.logoUrl || '',
      owner: item.owner,
      stage: {
        colors,
        id: stageId,
        name: stageName,
        color: colors.hard,
        order: item.order || 0,
      },
    };
  });
}

export const createStagePipelineMap = (
  stages: Stage[],
  assignments: CandidateJobAssignmentInPipeline[],
): Record<string, CandidateJobAssignmentInPipeline[]> => {
  const sortedStages = [...stages].sort((a, b) => (a.order || 0) - (b.order || 0));
  return sortedStages.reduce(
    (acc, stage) => {
      const stageAssignments = assignments.filter((assignment) => assignment.stage.id === stage.id);
      acc[stage.id] = stageAssignments;
      return acc;
    },
    {} as Record<string, CandidateJobAssignmentInPipeline[]>,
  );
};

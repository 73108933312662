import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useOwnerCandidatesAssignments } from '@bluebird-monorepo/candidateJobAssignments';
import {
  CandidateCard,
  KanbanBoard,
  KanbanColumn,
  KanbanSettingsDrawer,
  useKanbanSettings,
} from '@bluebird-monorepo/kanban';
import { useUser } from '@bluebird-monorepo/users';
import React, { useCallback, useMemo, useState } from 'react';
import { Layout } from '../../layout/Layout';

const DashboardPage: React.FC = () => {
  const { auth } = useFirebase();
  const { user } = useUser(auth);
  const { stageMap, assignments, updateAssignment, isLoading, error } = useOwnerCandidatesAssignments(user?.id);
  const { selectedJobIds, hiddenStageIds, stageOrder } = useKanbanSettings();
  const [open, setOpen] = useState(false);

  // Get unique job IDs from assignments
  const activeJobIds = useMemo(() => {
    if (!assignments) return [];
    return [
      ...new Set(assignments.map((assignment) => assignment.jobId).filter((id): id is string => id !== undefined)),
    ];
  }, [assignments]);

  // Filter stageMap based on selected jobs and hidden stages
  const filteredStageMap = useMemo(() => {
    if (!stageMap) return {};

    // Create a new map with only visible stages and owned assignments
    const visibleStages = Object.entries(stageMap).reduce((acc, [stageName, cards]) => {
      // Skip hidden stages
      if (hiddenStageIds.includes(stageName)) return acc;

      // Filter cards by selected jobs (if any are selected) and ownership
      const filteredCards =
        selectedJobIds.length > 0
          ? cards
              .filter(
                (card) =>
                  card.jobId && card.candidateId && selectedJobIds.includes(card.jobId) && card.owner === user?.id,
              )
              .map((card) => ({
                ...card,
                jobId: card.jobId!,
                candidateId: card.candidateId!,
                logoUrl: card.logoUrl,
              }))
          : cards
              .filter((card) => card.jobId && card.candidateId && card.owner === user?.id)
              .map((card) => ({
                ...card,
                jobId: card.jobId!,
                candidateId: card.candidateId!,
                logoUrl: card.logoUrl,
              }));

      acc[stageName] = filteredCards as CandidateCard[];
      return acc;
    }, {} as KanbanColumn);

    // Sort stages according to stageOrder if defined
    if (stageOrder.length > 0) {
      const orderedMap = {} as KanbanColumn;
      stageOrder.forEach((stageName) => {
        if (visibleStages[stageName] !== undefined) {
          orderedMap[stageName] = visibleStages[stageName];
        }
      });
      return orderedMap;
    }

    return visibleStages;
  }, [stageMap, selectedJobIds, hiddenStageIds, stageOrder, user?.id]);

  // Handle status update during drag-and-drop
  const handleStatusUpdate = useCallback(
    (candidateId: string, statusId: number, statusLabel: string, jobId: string) => {
      updateAssignment({
        id: jobId + '_' + candidateId,
        data: {
          candidateId,
          jobId,
          status_id: statusId,
          status: statusLabel,
        },
      });
    },
    [updateAssignment],
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!stageMap) {
    return <div>No stages configured</div>;
  }
  return (
    <Layout
      title="Dashboard"
      actionButton={{
        label: 'Settings for Kanban Board',
        onClick: () => setOpen(true), // We'll need to lift this state up
        variant: 'dashed',
      }}
    >
      <div style={{ position: 'relative' }}>
        <KanbanSettingsDrawer
          activeJobIds={activeJobIds}
          open={open} // Add these props
          setOpen={setOpen} // Add these props
        />
        <KanbanBoard initial={filteredStageMap} onStatusUpdate={handleStatusUpdate} withScrollableColumns={true} />
      </div>
    </Layout>
  );
};

export default DashboardPage;

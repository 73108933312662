import { FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

type InvoicingDetails = {
  invoicingDetails: {
    registeredName: string;
    address: string;
    city: string;
    zipCode: string;
    country: string;
    kvkNumber: string;
    taxNumber: string;
  };
};

export function InvoicingStep() {
  const {
    register,
    formState: { errors },
  } = useFormContext<InvoicingDetails>();

  return (
    <Stack spacing={3}>
      <Typography level="h4">Invoicing Details (Optional)</Typography>

      <FormControl error={!!errors.invoicingDetails?.registeredName}>
        <FormLabel>Registered Company Name (Optional)</FormLabel>
        <Input {...register('invoicingDetails.registeredName')} placeholder="Legal entity name" />
        {errors.invoicingDetails?.registeredName && (
          <FormHelperText>{errors.invoicingDetails.registeredName.message as string}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.invoicingDetails?.address}>
        <FormLabel>Street Address (Optional)</FormLabel>
        <Input {...register('invoicingDetails.address')} placeholder="Street address" />
        {errors.invoicingDetails?.address && (
          <FormHelperText>{errors.invoicingDetails.address.message as string}</FormHelperText>
        )}
      </FormControl>

      <Stack direction="row" spacing={2}>
        <FormControl error={!!errors.invoicingDetails?.city} sx={{ flex: 1 }}>
          <FormLabel>City (Optional)</FormLabel>
          <Input {...register('invoicingDetails.city')} placeholder="City" />
          {errors.invoicingDetails?.city && (
            <FormHelperText>{errors.invoicingDetails.city.message as string}</FormHelperText>
          )}
        </FormControl>

        <FormControl error={!!errors.invoicingDetails?.zipCode} sx={{ flex: 1 }}>
          <FormLabel>Postal Code (Optional)</FormLabel>
          <Input {...register('invoicingDetails.zipCode')} placeholder="Postal code" />
          {errors.invoicingDetails?.zipCode && (
            <FormHelperText>{errors.invoicingDetails.zipCode.message as string}</FormHelperText>
          )}
        </FormControl>
      </Stack>

      <FormControl error={!!errors.invoicingDetails?.country}>
        <FormLabel>Country (Optional)</FormLabel>
        <Input {...register('invoicingDetails.country')} placeholder="Country" />
        {errors.invoicingDetails?.country && (
          <FormHelperText>{errors.invoicingDetails.country.message as string}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.invoicingDetails?.kvkNumber}>
        <FormLabel>KvK Number (Optional)</FormLabel>
        <Input {...register('invoicingDetails.kvkNumber')} placeholder="Chamber of Commerce number" />
        {errors.invoicingDetails?.kvkNumber && (
          <FormHelperText>{errors.invoicingDetails.kvkNumber.message as string}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.invoicingDetails?.taxNumber}>
        <FormLabel>Tax Number (Optional)</FormLabel>
        <Input {...register('invoicingDetails.taxNumber')} placeholder="VAT/Tax identification number" />
        {errors.invoicingDetails?.taxNumber && (
          <FormHelperText>{errors.invoicingDetails.taxNumber.message as string}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
}

import { Job } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import { FC } from 'react';
import { useJobEvents } from '../../../../hooks/useJobEvents';
import { EventList } from './EventList';

interface EventsTabProps {
  editableJob: Job;
}

export const EventsTab: FC<EventsTabProps> = ({ editableJob }) => {
  const { events, stats } = useJobEvents(editableJob.id);
  if (!events) return null;

  return (
    <Box>
      {events.length > 0 ? (
        <EventList events={events} stats={stats || { total: 0, scheduled: 0 }} />
      ) : (
        <Typography level="body-sm">No events associated with this job.</Typography>
      )}
    </Box>
  );
};

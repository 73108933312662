import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore } from 'firebase/firestore';

export interface TechnologyDocument {
  id: string;
  name: string;
  category?: string;
}

export class TechnologiesRepository extends BaseRepository<TechnologyDocument> {
  constructor(db: Firestore) {
    super(db, 'technologies');
  }
}

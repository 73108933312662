import { useStages } from '@bluebird-monorepo/kanban';
import { useMemo } from 'react';
import { createStagePipelineMap, mapToPipeline } from '../utils/pipeline.utils';
import { useCandidateJobAssignments } from './useCandidateJobAssignments';

export const useOwnerCandidatesAssignments = (ownerId: string) => {
  const { stages, loading: stagesLoading, error: stagesError } = useStages();
  const { getAssignmentsByOwner, updateAssignment } = useCandidateJobAssignments();
  const {
    data: rawAssignments,
    error: assignmentsError,
    isLoading: assignmentsLoading,
  } = getAssignmentsByOwner(ownerId);

  const pipelineAssignments = useMemo(() => (rawAssignments ? mapToPipeline(rawAssignments) : []), [rawAssignments]);

  const stageMap = useMemo(() => {
    if (!stages) return null;
    return createStagePipelineMap(stages, pipelineAssignments);
  }, [pipelineAssignments, stages]);

  return {
    assignments: pipelineAssignments,
    stageMap,
    error: stagesError || assignmentsError,
    isLoading: stagesLoading || assignmentsLoading,
    updateAssignment,
  };
};

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import Link from '@mui/joy/Link';

interface CompaniesTableHeaderProps {
  onSortChange: (column: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

export function CompaniesTableHeader({ onSortChange, order, orderBy }: CompaniesTableHeaderProps) {
  const createSortHandler = (property: string) => () => {
    onSortChange(property);
  };

  const renderSortIcon = (column: string) => {
    return orderBy === column ? (
      order === 'asc' ? (
        <ArrowDropUpRounded color="primary" sx={{ height: 30, width: 30 }} />
      ) : (
        <ArrowDropDownRounded color="primary" sx={{ height: 30, width: 30 }} />
      )
    ) : null;
  };

  return (
    <thead>
      <tr>
        <th
          onClick={createSortHandler('name')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '35%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Company Name
            {renderSortIcon('name')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('owner')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '25%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Owner
            {renderSortIcon('owner')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('status')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '20%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Status
            {renderSortIcon('status')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('activeJobs')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '20%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Active Jobs
            {renderSortIcon('activeJobs')}
          </Link>
        </th>
      </tr>
    </thead>
  );
}

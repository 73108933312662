import { Placement } from '@bluebird-monorepo/types';

export function formatDate(date: any) {
  if (!date) return 'N/A';
  let dateObj;

  if (date.toDate) {
    dateObj = date.toDate();
  } else if (typeof date === 'object' && date.seconds) {
    dateObj = new Date(date.seconds * 1000);
  } else if (typeof date === 'string' || typeof date === 'number') {
    dateObj = new Date(date);
  } else {
    dateObj = date;
  }

  return dateObj.toLocaleDateString();
}

export function formatFee(fee: number) {
  return fee ? `€${Number(fee).toLocaleString('nl-NL', { minimumFractionDigits: 0 })}` : 'N/A';
}

export function calculateTotalFee(placements: Placement[]) {
  return placements.reduce((total, placement) => {
    const feeValue = Number(placement.fee) || 0;
    return total + feeValue;
  }, 0);
}

export const repsToShow = ['Dennis', 'Jacob', 'Mitchel', 'Pontus'];

export const responsibleMapping = {
  Dennis: {
    colorClass: 'mint-green',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U05QQC9SJ1W-8fccbf482c28-512',
    name: 'Dennis',
  },
  Jacob: {
    colorClass: 'blush-pink',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U06T7S16K6W-ab5ce9b172f2-192',
    name: 'Jacob',
  },
  Mitchel: {
    colorClass: 'pale-lavender',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U07RZ07JSDP-d3370936361e-192',
    name: 'Mitchel',
  },
  Pontus: {
    colorClass: 'powder-blue',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U075S4N9M6V-dec8ff679f17-192',
    name: 'Pontus',
  },
};

import { useJobCandidateAssignments } from '@bluebird-monorepo/candidateJobAssignments';
import { useJobs } from '@bluebird-monorepo/jobs';
import { type Candidate } from '@bluebird-monorepo/types';
import { Autocomplete, Box, Button, Divider, List, Snackbar, Stack, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { CandidateJob } from './CandidateJob';

interface JobOption {
  id: string;
  label: string;
  company: string;
}

interface JobAssignmentSectionProps {
  candidate: Candidate;
}

export const JobAssignmentSection: FC<JobAssignmentSectionProps> = ({ candidate }) => {
  const { jobs } = useJobs();
  const { assignments, createAssignment } = useJobCandidateAssignments(candidate.id);

  const [errorMessage, setErrorMessage] = useState('');
  const [selectedJob, setSelectedJob] = useState<JobOption | null>(null);

  if (!assignments) return null;
  const assignedJobs = assignments
    .filter((assignment) => assignment.candidateId === candidate.id)
    .map((assignment) => assignment.jobId)
    .filter((jobId): jobId is string => jobId !== undefined);

  const isCandidateAlreadyAssignedToJob = selectedJob?.id && assignedJobs.includes(selectedJob.id);

  const getJobOptions = (): JobOption[] => {
    return (
      jobs?.map((job) => ({
        id: job.id,
        label: job.jobTitle,
        company: job.companyName || '',
      })) || []
    );
  };

  const handleAssignToJob = async () => {
    if (!selectedJob?.id) return;

    if (isCandidateAlreadyAssignedToJob) {
      setErrorMessage('Candidate is already assigned to this job.');
      return;
    }

    // TODO: Don't hardcode the status
    try {
      createAssignment({
        id: `${selectedJob.id}_${candidate.id}`,
        jobId: selectedJob.id,
        candidateId: candidate.id,
        status: 'Applied',
        status_id: 1,
      });
      setSelectedJob(null);
    } catch (error) {
      setErrorMessage(
        `Failed to assign candidate: ${error instanceof Error ? error.message : 'An unknown error occurred.'}`,
      );
    }
  };

  return (
    <Box>
      <Stack direction="row" gap={1} my={2} width="100%">
        <Autocomplete
          onChange={(_, value) => setSelectedJob(value)}
          options={getJobOptions()}
          getOptionLabel={(option) => `${option.label} - ${option.company}`}
          placeholder="Select a job..."
          sx={{ width: '100%' }}
          value={selectedJob}
        />
        <Button disabled={!selectedJob} onClick={handleAssignToJob} sx={{ whiteSpace: 'nowrap' }} variant="outlined">
          Assign to Job
        </Button>
      </Stack>

      <Divider />
      {assignedJobs.length > 0 ? (
        <>
          <Typography level="h4" pb={2}>
            List of Jobs
          </Typography>
          <List>
            {assignedJobs.map((jobId) => (
              <CandidateJob id={jobId} key={jobId} handleAuditLog={() => {}} />
            ))}
          </List>
        </>
      ) : (
        <Typography level="body-md" mt={4} pb={2} textAlign="center">
          No jobs assigned
        </Typography>
      )}

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        color="danger"
        onClose={() => setErrorMessage('')}
        open={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </Box>
  );
};

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export const useStorage = () => {
  const { storage } = useFirebase();

  const uploadFiles = async (files: File[], path: string): Promise<string[]> => {
    if (!storage) throw new Error('Storage not initialized');

    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(storage, `${path}/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      return getDownloadURL(snapshot.ref);
    });

    return Promise.all(uploadPromises);
  };

  return {
    storage,
    uploadFiles,
  };
};

import { BluebirdCard, RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { Job } from '@bluebird-monorepo/types';
import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material';
import { Box, CardContent, Divider, Typography, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';

type SectionKey =
  | 'mustHaves'
  | 'niceToHaves'
  | 'keyResponsibilities'
  | 'idealCandidateProfile'
  | 'hiringProcess'
  | 'howToIntro';

type Section = {
  key: SectionKey;
  order: number;
  placeholder: string;
  title: string;
};

type SidePanelsProps = {
  job: Job;
};

const SidePanels: FC<SidePanelsProps> = ({ job }) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm')); // 0-600px
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600-900px
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900-1200px
  const isLarge = useMediaQuery(theme.breakpoints.up('lg')); // 1200px+

  // Layout configuration based on screen size
  const layoutConfig = {
    gridColumns: {
      xs: '1fr',
      sm: 'repeat(1, 1fr)',
      md: 'repeat(2, 1fr)',
      lg: 'repeat(2, 1fr)',
    },
    spacing: {
      xs: 2,
      sm: 2.5,
      md: 3,
      lg: 4,
    },
    fontSize: {
      title: {
        xs: 14,
        sm: 15,
        md: 16,
      },
      body: {
        xs: 13,
        sm: 14,
        md: 15,
      },
    },
    padding: {
      xs: 0.5,
      sm: 0.75,
      md: 0.75,
    },
  };

  const sections: Section[] = [
    {
      key: 'mustHaves',
      order: 1,
      placeholder: 'List essential skills and qualifications required for this role.',
      title: 'Must haves',
    },
    {
      key: 'niceToHaves',
      order: 2,
      placeholder: 'List nice-to-have skills and qualifications required for this role.',
      title: 'Nice to haves',
    },
    {
      key: 'keyResponsibilities',
      order: 3,
      placeholder: 'List key responsibilities for this role.',
      title: 'Key responsibilities',
    },
    {
      key: 'idealCandidateProfile',
      order: 4,
      placeholder: "Describe the ideal candidate's experience, traits, and skills.",
      title: 'Candidate Profile',
    },
    {
      key: 'hiringProcess',
      order: 5,
      placeholder: 'Outline the steps in the hiring process, including timelines and methods.',
      title: 'Hiring process',
    },
    {
      key: 'howToIntro',
      order: 6,
      placeholder: 'Provide tips for introducing candidates to the team and organization.',
      title: 'How to Intro?',
    },
  ];

  const [isResponsibilitiesCollapsed, setResponsibilitiesCollapsed] = useState(true);

  return (
    <Box
      sx={{
        display: 'grid',
        gap: {
          xs: layoutConfig.spacing.xs,
          sm: layoutConfig.spacing.sm,
          md: layoutConfig.spacing.md,
          lg: layoutConfig.spacing.lg,
        },
        gridTemplateColumns: {
          xs: layoutConfig.gridColumns.xs,
          sm: layoutConfig.gridColumns.sm,
          md: layoutConfig.gridColumns.md,
          lg: layoutConfig.gridColumns.lg,
        },
        width: '100%',
      }}
    >
      {sections.map((section) => (
        <BluebirdCard
          key={section.key}
          sx={{
            order: section.order,
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: isXSmall ? 'none' : 'translateY(-4px)',
              boxShadow: isXSmall ? 'none' : theme.shadow.md,
            },
          }}
        >
          <CardContent
            sx={{
              p: {
                xs: layoutConfig.padding.xs,
                sm: layoutConfig.padding.sm,
                md: layoutConfig.padding.md,
              },
            }}
          >
            <Typography
              level="body-md"
              startDecorator={
                <InfoOutlined
                  sx={{
                    fontSize: {
                      xs: '1.1rem',
                      sm: '1.2rem',
                      md: '1.3rem',
                    },
                  }}
                />
              }
              sx={{
                alignItems: 'center',
                fontSize: layoutConfig.fontSize.title,
                fontWeight: 'bold',
                pb: {
                  xs: 1,
                  sm: 1.5,
                  md: 2,
                },
                cursor: 'pointer',
                display: 'flex',
                gap: 1,
              }}
              onClick={() => setResponsibilitiesCollapsed(!isResponsibilitiesCollapsed)}
            >
              {section.title}
              {section.key === 'keyResponsibilities' &&
                (isResponsibilitiesCollapsed ? (
                  <ExpandMore sx={{ fontSize: '1.25rem' }} />
                ) : (
                  <ExpandLess sx={{ fontSize: '1.25rem' }} />
                ))}
            </Typography>

            <Divider
              inset="none"
              sx={{
                my: {
                  xs: 1,
                  sm: 1.5,
                  md: 2,
                },
              }}
            />

            {section.key !== 'keyResponsibilities' || !isResponsibilitiesCollapsed ? (
              <RawHTML
                children={job?.[section.key] || ''}
                style={{
                  padding: theme.spacing(1),
                  fontSize: isXSmall
                    ? layoutConfig.fontSize.body.xs
                    : isSmall
                      ? layoutConfig.fontSize.body.sm
                      : layoutConfig.fontSize.body.md,
                  lineHeight: 1.6,
                }}
              />
            ) : null}
          </CardContent>
        </BluebirdCard>
      ))}
    </Box>
  );
};

export default SidePanels;

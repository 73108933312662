import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { FilterAlt } from '@mui/icons-material';
import { Button, Divider, Stack, Tooltip } from '@mui/joy';
import { nanoid } from 'nanoid';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import type { CandidateFiltersGroup, CandidateFiltersGroupCondition } from '../../../store/candidates.store';
import { useCandidateStore } from '../../../store/candidates.store';
import GroupForm from './GroupForm';

type CandidatesFiltersForm = {
  groups: CandidateFiltersGroup[];
};

const CandidatesFilterPopup: FC = () => {
  const [open, setOpen] = useState(false);
  const setFilters = useCandidateStore((state) => state.setFilters);

  const { control, getValues, handleSubmit, setValue, watch } = useForm<CandidatesFiltersForm>({
    defaultValues: {
      groups: [
        {
          conditions: [],
          id: nanoid(),
          operator: 'and',
          subgroups: [],
        },
      ],
    },
  });

  const groups = watch('groups');

  const onSubmit: SubmitHandler<CandidatesFiltersForm> = (data) => {
    setFilters(data.groups);
    setOpen(false);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // findConditionPath remains the same as your original implementation
  const findConditionPath = (filtersGroup: CandidateFiltersGroup, condId: string, path = 'groups.0'): null | string => {
    const conditionIndex = filtersGroup.conditions.findIndex((condition) => condition.id === condId);
    if (conditionIndex !== -1) return `${path}.conditions`;

    for (let i = 0; i < filtersGroup.subgroups.length; i++) {
      const subgroupPath = `${path}.subgroups.${i}`;
      const result = findConditionPath(filtersGroup.subgroups[i], condId, subgroupPath);
      if (result) return result;
    }
    return null;
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const findPath = findConditionPath(groups[0], active.id as string);
    if (!findPath) return;

    const items = getValues(findPath as any) as CandidateFiltersGroupCondition[];

    const reorderedItems = (() => {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      if (oldIndex === -1 || newIndex === -1) return items;

      const reorderedItems = [...items];
      const [movedItem] = reorderedItems.splice(oldIndex, 1);
      reorderedItems.splice(newIndex, 0, movedItem);

      return reorderedItems;
    })();

    setValue(findPath as any, reorderedItems);
  };

  return (
    <div>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
        <Tooltip
          arrow
          describeChild
          open={open}
          placement="bottom-end"
          sx={{ p: 0, zIndex: 10 }}
          title={
            <form onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: 'white' }}>
              <Stack gap={2} sx={{ minWidth: 600, p: 2 }}>
                {groups.map((group, index) => (
                  <div key={group.id}>
                    <GroupForm control={control} groupIndex={index} subIndex={index} />
                    {index < groups.length - 1 && <Divider />}
                  </div>
                ))}
                <Button fullWidth type="submit" variant="solid">
                  Apply Filters
                </Button>
              </Stack>
            </form>
          }
          variant="soft"
        >
          <Button
            onClick={() => setOpen((prev) => !prev)}
            startDecorator={<FilterAlt />}
            sx={{ px: 2 }}
            variant="solid"
          >
            {open ? 'Close filters' : 'Open filters'}
          </Button>
        </Tooltip>
      </DndContext>
    </div>
  );
};

export { CandidatesFilterPopup };

import { SortableContext } from '@dnd-kit/sortable';
import { Add, DeleteOutlineRounded, LoopRounded } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/joy';
import { nanoid } from 'nanoid';
import { FC } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';

import ConditionRow from './ConditionRow';

interface ConditionField {
  id: string;
  field: string;
  operator: string;
  value: string;
}

const GroupForm: FC<{
  control: Control<any>;
  groupIndex: number;
  subIndex: number;
}> = ({ control, groupIndex, subIndex }) => {
  const groupPath = `groups.${groupIndex}` as const;
  const conditionsArray = useFieldArray({
    control,
    keyName: 'key',
    name: `${groupPath}.conditions`,
  });
  const subgroupsArray = useFieldArray({
    control,
    keyName: 'key',
    name: `${groupPath}.subgroups` as any,
  });
  return (
    <Stack
      gap={2}
      padding={1}
      sx={{
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Controller
        control={control}
        name={`${groupPath}.operator`}
        render={({ field: { onChange, value } }) => (
          <Button
            color="neutral"
            endDecorator={<LoopRounded />}
            onClick={() => onChange(value === 'and' ? 'or' : 'and')}
            sx={{ textTransform: 'uppercase', width: 'fit-content' }}
            variant="soft"
          >
            {value}
          </Button>
        )}
      />
      <SortableContext items={conditionsArray?.fields?.map((condition) => condition.key)}>
        {conditionsArray.fields.map((condition, index) => (
          <ConditionRow
            conditionId={(condition as unknown as ConditionField).id}
            control={control}
            groupPath={groupPath}
            index={index}
            key={(condition as unknown as ConditionField).id}
            onRemove={() => conditionsArray.remove(index)}
          />
        ))}
      </SortableContext>

      {subgroupsArray.fields.map((subgroup, subIndex) => (
        <Box key={subgroup.key} sx={{ borderLeft: '2px solid #ccc', pl: 3 }}>
          <GroupForm control={control} groupIndex={`${groupIndex}.subgroups.${subIndex}` as any} subIndex={subIndex} />
        </Box>
      ))}

      <Stack direction="row" gap={2}>
        <Button
          onClick={() =>
            conditionsArray.append({
              field: '',
              id: nanoid(),
              operator: 'equals',
              value: '',
            })
          }
          startDecorator={<Add />}
          variant="plain"
        >
          Add Condition
        </Button>
        <Button
          color="neutral"
          onClick={() =>
            subgroupsArray.append({
              conditions: [],
              id: nanoid(),
              operator: 'and',
              subgroups: [],
            })
          }
          startDecorator={<Add />}
          variant="plain"
        >
          Add Subgroup
        </Button>
        {subgroupsArray.fields.length > 0 && (
          <IconButton color="neutral" onClick={() => subgroupsArray.remove(subIndex)}>
            <DeleteOutlineRounded />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default GroupForm;

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRepository } from '../store/user.repository';
import { useUserStore } from '../store/users.store';

export const useAuthSync = (auth: any) => {
  const { firestore } = useFirebase();
  const userRepository = useMemo(() => new UserRepository(firestore), [firestore]);
  const navigate = useNavigate();
  const { setUser, clearUser } = useUserStore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser: any) => {
      if (firebaseUser) {
        const userData = await userRepository.getUserById(firebaseUser.uid);
        setUser(userData ? { ...firebaseUser, ...userData } : firebaseUser);
      } else {
        clearUser();
        navigate('/sign-in');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate, userRepository, setUser, clearUser]);
};

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Company } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useCompanyStore } from '../store/companies.store';
import { CompanyRepository } from '../store/company.repository';

export const useCompanies = () => {
  const { firestore } = useFirebase();
  const companyRepository = new CompanyRepository(firestore);
  const queryClient = useQueryClient();
  const { companies, setCompanies, addCompany, updateCompany, removeCompany } = useCompanyStore();

  const { data, isLoading, error } = useQuery({
    queryKey: ['companies'],
    queryFn: () => companyRepository.getAll(),
  });

  useEffect(() => {
    if (data) {
      setCompanies(data);
    }
  }, [data, setCompanies]);

  const useCompany = (id: string) => {
    const setCurrentCompany = useCompanyStore((state) => state.setCurrentCompany);

    const query = useQuery({
      queryKey: ['companies', id],
      queryFn: () => companyRepository.getById(id),
      enabled: !!id,
    });

    useEffect(() => {
      if (query.data) {
        setCurrentCompany(query.data);
      }
    }, [query.data, setCurrentCompany]);

    return query;
  };

  const createCompanyMutation = useMutation({
    mutationFn: (newCompany: Omit<Company, 'id'>) => companyRepository.create(newCompany),
    onSuccess: (company) => {
      addCompany(company);
      queryClient.invalidateQueries({ queryKey: ['companies'] });
    },
  });

  const updateCompanyMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Company> }) => companyRepository.update(id, data),
    onSuccess: (_, variables) => {
      updateCompany(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['companies'] });
    },
  });

  const deleteCompanyMutation = useMutation({
    mutationFn: (id: string) => companyRepository.delete(id),
    onSuccess: (_, id) => {
      removeCompany(id);
      queryClient.invalidateQueries({ queryKey: ['companies'] });
    },
  });

  return {
    companies,
    isLoading,
    error,
    useCompany,
    createCompany: createCompanyMutation.mutate,
    updateCompany: updateCompanyMutation.mutate,
    deleteCompany: deleteCompanyMutation.mutate,
  };
};

import { Candidate } from '@bluebird-monorepo/types';
import { getNestedFieldValue } from '@bluebird-monorepo/utils';
import { useMemo } from 'react';
import type { CandidateFiltersGroup, CandidateFiltersGroupCondition } from '../store/candidates.store';
import { useCandidateStore } from '../store/candidates.store';

export const useFilteredAndSortedCandidates = (candidates: Candidate[], orderBy: string, order: 'asc' | 'desc') => {
  const { filters, search } = useCandidateStore();

  const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return (a: Candidate, b: Candidate) => {
      let aValue: any;
      let bValue: any;

      // Special handling for name sorting
      if (orderBy === 'firstName') {
        aValue = `${a.firstName || ''} ${a.lastName || ''}`.trim().toLowerCase();
        bValue = `${b.firstName || ''} ${b.lastName || ''}`.trim().toLowerCase();
      } else {
        aValue = getNestedFieldValue(a, orderBy);
        bValue = getNestedFieldValue(b, orderBy);

        // Convert to numbers for numeric fields
        if (typeof aValue === 'number' || typeof bValue === 'number') {
          aValue = Number(aValue) || 0;
          bValue = Number(bValue) || 0;
        }
      }

      // Handle undefined/null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;
      if (aValue === bValue) return 0;

      // Compare values based on order
      if (order === 'desc') {
        return bValue < aValue ? -1 : 1;
      }
      return aValue < bValue ? -1 : 1;
    };
  };

  return useMemo(() => {
    let filteredCandidates = candidates;

    if (filters?.length) {
      filteredCandidates = candidates.filter((candidate) => filters.every((group) => evaluateGroup(candidate, group)));
    }

    if (search) {
      filteredCandidates = filteredCandidates.filter((candidate) =>
        `${candidate.firstName || ''} ${candidate.lastName || ''}`.toLowerCase().includes(search.toLowerCase()),
      );
    }

    // Apply sorting
    const comparator = getComparator(order, orderBy);
    return [...filteredCandidates].sort(comparator);
  }, [candidates, filters, search, order, orderBy]);

  function evaluateGroup(candidate: Candidate, group: CandidateFiltersGroup): boolean {
    const evaluateCondition = (condition: CandidateFiltersGroupCondition) => {
      const { field, operator, value } = condition;
      const candidateValue = getNestedFieldValue(candidate, field);
      if (candidateValue === undefined || value === undefined) return false;

      const getConvertedValue = (field: string, value: string) => {
        const numberFields = [
          'salaryInformation.currentSalary.yearly',
          'salaryInformation.currentSalary.monthly',
          'workExperienceYears',
          'score',
        ];
        if (numberFields.includes(field)) {
          return Number(value);
        }

        const booleanFields = ['requiresVisa'];
        if (booleanFields.includes(field)) {
          return value === 'true';
        }

        return value;
      };
      const convertedValue = getConvertedValue(field, value as string);

      switch (operator) {
        case 'contains':
          return typeof candidateValue === 'string' && candidateValue.includes(convertedValue as string);
        case 'equals':
          return candidateValue === convertedValue;
        case 'greaterThan':
          return Number(candidateValue) > Number(convertedValue);
        case 'lessThan':
          return Number(candidateValue) < Number(convertedValue);
        case 'startsWith':
          return typeof candidateValue === 'string' && candidateValue.startsWith(convertedValue as string);
        default:
          return false;
      }
    };

    const conditionsResult =
      group.operator === 'and' ? group.conditions.every(evaluateCondition) : group.conditions.some(evaluateCondition);

    const subgroupsResult =
      group.operator === 'and'
        ? group.subgroups.every((subgroup) => evaluateGroup(candidate, subgroup))
        : group.subgroups.some((subgroup) => evaluateGroup(candidate, subgroup));

    return group.operator === 'and' ? conditionsResult && subgroupsResult : conditionsResult || subgroupsResult;
  }
};

import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { Company } from '@bluebird-monorepo/types';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/joy';
import React from 'react';
import { PersonCard } from '../../PersonCard';

interface PeopleTabProps {
  company: Company;
}

export const PeopleTab: React.FC<PeopleTabProps> = ({ company }) => {
  const hiringManagers = company.hiringManagers ?? [];
  const contactPersons = company.contactPersons ?? [];

  return (
    <BluebirdCard>
      <Box mb={4}>
        <Typography level="h3" mb={2}>
          Contacts & Hiring Managers
        </Typography>
        <Box mb={2} sx={{ display: 'flex', gap: 2 }}>
          <Button color="neutral" size="sm" variant="outlined" startDecorator={<Add />}>
            Add Contact Person
          </Button>
          <Button color="neutral" size="sm" variant="outlined" startDecorator={<Add />}>
            Add Hiring Manager
          </Button>
        </Box>
        <Stack direction="row" gap={2}>
          {contactPersons.map((contact) => (
            <PersonCard
              key={contact.id}
              person={{
                ...contact,
                isContactPerson: true,
                isHiringManager: hiringManagers.some((hm) => hm.id === contact.id),
                responsibleForJobs: hiringManagers.find((hm) => hm.id === contact.id)?.responsibleForJobs,
              }}
            />
          ))}

          {hiringManagers.map((manager) => (
            <React.Fragment key={manager.id}>
              {!contactPersons.some((cp) => cp.id === manager.id) && (
                <PersonCard
                  person={{
                    ...manager,
                    isHiringManager: true,
                    responsibleForJobs: manager.responsibleForJobs,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Stack>
      </Box>
    </BluebirdCard>
  );
};

import { FormControl, FormHelperText, FormLabel, Input, Option, Select, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

const STATUS_OPTIONS = [
  {
    value: 'client',
    label: 'Client',
    description: 'Active business relationship with signed agreements',
  },
  {
    value: 'prospect',
    label: 'Prospect',
    description: 'Potential client in discussion phase',
  },
  {
    value: 'partner',
    label: 'Partner',
    description: 'Strategic business partner or affiliate',
  },
];

export function AboutStep() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const status = watch('status');

  return (
    <Stack spacing={3}>
      <Typography level="h4">About the Company</Typography>

      <FormControl error={!!errors.name} required>
        <FormLabel>Company Name</FormLabel>
        <Input {...register('name')} placeholder="Enter company name" />
        {errors.name && <FormHelperText>{errors.name.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.industry}>
        <FormLabel>Industry (Optional)</FormLabel>
        <Input {...register('industry')} placeholder="e.g., Technology, Healthcare, Finance" />
        {errors.industry && <FormHelperText>{errors.industry.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.location}>
        <FormLabel>Location (Optional)</FormLabel>
        <Input {...register('location')} placeholder="Primary business location" />
        {errors.location && <FormHelperText>{errors.location.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.website}>
        <FormLabel>Website (Optional)</FormLabel>
        <Input {...register('website')} placeholder="e.g., company.com" />
        {errors.website && <FormHelperText>{errors.website.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.status}>
        <FormLabel>Company Status</FormLabel>
        <Select value={status} onChange={(_, value) => setValue('status', value)} placeholder="Select company status">
          {STATUS_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              <Stack>
                <Typography>{option.label}</Typography>
                <Typography level="body-xs">{option.description}</Typography>
              </Stack>
            </Option>
          ))}
        </Select>
        {errors.status && <FormHelperText>{errors.status.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.agreedFee}>
        <FormLabel>Agreed Fee Percentage (Optional)</FormLabel>
        <Input
          type="number"
          {...register('agreedFee', { valueAsNumber: true })}
          placeholder="Enter fee percentage"
          endDecorator="%"
        />
        {errors.agreedFee && <FormHelperText>{errors.agreedFee.message as string}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

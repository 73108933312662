import Delete from '@mui/icons-material/Delete';
import Upload from '@mui/icons-material/Upload';
import { Box, Button, Typography } from '@mui/joy';
import { ChangeEvent, FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileUploadProps {
  onSave: (files: File[]) => Promise<void>;
}

const FileUpload: FC<FileUploadProps> = ({ onSave }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    setFiles((prevFiles) => {
      const uniqueNewFiles = newFiles.filter(
        (newFile) => !prevFiles.some((file) => file.name === newFile.name)
      );
      return [...prevFiles, ...uniqueNewFiles].slice(0, 5);
    });
  };

  const handleFileRemove = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSave = async () => {
    await onSave(files);
    setFiles([]);
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: true,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => {
        const uniqueNewFiles = acceptedFiles.filter(
          (newFile) => !prevFiles.some((file) => file.name === newFile.name)
        );
        return [...prevFiles, ...uniqueNewFiles].slice(0, 5);
      });
    },
    onDropAccepted: () => setIsDragActive(false),
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box
        sx={{
          '&:hover': { borderColor: 'primary.solidBg' },
          backgroundColor: isDragActive ? 'neutral.200' : 'neutral.softBg',
          border: '2px dashed',
          borderColor: isDragActive
            ? 'primary.solidBg'
            : 'neutral.outlinedBorder',
          borderRadius: 'md',
          boxShadow: isDragActive ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none',
          padding: 2,
          textAlign: 'center',
          transition: 'all 0.3s ease',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} hidden />
        <Typography level="body-lg" mb={1}>
          {isDragActive
            ? 'Release to drop files'
            : 'Drag and drop files here, or click to select'}
        </Typography>
        <Button
          component="label"
          startDecorator={<Upload />}
          variant="outlined"
        >
          Upload Files
          <input hidden multiple onChange={handleFileChange} type="file" />
        </Button>
      </Box>

      {files.length > 0 && (
        <>
          <Box>
            <Typography level="h4" mb={1}>
              Uploaded Files:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {files.map((file) => (
                <Box
                  key={file.name}
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'neutral.200',
                    borderRadius: 15,
                    display: 'flex',
                    gap: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <Box>{file.name}</Box>
                  <Delete onClick={() => handleFileRemove(file.name)} />
                </Box>
              ))}
            </Box>
          </Box>
          <Button
            color="primary"
            onClick={handleSave}
            sx={{ alignSelf: 'flex-start' }}
            variant="solid"
          >
            Save Files
          </Button>
        </>
      )}
    </Box>
  );
};

export { FileUpload };

import { RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { Job } from '@bluebird-monorepo/types';
import { Box, Chip, Divider, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function ReviewStep() {
  const { watch } = useFormContext<Job>();
  const values = watch();
  const defaultStyle = { whiteSpace: 'pre-wrap' };

  const Section = ({ title, children }: { title: string; children: React.ReactNode }) => (
    <Box sx={{ mb: 3 }}>
      <Typography level="title-md" sx={{ mb: 1 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );

  return (
    <Stack spacing={2}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Review Job Details
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Please review all information before submitting</Box>
        </Box>
      </Box>

      <Section title="Basic Information">
        <Typography level="body-md">Title: {values.jobTitle}</Typography>
        <Typography level="body-md">Location: {values.location}</Typography>
        <Typography level="body-md">Contract Type: {values.contractType}</Typography>
      </Section>

      <Divider />

      <Section title="Salary & Requirements">
        <Typography level="body-md">
          Salary Range: €{values.salaryRange?.min} - €{values.salaryRange?.max}
        </Typography>
        <Typography level="body-md">Fee: {values.feePercentage}%</Typography>
        {values.preferredLanguages && values.preferredLanguages.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Typography level="body-md">Preferred Languages:</Typography>
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
              {values.preferredLanguages.map((lang) => (
                <Chip key={lang} size="sm">
                  {lang}
                </Chip>
              ))}
            </Box>
          </Box>
        )}
      </Section>

      <Divider />

      <Section title="Requirements & Responsibilities">
        <Typography level="body-md" sx={{ mb: 1 }}>
          Must-Have Requirements:
        </Typography>
        <RawHTML children={values.mustHaves} style={defaultStyle} />

        {values.niceToHaves && values.niceToHaves.length > 0 && (
          <>
            <Typography level="body-md" sx={{ mb: 1, mt: 2 }}>
              Nice-to-Have Requirements:
            </Typography>
            <RawHTML children={values.niceToHaves} style={defaultStyle} />
          </>
        )}

        <Typography level="body-md" sx={{ mb: 1, mt: 2 }}>
          Key Responsibilities:
        </Typography>
        <RawHTML children={values.keyResponsibilities} style={defaultStyle} />
      </Section>

      <Divider />

      <Section title="Hiring Process">
        <Typography level="body-md" sx={{ whiteSpace: 'pre-wrap' }}>
          <RawHTML children={values.hiringProcess} style={defaultStyle} />
        </Typography>
      </Section>
      <Section title="About the Company">
        <RawHTML children={values.productDescription} style={defaultStyle} />
        <RawHTML children={values.uniqueAboutCompany} style={defaultStyle} />
      </Section>

      <Divider />

      <Section title="Role Description">
        <RawHTML children={values.roleDescription} style={defaultStyle} />
      </Section>
    </Stack>
  );
}

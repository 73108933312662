import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore } from 'firebase/firestore';

export interface ResponsibleDocument {
  id: string;
  name: string;
}

export class ResponsiblesRepository extends BaseRepository<ResponsibleDocument> {
  constructor(db: Firestore) {
    super(db, 'responsibles');
  }
}

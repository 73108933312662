import { BluebirdDrawer } from '@bluebird-monorepo/bluebird-ui';
import { useJobs } from '@bluebird-monorepo/jobs';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Stack,
  Switch,
  Typography,
} from '@mui/joy';
import { useStages } from '../hooks/useStages';
import { useKanbanSettings } from '../store/useKanbanSettings';

interface Props {
  activeJobIds: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function KanbanSettingsDrawer({ activeJobIds, open, setOpen }: Props) {
  const { stages } = useStages(); // We're already using this

  const { jobs } = useJobs();
  const {
    selectedJobIds,
    hiddenStageIds,
    stageOrder,
    setSelectedJobs,
    toggleStageVisibility,
    updateStageOrder,
    resetSettings,
  } = useKanbanSettings();

  const activeJobs = jobs?.filter((job) => activeJobIds.includes(job.id)) || [];

  const handleJobToggle = (jobId: string) => {
    if (selectedJobIds.includes(jobId)) {
      setSelectedJobs(selectedJobIds.filter((id) => id !== jobId));
    } else {
      setSelectedJobs([...selectedJobIds, jobId]);
    }
  };

  const handleStageReorder = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(stageOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateStageOrder(items);
  };

  return (
    <BluebirdDrawer open={open} onClose={() => setOpen(false)} title="Kanban Board Settings">
        <Stack spacing={2} sx={{ p: 2 }}>
          {/* Jobs Section */}
          <Accordion defaultExpanded={false}>
            <AccordionSummary indicator={<ExpandMore />}>
              <Typography level="h4">
                Jobs ({selectedJobIds.length}/{activeJobs.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {activeJobs.map((job) => (
                  <ListItem key={job.id}>
                    <ListItemButton onClick={() => handleJobToggle(job.id)}>
                      <Checkbox checked={selectedJobIds.includes(job.id)} />
                      <ListItemContent>
                        <Typography>{job.jobTitle}</Typography>
                        <Typography level="body-sm">{job.companyName}</Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ))}
                {activeJobs.length === 0 && (
                  <ListItem>
                    <Typography level="body-sm" sx={{ fontStyle: 'italic' }}>
                      No active jobs found
                    </Typography>
                  </ListItem>
                )}
              </List>
            </AccordionDetails>
          </Accordion>

          <Divider />

          {/* Columns Section */}
          <Accordion defaultExpanded={false}>
            <AccordionSummary indicator={<ExpandMore />}>
              <Typography level="h4">
                Columns ({stages.length - hiddenStageIds.length}/{stages.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DragDropContext onDragEnd={handleStageReorder}>
                <Droppable droppableId="stages">
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {stages.map((stage, index) => (
                        <Draggable key={stage.id} draggableId={stage?.id?.toString()} index={index}>
                          {(provided) => (
                            <ListItem
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <Switch
                                checked={!hiddenStageIds.includes(stage.id)}
                                onChange={() => toggleStageVisibility(stage.id)}
                              />
                              <ListItemContent>
                                <Typography>{stage.name}</Typography>
                              </ListItemContent>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </AccordionDetails>
          </Accordion>

          <Divider />

          {/* Reset Button */}
          <Button onClick={resetSettings} color="danger" variant="soft" fullWidth>
            Reset to Default
          </Button>
        </Stack>
      </BluebirdDrawer>
  );
}

import './RichTextEditor.scss';

import Link from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect } from 'react';

import MenuBar from './MenuBar';

const extensions = [
  StarterKit,
  Link.configure({
    autolink: true,
    defaultProtocol: 'https',
    openOnClick: false,
  }),
];

const defaultContent = `
`;

type RichTextEditorProps = {
  content?: string;
  CustomMenuBar?: any;
  disabled?: boolean;
  onChange: (content: string) => void;
  showMenuBar?: boolean;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  content = defaultContent,
  CustomMenuBar,
  disabled = false,
  onChange,
  showMenuBar = true,
}) => {
  const editor = useEditor({
    content,
    editable: !disabled,
    extensions,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html); // Sync content changes to parent
    },
  });

  // Update editor content when `content` prop changes
  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content || defaultContent); // Safely update content
    }
  }, [content, editor]);

  // Update editor editability when `disabled` prop changes
  useEffect(() => {
    editor?.setEditable(!disabled);
  }, [disabled, editor]);

  return (
    <>
      {showMenuBar && (
        <div className="menu-bar">
          {CustomMenuBar ? (
            <CustomMenuBar disabled={disabled} editor={editor} />
          ) : (
            <MenuBar disabled={disabled} editor={editor} />
          )}
        </div>
      )}
      <EditorContent editor={editor} style={{ cursor: 'pointer' }} />
    </>
  );
};

export default RichTextEditor;

import { FirebaseProvider } from '@bluebird-monorepo/bluebird-firebase';
import { StyledEngineProvider } from '@mui/joy';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/App';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GCLIENT_ID || ''}>
      <StyledEngineProvider injectFirst>
        <FirebaseProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </FirebaseProvider>
      </StyledEngineProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface KanbanSettings {
  selectedJobIds: string[];
  hiddenStageIds: string[];
  stageOrder: string[];
}

interface KanbanSettingsState extends KanbanSettings {
  setSelectedJobs: (jobIds: string[]) => void;
  toggleStageVisibility: (stageId: string) => void;
  updateStageOrder: (stageIds: string[]) => void;
  resetSettings: () => void;
}

const defaultSettings: KanbanSettings = {
  selectedJobIds: [],
  hiddenStageIds: [],
  stageOrder: [],
};

export const useKanbanSettings = create<KanbanSettingsState>()(
  persist(
    (set) => ({
      ...defaultSettings,

      setSelectedJobs: (jobIds) =>
        set(() => ({
          selectedJobIds: jobIds,
        })),

      toggleStageVisibility: (stageId) =>
        set((state) => ({
          hiddenStageIds: state.hiddenStageIds.includes(stageId)
            ? state.hiddenStageIds.filter((id) => id !== stageId)
            : [...state.hiddenStageIds, stageId],
        })),

      updateStageOrder: (stageIds) =>
        set(() => ({
          stageOrder: stageIds,
        })),

      resetSettings: () =>
        set(() => ({
          ...defaultSettings,
        })),
    }),
    {
      name: 'kanban-settings',
    },
  ),
);

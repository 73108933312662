import { CandidatesFilterPopup, CandidateTable } from '@bluebird-monorepo/candidates';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Input, Stack } from '@mui/joy';
import React, { useState } from 'react';
import { Layout } from '../../../layout';

const CandidatesPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Layout title="Candidates">
      <Box
        sx={{
          alignItems: { sm: 'center', xs: 'start' },
          display: 'flex',
          flexDirection: { sm: 'row', xs: 'column' },
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <Stack width="100%" direction="row" justifyContent="space-between" gap={2}>
          <Input
            size="sm"
            placeholder="Search candidates..."
            startDecorator={<SearchRoundedIcon />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: 400 }}
          />
          <CandidatesFilterPopup />
        </Stack>
      </Box>
      <CandidateTable searchQuery={searchQuery} />
    </Layout>
  );
};

export default CandidatesPage;

import { useJobs } from '@bluebird-monorepo/jobs';
import { WorkOutlined } from '@mui/icons-material';
import { Button, Divider, Link, ListItem, Typography } from '@mui/joy';
import { FC } from 'react';

interface CandidateJobProps {
  handleAuditLog: (jobId: string) => void;
  id: string;
}

export const CandidateJob: FC<CandidateJobProps> = ({ handleAuditLog, id }) => {
  const { useJob } = useJobs();
  const { data: job, isLoading } = useJob(id);

  if (isLoading || !job?.jobTitle || !job?.companyName) {
    return null;
  }

  return (
    <>
      <ListItem sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
        <WorkOutlined sx={{ color: 'primary.main' }} />
        <div>
          <Link
            href={`/jobs/${id}`}
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            {job.jobTitle}
          </Link>
          <Typography level="body-sm">{job.companyName}</Typography>
        </div>
        <Button
          color="primary"
          onClick={() => handleAuditLog(id)}
          sx={{ marginLeft: 'auto' }}
          variant="plain"
        >
          View Audit Log
        </Button>
      </ListItem>
      <Divider component="li" sx={{ my: 1 }} />
    </>
  );
};

import { Candidate } from '@bluebird-monorepo/types';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/joy';
import { FC } from 'react';

interface CandidatesTableHeaderProps {
  onSort: (columnId: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

const CandidatesTableHeader: FC<CandidatesTableHeaderProps> = ({ onSort, order, orderBy }) => {
  const renderOrderIcon = (column: keyof Candidate) => {
    return orderBy === column ? (
      order === 'asc' ? (
        <ArrowDropUpRounded color="primary" sx={{ height: 30, width: 30 }} />
      ) : (
        <ArrowDropDownRounded color="primary" sx={{ height: 30, width: 30 }} />
      )
    ) : null;
  };

  const columns = [
    { id: 'firstName', label: 'Name', width: '20%' },
    { id: 'currentPosition', label: 'Position', width: '20%' },
    { id: 'currentOrganization', label: 'Company', width: '15%' },
    { id: 'city', label: 'Location', width: '15%' },
    { id: 'score', label: 'Score', width: '10%' },
    { id: 'status', label: 'Status', width: '10%' },
    { id: 'actions', label: 'Actions', width: '10%', sortable: false },
  ];

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            key={column.id}
            onClick={() => column.sortable !== false && onSort(column.id)}
            style={{
              cursor: column.sortable !== false ? 'pointer' : 'default',
              padding: '12px 16px',
              verticalAlign: 'middle',
              width: column.width,
              textAlign: column.id === 'actions' ? 'center' : 'left',
            }}
          >
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              justifyContent={column.id === 'actions' ? 'center' : 'flex-start'}
            >
              <Typography level="body-sm" textColor="text.primary" fontWeight="lg">
                {column.label}
              </Typography>
              {column.sortable !== false && renderOrderIcon(column.id as keyof Candidate)}
            </Stack>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default CandidatesTableHeader;

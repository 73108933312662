import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Job } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { JobsRepository } from '../store/job.repository';
import { useJobsStore } from '../store/jobs.store';
import { ResponsiblesRepository } from '../store/responsible.repository';
import { StatusRepository } from '../store/status.repository';

export const useJobs = () => {
  const { firestore } = useFirebase();
  if (!firestore) throw new Error('Firestore not initialized');

  const repositories = useMemo(
    () => ({
      jobsRepository: new JobsRepository(firestore),
      responsiblesRepository: new ResponsiblesRepository(firestore),
      statusRepository: new StatusRepository(firestore),
    }),
    [firestore],
  );

  const queryClient = useQueryClient();
  const {
    jobs,
    setJobs,
    addJob,
    updateJob,
    removeJob,
    setResponsibles,
    setStatuses,
    responsibles,
    statuses,
    currentJob,
  } = useJobsStore();

  const { data, isLoading, error } = useQuery({
    queryKey: ['jobs'],
    queryFn: () => repositories.jobsRepository.getAll(),
  });

  const useJob = (id: string) => {
    const setCurrentJob = useJobsStore((state) => state.setCurrentJob);

    const query = useQuery({
      queryKey: ['jobs', id],
      queryFn: () => repositories.jobsRepository.getById(id),
      enabled: !!id,
    });

    useEffect(() => {
      if (query.data) {
        setCurrentJob(query.data);
      }
    }, [query.data, setCurrentJob]);

    return query;
  };

  // Use useEffect to update the store when data changes
  React.useEffect(() => {
    if (data) {
      setJobs(data);
    }
  }, [data, setJobs]);

  const { data: responsiblesData } = useQuery({
    queryKey: ['responsibles'],
    queryFn: () => repositories.responsiblesRepository.getAll(),
  });

  React.useEffect(() => {
    if (responsiblesData) {
      setResponsibles(responsiblesData);
    }
  }, [responsiblesData, setResponsibles]);

  const { data: statusesData } = useQuery({
    queryKey: ['statuses'],
    queryFn: () => repositories.statusRepository.getAll(),
  });

  React.useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData);
    }
  }, [statusesData, setStatuses]);

  const createJobMutation = useMutation({
    mutationFn: (newJob: Omit<Job, 'id'>) => repositories.jobsRepository.create(newJob),
    onMutate: (newJob) => {
      console.log('Creating job with data:', newJob);
    },
    onSuccess: (job) => {
      addJob(job);
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      console.log('Job created successfully:', job);
    },
    onError: (error) => {
      console.error('Error creating job:', error);
    },
  });

  const upsertJob = () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: repositories.jobsRepository.create,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['jobs'] });
      },
    });
  };

  const updateJobMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Job> }) => repositories.jobsRepository.update(id, data),
    onMutate: (updatedJob) => {
      console.log('Updating job with data:', updatedJob);
    },
    onSuccess: (_, variables) => {
      updateJob(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      console.log('Job updated successfully:', variables.data);
    },
    onError: (error) => {
      console.error('Error updating job:', error);
    },
  });

  const deleteJobMutation = useMutation({
    mutationFn: (id: string) => repositories.jobsRepository.delete(id),
    onSuccess: (_, id) => {
      removeJob(id);
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
  });

  return {
    jobs,
    currentJob,
    responsibles,
    statuses,
    isLoading,
    error,
    useJob,
    upsertJob,
    createJobMutation,
    updateJobMutation,
    createJob: createJobMutation.mutate,
    updateJob: updateJobMutation.mutate,
    deleteJob: deleteJobMutation.mutate,
  };
};

import { Droppable } from '@hello-pangea/dnd';
import { Sheet, Table } from '@mui/joy';
import React from 'react';

interface TableLayoutProps {
  droppableId?: string; // Optional droppable ID for drag-and-drop context
  header: React.ReactNode;
  isDroppable?: boolean; // Whether to enable drag-and-drop
  providedDroppableProps?: any; // Droppable props (for drag-and-drop)
  providedDroppableRef?: any; // Droppable ref (for drag-and-drop)
  rows: React.ReactNode;
  tableProps?: any; // Extra props for the Table component (optional)
}

export function TableLayout({
  droppableId,
  header,
  isDroppable,
  providedDroppableProps,
  providedDroppableRef,
  rows,
  tableProps,
}: TableLayoutProps) {
  const tableContent = (
    <Table
      aria-labelledby="tableTitle"
      borderAxis="bothBetween"
      hoverRow
      stickyHeader
      sx={{
        '--Table-headerUnderlineThickness': '1px',
        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
        '--TableCell-paddingX': '8px',
        '--TableCell-paddingY': '4px',
        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
        marginBottom: '2rem',
      }}
      {...tableProps}
    >
      {header}
      <tbody>{rows}</tbody>
    </Table>
  );

  return (
    <Sheet
      sx={{
        borderRadius: 'sm',
        display: { sm: 'initial', xs: 'none' },
        flexShrink: 1,
        minHeight: 0,
        overflow: 'auto',
        width: '100%',
      }}
    >
      {isDroppable ? (
        <Droppable droppableId={droppableId?.toString() || ''}>
          {(provided) => (
            <div {...providedDroppableProps} ref={providedDroppableRef || provided.innerRef}>
              {tableContent}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        tableContent
      )}
    </Sheet>
  );
}

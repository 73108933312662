import { CreateJobWizard, JobsTable, useJobs } from '@bluebird-monorepo/jobs';
import React, { useState } from 'react';
import { Layout } from '../../../layout/Layout';

const JobsPage: React.FC = () => {
  const { jobs } = useJobs();
  const [isCreatingJob, setIsCreatingJob] = useState(false);
  const [filteredCount, setFilteredCount] = useState(jobs?.length || 0);

  const handleStartCreateJob = () => {
    setIsCreatingJob(true);
  };

  const handleCompleteCreateJob = () => {
    setIsCreatingJob(false);
  };

  const handleCancelCreateJob = () => {
    setIsCreatingJob(false);
  };

  const handleFilteredCountChange = (count: number) => {
    setFilteredCount(count);
  };

  return (
    <Layout
      title="Jobs"
      subtitle="Manage your jobs"
      actionButton={
        !isCreatingJob
          ? {
              label: 'Create new job',
              onClick: handleStartCreateJob,
              variant: 'create',
            }
          : undefined
      }
      count={filteredCount}
    >
      {isCreatingJob ? (
        <CreateJobWizard onComplete={handleCompleteCreateJob} onCancel={handleCancelCreateJob} />
      ) : (
        <JobsTable onFilteredCountChange={handleFilteredCountChange} />
      )}
    </Layout>
  );
};

export default JobsPage;

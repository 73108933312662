export const countries = [
  {
    code: 'GB',
    country: 'United Kingdom',
    languages: ['en'],
  },
  {
    code: 'FR',
    country: 'France',
    languages: ['fr'],
  },
  {
    code: 'DE',
    country: 'Germany',
    languages: ['de'],
  },
  {
    code: 'IT',
    country: 'Italy',
    languages: ['it'],
  },
  {
    code: 'ES',
    country: 'Spain',
    languages: ['es'],
  },
  {
    code: 'CN',
    country: 'China',
    languages: ['zh'],
  },

  {
    code: 'SA',
    country: 'Saudi Arabia',
    languages: ['ar'],
  },
  {
    code: 'SE',
    country: 'Sweden',
    languages: ['sv'],
  },
  {
    code: 'NO',
    country: 'Norway',
    languages: ['no'],
  },
  {
    code: 'FI',
    country: 'Finland',
    languages: ['fi'],
  },
  {
    code: 'DK',
    country: 'Denmark',
    languages: ['da'],
  },
  {
    code: 'NL',
    country: 'Netherlands',
    languages: ['nl'],
  },
  {
    code: 'TR',
    country: 'Turkey',
    languages: ['tr'],
  },
  {
    code: 'GR',
    country: 'Greece',
    languages: ['el'],
  },
  {
    code: 'PL',
    country: 'Poland',
    languages: ['pl'],
  },
];

// Define the type for the language map
type LanguageMap = {
  [key: string]: string; // or list specific languages if you know them
};

export const languageMap: LanguageMap = {
  af: 'Afrikaans',
  ar: 'Arabic',
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  en: 'English',
  es: 'Spanish',
  fi: 'Finnish',
  fil: 'Filipino',
  fr: 'French',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  id: 'Indonesian',
  ig: 'Igbo',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  ms: 'Malay',
  nl: 'Dutch',
  no: 'Norwegian',
  pl: 'Polish',
  pt: 'Portuguese',
  ru: 'Russian',
  st: 'Sotho',
  sv: 'Swedish',
  th: 'Thai',
  tr: 'Turkish',
  ur: 'Urdu',
  vi: 'Vietnamese',
  xh: 'Xhosa',
  yo: 'Yoruba',
  zh: 'Chinese',
  zu: 'Zulu',
};

export const languageCountryMap = countries.flatMap((country) =>
  country.languages.map((language) => ({
    countryCode: country.code,
    countryName: country.country,
    languageCode: language,
    languageName: languageMap[language as keyof typeof languageMap],
  })),
);

import { CreateJobWizard, JobDetails, useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout';

export type TEditableJobDetails = {
  companyId: string;
  companyName: string;
  jobTitle: string;
  location: string;
  salary: string;
  keyResponsibilities: string;
  niceToHaves: string;
  mustHaves: string;
  openSinceDate: string;
};

const JobDetailsPage = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { useJob, updateJobMutation } = useJobs();
  const [isEditing, setIsEditing] = useState(false);
  const [subTitle, setSubTitle] = useState('');

  const { data: job, isLoading } = useJob(jobId || '');

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleEditComplete = async (updatedJob: TEditableJobDetails) => {
    if (!job) return;

    await updateJobMutation.mutate({
      id: job.id,
      data: {
        ...job,
        ...updatedJob,
        salary: updatedJob.salary ? parseInt(updatedJob.salary, 10) : undefined,
      },
    });

    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const getInitialFormData = (job: Job): any => ({
    id: job.id,
    companyId: job.companyId,
    companyName: job.companyName,
    jobTitle: job.jobTitle,
    location: job.location,
    contractType: job.contractType || '',
    roleDescription: job.roleDescription || '',
    productDescription: job.productDescription || '',
    salaryRange: job.salaryRange || { min: 0, max: 0 },
    feePercentage: job.feePercentage || 0,
    preferredLanguages: job.preferredLanguages || [],
    techStack: job.techStack || [],
    mustHaves: job.mustHaves || '',
    niceToHaves: job.niceToHaves || '',
    keyResponsibilities: job.keyResponsibilities || '',
    hiringProcess: job.hiringProcess || '',
    howToIntro: job.howToIntro || '',
    openSinceDate: job.openSinceDate || '',
  });

  if (isLoading) {
    return <Typography level="h4">Loading...</Typography>;
  }

  if (!job) {
    return <Typography level="h4">Job not found</Typography>;
  }

  return (
    <Layout
      title={`${job.jobTitle} - ${subTitle}`}
      subtitle={job.companyName}
      actionButton={{
        label: 'Edit',
        onClick: handleStartEdit,
        variant: 'warning',
      }}
    >
      {isEditing ? (
        <CreateJobWizard
          initialData={getInitialFormData(job)}
          mode="edit"
          onComplete={handleEditComplete}
          onCancel={handleEditCancel}
        />
      ) : (
        <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: '1fr', mt: 2 }}>
          <Box sx={{ overflow: 'auto' }}>
            <JobDetails setSubTitle={setSubTitle} job={job} />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default JobDetailsPage;

import { Nullable } from '@bluebird-monorepo/types';
import { Button, ButtonGroup, Stack } from '@mui/joy';
import { Editor } from '@tiptap/react';
import React, { useCallback, useEffect } from 'react';

export type MenuBarProps = {
  disabled?: boolean;
  editor: Nullable<Editor>;
};
const MenuBar: React.FC<MenuBarProps> = ({ disabled, editor }) => {
  if (!editor || disabled) {
    return null;
  }
  const renderButton = (
    action: () => void,
    label: string,
    isActiveCheck = false,
    disabledCheck = false
  ) => (
    <Button
      color={isActiveCheck ? 'primary' : 'neutral'}
      disabled={disabledCheck || disabled}
      onClick={action}
      variant={isActiveCheck ? 'soft' : 'outlined'}
    >
      {label}
    </Button>
  );

  useEffect(() => {
    if (editor.isActive('bold')) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [editor]);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);
  return (
    <Stack direction="row" flexWrap="wrap" gap={1}>
      <ButtonGroup variant="outlined">
        {renderButton(
          () => editor.chain().focus().toggleBold().run(),
          'Bold',
          editor.isActive('bold'),
          !editor.can().chain().focus().toggleBold().run()
        )}
        {renderButton(
          () => editor.chain().focus().toggleItalic().run(),
          'Italic',
          editor.isActive('italic'),
          !editor.can().chain().focus().toggleItalic().run()
        )}
        {renderButton(
          () => editor.chain().focus().toggleStrike().run(),
          'Strike',
          editor.isActive('strike'),
          !editor.can().chain().focus().toggleStrike().run()
        )}
        {renderButton(
          () => editor.chain().focus().toggleCode().run(),
          'Code',
          editor.isActive('code'),
          !editor.can().chain().focus().toggleCode().run()
        )}
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        {renderButton(setLink, 'Link', editor.isActive('link'))}
        {renderButton(
          () => editor.chain().focus().unsetLink().run(),
          'Unset link',
          false,
          !editor.isActive('link')
        )}
        {renderButton(
          () => editor.chain().focus().unsetAllMarks().run(),
          'Clear marks'
        )}
        {renderButton(
          () => editor.chain().focus().clearNodes().run(),
          'Clear nodes'
        )}
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        {renderButton(
          () => editor.chain().focus().setParagraph().run(),
          'Paragraph',
          editor.isActive('paragraph')
        )}

        {renderButton(
          () =>
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 1 as any })
              .run(),
          `H1`,
          editor.isActive('heading', { level: 1 })
        )}
        {renderButton(
          () =>
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 2 as any })
              .run(),
          `H2`,
          editor.isActive('heading', { level: 2 })
        )}
        {renderButton(
          () =>
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 3 as any })
              .run(),
          `H3`,
          editor.isActive('heading', { level: 3 })
        )}
        {renderButton(
          () =>
            editor
              .chain()
              .focus()
              .toggleHeading({ level: 4 as any })
              .run(),
          `H4`,
          editor.isActive('heading', { level: 4 })
        )}
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        {renderButton(
          () => editor.chain().focus().toggleBulletList().run(),
          'Bullet list',
          editor.isActive('bulletList')
        )}
        {renderButton(
          () => editor.chain().focus().toggleOrderedList().run(),
          'Ordered list',
          editor.isActive('orderedList')
        )}
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        {renderButton(
          () => editor.chain().focus().toggleCodeBlock().run(),
          'Code block',
          editor.isActive('codeBlock')
        )}
        {renderButton(
          () => editor.chain().focus().toggleBlockquote().run(),
          'Blockquote',
          editor.isActive('blockquote')
        )}
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        {renderButton(
          () => editor.chain().focus().setHorizontalRule().run(),
          'Horizontal rule'
        )}
        {renderButton(
          () => editor.chain().focus().setHardBreak().run(),
          'Hard break'
        )}
      </ButtonGroup>

      <ButtonGroup variant="outlined">
        {renderButton(
          () => editor.chain().focus().undo().run(),
          'Undo',
          false,
          !editor.can().chain().focus().undo().run()
        )}
        {renderButton(
          () => editor.chain().focus().redo().run(),
          'Redo',
          false,
          !editor.can().chain().focus().redo().run()
        )}
      </ButtonGroup>
    </Stack>
  );
};

export default MenuBar;

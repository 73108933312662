import { useJobs } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close, ErrorOutline } from '@mui/icons-material';
import { Alert, Box, Button, IconButton, Stack, Step, StepIndicator, Stepper } from '@mui/joy';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { AboutStep } from './steps/AboutStep';
import { DescriptionsStep } from './steps/DescriptionsStep';
import { DetailsStep } from './steps/DetailsStep';
import { RequirementsStep } from './steps/RequirementsStep';
import { ReviewStep } from './steps/ReviewStep';

// Validation schema
const jobSchema = z.object({
  companyId: z.string().min(1, 'Company is required'),
  companyName: z.string().min(1, 'Company name is required'),
  jobTitle: z.string().min(1, 'Job title is required'),
  location: z.string().min(1, 'Location is required'),
  contractType: z.string().min(1, 'Contract type is required'),
  roleDescription: z.string().optional(),
  productDescription: z.string().optional(),
  uniqueAboutCompany: z.string().optional(),
  salaryRange: z
    .object({
      min: z.number().min(1, 'Minimum salary is required'),
      max: z.number().min(1, 'Maximum salary is required'),
    })
    .optional(),
  feePercentage: z.number().min(1, 'Fee percentage is required'),
  preferredLanguages: z.array(z.string()).optional(),
  techStack: z.array(z.string()).optional(),
  mustHaves: z.string().optional(),
  niceToHaves: z.string().optional(),
  keyResponsibilities: z.string().optional(),
  hiringProcess: z.string().min(1, 'Hiring process is required'),
  howToIntro: z.string().min(1, 'Introduction guidance is required'),
});

const steps = [
  {
    label: 'About',
    description: 'Basic job information',
    component: AboutStep,
  },
  {
    label: 'Details',
    description: 'Salary and requirements',
    component: DetailsStep,
  },
  {
    label: 'Descriptions',
    component: DescriptionsStep,
    description: 'Description about the role and product',
  },
  {
    label: 'Requirements',
    description: 'Key responsibilities and process',
    component: RequirementsStep,
  },
  {
    label: 'Review',
    description: 'Review and submit',
    component: ReviewStep,
  },
];

type JobFormData = Omit<Job, 'id'>;

interface CreateJobWizardProps {
  initialData?: any;
  mode?: 'create' | 'edit';
  onComplete: (data: any) => void;
  onCancel: () => void;
}

export function CreateJobWizard({ onComplete, onCancel, initialData, mode = 'create' }: CreateJobWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm<JobFormData>({
    resolver: zodResolver(jobSchema),
    mode: 'onChange',
    defaultValues: initialData, // Set initial values if provided
  });
  const { createJobMutation, updateJobMutation } = useJobs();

  const stepFields = {
    0: ['companyId', 'companyName', 'jobTitle', 'location', 'contractType'],
    1: ['salaryRange', 'feePercentage', 'preferredLanguages', 'techStack'],
    2: ['roleDescription', 'productDescription', 'uniqueAboutCompany'],
    3: ['mustHaves', 'niceToHaves', 'keyResponsibilities', 'hiringProcess', 'howToIntro'],
  } as const;

  const {
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = methods;

  const onSubmit = async (data: JobFormData) => {
    try {
      if (mode === 'edit' && initialData?.id) {
        await updateJobMutation.mutateAsync({
          id: initialData.id,
          data,
        });
      } else {
        await createJobMutation.mutateAsync(data);
      }
      onComplete(data);
    } catch (error) {
      console.error('Failed to save job:', error);
    }
  };

  const isStepValid = (step: number) => {
    const currentStepFields = stepFields[step as keyof typeof stepFields];
    return !currentStepFields.some(field => {
      const error = errors[field as keyof JobFormData];
      return error !== undefined;
    });
  };

  const handleNext = async () => {
    const currentStepFields = stepFields[activeStep as keyof typeof stepFields];
    const stepValid = await trigger(currentStepFields);

    if (stepValid) {
      setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const renderStepContent = (step: number) => {
    const StepComponent = steps[step].component;
    return <StepComponent />;
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: 4,
          p: 3,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary',
            },
          }}
        >
          <Close />
        </IconButton>
        {/* Left side - Stepper */}
        <Box
          sx={{
            width: '280px',
            borderRight: '1px solid',
            borderColor: 'divider',
            pr: 3,
          }}
        >
          <Stepper
            component="nav"
            orientation="vertical"
            sx={{
              '--Stepper-spacing': '1rem',
              '--StepIndicator-size': '2rem',
              '--Step-gap': '1rem',
              '--Step-connectorThickness': '2px',
              '--Step-connectorRadius': '1px',
              minHeight: 400,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={index < activeStep}
                indicator={
                  <StepIndicator
                    variant={activeStep === index ? 'solid' : 'soft'}
                    color={activeStep === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
                slotProps={{
                  // Add this wrapper to prevent props leaking to DOM
                  root: {
                    sx: {
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: 'background.level1',
                      },
                    },
                    onClick: () => setActiveStep(index),
                  },
                }}
              >
                <Box>
                  <Box sx={{ fontWeight: 'bold' }}>{step.label}</Box>
                  <Box sx={{ color: 'text.secondary', fontSize: 'sm' }}>{step.description}</Box>
                </Box>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* Right side - Content */}
        <Box sx={{ flex: 1, maxWidth: '800px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderStepContent(activeStep)}

            {/* Navigation buttons */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 4,
                pt: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Button variant="outlined" color="neutral" onClick={onCancel} sx={{ minWidth: 100 }}>
                Cancel
              </Button>
              <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0} sx={{ minWidth: 100 }}>
                Back
              </Button>
              <Button
                onClick={activeStep === steps.length - 1 ? handleSubmit(onSubmit) : handleNext}
                disabled={activeStep === steps.length - 1 ? !isValid : !isStepValid(activeStep)}
                sx={{ minWidth: 100 }}
              >
                {activeStep === steps.length - 1 ? (mode === 'edit' ? 'Save' : 'Submit') : 'Next'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
}

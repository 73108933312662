import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRepository } from '../store/user.repository';
import { useUserStore } from '../store/users.store';
import { useAuthSync } from './useAuthSync';

export const useUser = (auth: any) => {
  const { firestore } = useFirebase();
  const userRepository = useMemo(() => new UserRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setUser, updateUser, clearUser } = useUserStore();
  const { user } = useUserStore();

  useAuthSync(auth);

  const updateUserMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<any> }) => userRepository.update(id, data),
    onSuccess: (_, variables) => {
      updateUser(variables.data);
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  return {
    updateUserMutation,
    updateUser: updateUserMutation.mutate,
    isUpdating: updateUserMutation.isPending,
    updateError: updateUserMutation.error,
    user,
    isAuthenticated: !!user,
  };
};

import { Edit, Mail, Phone } from '@mui/icons-material';
import { Avatar, Box, Card, IconButton, Typography } from '@mui/joy';
import { cardStyle } from './ContactPersonCard';

const FinancialContactCard = ({ contact }: { contact: any }) => {
  return (
    <Card sx={cardStyle} variant="outlined">
      <Box alignItems="center" display="flex" gap={2}>
        <Avatar
          alt={contact.name}
          src={contact.avatar || '/default-avatar.png'} // Fallback avatar
          sx={{
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
            height: 56,
            width: 56,
          }}
        />
        <Typography fontWeight="bold" level="h4" sx={{ fontSize: '1.25rem' }}>
          {contact.name}
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography startDecorator={<Mail />} sx={{ fontSize: '0.875rem' }}>
          {contact.email || 'No email provided'}
        </Typography>
        <Typography
          startDecorator={<Phone />}
          sx={{ fontSize: '0.875rem', mt: 1 }}
        >
          {contact.phone || 'No phone provided'}
        </Typography>
      </Box>

      <Box sx={{ position: 'absolute', right: 16, top: 16 }}>
        <IconButton aria-label="edit contact">
          <Edit fontSize="small" />
        </IconButton>
      </Box>
    </Card>
  );
};

export default FinancialContactCard;

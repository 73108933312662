import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { Company } from '@bluebird-monorepo/types';
import { InfoOutlined } from '@mui/icons-material';
import { Box, CardContent, Divider, Typography } from '@mui/joy';
import React from 'react';

interface CompanyDetailsCardProps {
  company: Company;
}

export const CompanyDetailsCard: React.FC<CompanyDetailsCardProps> = ({ company }) => {
  return (
    <BluebirdCard>
      <Typography level="h4" startDecorator={<InfoOutlined />} sx={{ paddingBottom: '1rem' }}>
        Company info
      </Typography>

      <Divider inset="none" />
      <CardContent sx={{ marginTop: 0, padding: '1rem' }}>
        <Box sx={{ display: 'grid', gap: 2 }}>
          <Box>
            <Typography level="title-md">Industry</Typography>
            <Typography>{company.industry}</Typography>
          </Box>
          <Box>
            <Typography level="title-md">Location</Typography>
            <Typography>{company.location}</Typography>
          </Box>
          {company.website && (
            <Box>
              <Typography level="title-md">Website</Typography>
              <Typography component="a" href={company.website} target="_blank">
                {company.website}
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </BluebirdCard>
  );
};

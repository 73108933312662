import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore } from 'firebase/firestore';

export interface UserDocument {
  id: string;
  email: string;
  displayName?: string;
  photoURL?: string;
  role?: string;
}

export class UserRepository extends BaseRepository<UserDocument> {
  constructor(db: Firestore) {
    super(db, 'users');
  }

  async getUserById(uid: string): Promise<any | null> {
    try {
      return this.getById(uid);
    } catch (error) {
      throw this.handleError(error, 'Error fetching user');
    }
  }
}

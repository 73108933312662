import { CompaniesTable, CreateCompanyWizard, useCompanies } from '@bluebird-monorepo/companies';
import React, { useState } from 'react';
import { Layout } from '../../../layout/Layout';

const CompaniesPage: React.FC = () => {
  const { companies } = useCompanies();

  const [isCreatingCompany, setIsCreatingCompany] = useState(false);

  const handleStartCreateCompany = () => {
    setIsCreatingCompany(true);
  };

  const handleCompleteCreateCompany = () => {
    setIsCreatingCompany(false);
  };

  const handleCancelCreateCompany = () => {
    setIsCreatingCompany(false);
  };

  return (
    <Layout
      title={isCreatingCompany ? 'Create new company' : 'Companies'}
      subtitle={isCreatingCompany ? '' : 'Manage your client companies and their job listings'}
      actionButton={
        !isCreatingCompany
          ? {
              label: 'Create new company',
              onClick: handleStartCreateCompany,
              variant: 'create',
            }
          : undefined
      }
    >
      {isCreatingCompany ? (
        <CreateCompanyWizard onComplete={handleCompleteCreateCompany} onCancel={handleCancelCreateCompany} />
      ) : (
        <CompaniesTable />
      )}
    </Layout>
  );
};

export default CompaniesPage;

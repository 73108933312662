import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Step, StepIndicator, Stepper, Typography } from '@mui/joy';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useCompanies } from '../../hooks/useCompanies';
import { AboutStep } from './steps/AboutStep';
import { ContactsStep } from './steps/ContactsStep';
import { InvoicingStep } from './steps/InvoicingStep';
import { ReviewStep } from './steps/ReviewStep';

const companySchema = z.object({
  name: z.string().min(1, 'Company name is required'),
  industry: z.string().optional(),
  location: z.string().optional(),
  website: z
    .string()
    .optional()
    .transform((val) => {
      if (!val) return val;
      if (!val.startsWith('http://') && !val.startsWith('https://')) {
        return `https://${val}`;
      }
      return val;
    }),
  status: z.enum(['client', 'prospect', 'partner']).default('prospect'),
  agreedFee: z.number().optional(),
  contactPersons: z.array(
    z.object({
      name: z.string().min(1),
      email: z.string().email(),
      phone: z.string().optional(),
      position: z.string().optional(),
    }),
  ),
  hiringManagers: z.array(
    z.object({
      name: z.string().min(1),
      email: z.string().email(),
      phone: z.string().optional(),
      position: z.string().optional(),
    }),
  ),
  invoicingDetails: z
    .object({
      registeredName: z.string().optional(),
      address: z.string().optional(),
      city: z.string().optional(),
      zipCode: z.string().optional(),
      country: z.string().optional(),
      kvkNumber: z.string().optional(),
      taxNumber: z.string().optional(),
    })
    .optional(),
  // Required fields for Company type compatibility
  bdStage: z.string().default('new'),
  financeContact: z.string().default(''),
  isClient: z.boolean().default(false),
  jobCount: z.number().default(0),
  owner: z.string().default(''),
  activeJobs: z.number().default(0),
  id: z.string().optional(), // Will be generated by the backend
});

type CompanyFormData = z.infer<typeof companySchema>;

const steps = [
  {
    label: 'About',
    description: 'Basic company information',
    component: AboutStep,
  },
  {
    label: 'Contacts',
    description: 'Contact persons and hiring managers',
    component: ContactsStep,
  },
  {
    label: 'Invoicing',
    description: 'Optional invoicing details',
    component: InvoicingStep,
  },
  {
    label: 'Review',
    description: 'Review and create company',
    component: ReviewStep,
  },
];

const stepValidationFields = {
  0: ['name', 'industry', 'location', 'website', 'status', 'agreedFee'],
  1: ['contactPersons', 'hiringManagers'],
  2: [], // Invoicing step is now optional, no required fields
  3: [], // Review step doesn't have its own fields
} as const;

interface CreateCompanyWizardProps {
  onComplete: () => void;
  onCancel: () => void;
}

export function CreateCompanyWizard({ onComplete, onCancel }: CreateCompanyWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { createCompany } = useCompanies();

  const methods = useForm<CompanyFormData>({
    resolver: zodResolver(companySchema),
    defaultValues: {
      name: '',
      industry: '',
      location: '',
      website: '',
      status: 'prospect',
      agreedFee: undefined,
      contactPersons: [],
      hiringManagers: [],
      invoicingDetails: undefined,
    },
  });

  const onSubmit = async (data: CompanyFormData) => {
    try {
      const companyData = {
        ...data,
        lastPlacementDate: new Date(),
        peoplePlaced: [],
        invoicingDetails: data.invoicingDetails
          ? {
              registeredName: data.invoicingDetails.registeredName || '',
              address: data.invoicingDetails.address || '',
              city: data.invoicingDetails.city || '',
              zipCode: data.invoicingDetails.zipCode || '',
              country: data.invoicingDetails.country || '',
              kvkNumber: data.invoicingDetails.kvkNumber || '',
              taxNumber: data.invoicingDetails.taxNumber || '',
            }
          : undefined,
        contactPersons: data.contactPersons.map((person) => ({ ...person, id: nanoid() })),
        hiringManagers:
          data.hiringManagers?.map((manager) => ({
            ...manager,
            id: nanoid(),
            responsibleForJobs: [],
          })) || [],
        industry: data.industry || 'Unknown',
        location: data.location || 'Unknown',
        agreedFee: data.agreedFee || 0,
        financeContact: {
          id: nanoid(),
          name: data.invoicingDetails?.registeredName || '',
          email: '',
        },
        isClient: data.status === 'client',
        bdStage: data.status === 'prospect' ? 'lead' : 'client',
        jobCount: 0,
        owner: '',
        activeJobs: 0,
        revenueGenerated: 0,
        slug: data.name.toLowerCase().replace(/\s+/g, '-'),
      };
      await createCompany(companyData);
      onComplete();
    } catch (error) {
      console.error('Failed to create company:', error);
    }
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await methods.handleSubmit(onSubmit)();
      return;
    }

    const fieldsToValidate = stepValidationFields[activeStep as keyof typeof stepValidationFields];
    const isStepValid = await methods.trigger(fieldsToValidate as any);

    if (isStepValid) {
      setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const ActiveStepComponent = steps[activeStep].component;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}
      >
        <Box sx={{ flex: '0 0 300px' }}>
          <Stepper
            component="nav"
            orientation="vertical"
            sx={{
              '--Stepper-spacing': '1rem',
              '--StepIndicator-size': '2rem',
              '--Step-gap': '0.5rem',
              '--Step-connectorThickness': '2px',
              '--Step-connectorRadius': '1px',
              minHeight: 400,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                active={activeStep === index}
                completed={index < activeStep}
                indicator={
                  <StepIndicator
                    variant={activeStep === index ? 'solid' : index < activeStep ? 'soft' : 'outlined'}
                    color={activeStep === index ? 'primary' : index < activeStep ? 'success' : undefined}
                  >
                    {index + 1}
                  </StepIndicator>
                }
              >
                <div>
                  <Typography fontWeight="lg">{step.label}</Typography>
                  <Typography level="body-xs">{step.description}</Typography>
                </div>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <IconButton onClick={onCancel} variant="plain">
              <Close />
            </IconButton>
          </Box>

          <ActiveStepComponent />

          <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined">
              Back
            </Button>
            <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Create Company' : 'Next'}</Button>
          </Stack>
        </Box>
      </Box>
    </FormProvider>
  );
}

import { Placement } from '@bluebird-monorepo/types';
import { Avatar, Box, Table, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import {
  calculateTotalFee,
  formatDate,
  formatFee,
  responsibleMapping,
} from '.';
import { sortPlacementsByDate } from './sorting';

interface PlacementsTableAllProps {
  placements: Placement[];
}

export function PlacementsTableAll({ placements }: PlacementsTableAllProps) {
  const sortedPlacements = sortPlacementsByDate(placements);
  const totalFee = calculateTotalFee(sortedPlacements);

  const navigate = useNavigate();

  const handleRowClick = (placementId: string) => {
    navigate(`/placements/${placementId}`);
  };

  return (
    <Box>
      <Table
        borderAxis="both"
        sx={(theme) => ({
          '& td': theme.variants.soft.neutral,
          '& th[scope="col"]': theme.variants.solid.neutral,
          '& tr > *:last-child': { bgcolor: 'success.softBg' },
        })}
      >
        <thead>
          <tr>
            <th scope="col">Placement Date</th>
            <th scope="col">Candidate</th>
            <th scope="col">Company</th>
            <th scope="col">Role</th>
            <th scope="col">Rep</th>
            <th scope="col" style={{ color: 'black' }}>
              Fee
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedPlacements.map((placement) => (
            <tr
              key={placement.id}
              onClick={() => handleRowClick(placement.id)}
              style={{ cursor: 'pointer' }}
            >
              <td>{formatDate(placement.placementdate)}</td>
              <td>{placement.candidate}</td>
              <td>{placement.company}</td>
              <td>{placement.role}</td>
              <td>
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                  <Avatar
                    alt={placement.rep}
                    src={
                      responsibleMapping[
                        placement.rep as keyof typeof responsibleMapping
                      ]?.imgUrl
                    }
                    sx={{ mr: 1 }}
                  />
                  <Typography level="title-sm">{placement.rep}</Typography>
                </Box>
              </td>
              <td>{formatFee(placement.fee)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="row">Totals</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <Typography fontWeight="bold">{formatFee(totalFee)}</Typography>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Box>
  );
}

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Placement } from '@bluebird-monorepo/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { PlacementRepository } from '../repositories/placements.repository';
import { usePlacementStore } from '../store/placements.store';

export const usePlacements = () => {
  const { firestore } = useFirebase();
  const placementRepository = useMemo(() => new PlacementRepository(firestore), [firestore]);
  const queryClient = useQueryClient();
  const { placements, setPlacements, addPlacement, updatePlacement, removePlacement } = usePlacementStore();

  const { data, isLoading, error } = useQuery({
    queryKey: ['placements'],
    queryFn: () => placementRepository.getAll(),
  });

  useEffect(() => {
    if (data) {
      setPlacements(data);
    }
  }, [data, setPlacements]);

  const createPlacementMutation = useMutation({
    mutationFn: (newPlacement: Omit<Placement, 'id'>) => placementRepository.create(newPlacement),
    onSuccess: (placement) => {
      addPlacement(placement);
      queryClient.invalidateQueries({ queryKey: ['placements'] });
    },
  });

  const updatePlacementMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Placement> }) => placementRepository.update(id, data),
    onSuccess: (_, variables) => {
      updatePlacement(variables.id, variables.data);
      queryClient.invalidateQueries({ queryKey: ['placements'] });
    },
  });

  const deletePlacementMutation = useMutation({
    mutationFn: (id: string) => placementRepository.delete(id),
    onSuccess: (_, id) => {
      removePlacement(id);
      queryClient.invalidateQueries({ queryKey: ['placements'] });
    },
  });

  return {
    placements,
    isLoading,
    error,
    createPlacement: createPlacementMutation.mutate,
    updatePlacement: updatePlacementMutation.mutate,
    deletePlacement: deletePlacementMutation.mutate,
  };
};

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { StageRepository } from '../repositories/stage.repository';

export const useStages = () => {
  const { firestore } = useFirebase();
  const stageRepository = useMemo(() => new StageRepository(firestore), [firestore]);

  const query = useQuery({
    queryKey: ['stages'],
    queryFn: () => stageRepository.getAll(),
  });

  return {
    stages: query.data || [],
    loading: query.isLoading,
    error: query.error,
  };
};

import { Alert, CircularProgress } from '@mui/joy';
import { useStages } from '../hooks/useStages';
import { useKanbanSettings } from '../store/useKanbanSettings';
import { KanbanColumn } from '../types';
import KanbanBoard from './KanbanBoard';

interface KanbanBoardContainerProps {
  initial: KanbanColumn;
  isCombineEnabled?: boolean;
  onStatusUpdate?: (candidateId: string, statusId: number, status: string, jobId: string) => void;
  useClone?: boolean;
  withScrollableColumns?: boolean;
}

export function KanbanBoardContainer(props: KanbanBoardContainerProps) {
  const { stages, loading, error } = useStages();
  const { hiddenStageIds } = useKanbanSettings();

  // Filter out hidden stages
  const visibleStages = stages.filter((stage) => !hiddenStageIds.includes(stage.id));

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Alert color="danger" variant="soft" sx={{ margin: '1rem' }}>
        Error loading stages: {error.message}
      </Alert>
    );
  }

  return <KanbanBoard {...props} stages={visibleStages} />;
}

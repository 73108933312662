import { Placement } from '@bluebird-monorepo/types';
import { useMemo, useState } from 'react';
export function usePlacementsFilters(placements: Placement[]) {
  const [selectedQuarter, setSelectedQuarter] = useState('All');
  const [selectedRep, setSelectedRep] = useState('All');
  const [totalFeeForYear, setTotalFeeForYear] = useState(0);

  const filteredPlacements = useMemo(() => {
    return placements.filter((placement) => {
      const placementQuarter = placement.Q ? `Q${placement.Q}` : 'Unknown';
      const quarterMatch =
        selectedQuarter === 'All' || placementQuarter === selectedQuarter;
      const repMatch = selectedRep === 'All' || placement.rep === selectedRep;
      return quarterMatch && repMatch;
    });
  }, [placements, selectedQuarter, selectedRep]);

  return {
    filteredPlacements,
    selectedQuarter,
    selectedRep,
    setSelectedQuarter,
    setSelectedRep,
    totalFeeForYear,
    setTotalFeeForYear,
  };
}

import { CandidateNote } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface CandidateNotesState {
  notes: CandidateNote[];
  setNotes: (notes: CandidateNote[]) => void;
  addNote: (note: CandidateNote) => void;
  removeNote: (id: string) => void;
}

export const useCandidateNotesStore = create<CandidateNotesState>((set) => ({
  notes: [],
  setNotes: (notes) => set({ notes }),
  addNote: (note) => set((state) => ({ notes: [...state.notes, note] })),
  removeNote: (id) =>
    set((state) => ({
      notes: state.notes.filter((note) => note.id !== id),
    })),
}));

import { FirebaseError } from 'firebase/app';

export class FirebaseRepositoryError extends Error {
  constructor(
    message: string,
    public readonly code: string,
    public readonly operation: string
  ) {
    super(message);
    this.name = 'FirebaseRepositoryError';
  }
}

export function handleFirebaseError(error: unknown, operation: string): never {
  if (error instanceof FirebaseError) {
    throw new FirebaseRepositoryError(error.message, error.code, operation);
  }
  throw error;
}

import { InfoOutlined } from '@mui/icons-material';
import { Box, CardContent, Typography } from '@mui/joy';
import { FC } from 'react';

import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { CandidateAssignmentSection } from '@bluebird-monorepo/candidateJobAssignments';
import { useJobs } from '@bluebird-monorepo/jobs';
import { JobInfoCard } from './jobInfo';
import { SidePanels } from './sidePanels';

const GeneralTab: FC = () => {
  const { currentJob: job } = useJobs();

  if (!job) return null;

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: {
          lg: '1fr 1fr',
          md: '1fr',
          sm: '1fr',
          xs: '1fr',
        },
        mb: 6,
        width: '100%',
      }}
    >
      <JobInfoCard job={job} />
      <SidePanels job={job} />
      <BluebirdCard>
        <Typography level="body-md" startDecorator={<InfoOutlined />} sx={{ paddingBottom: '1rem' }}>
          External Job Description (Used for website and Job Boards)
        </Typography>
        <Typography level="body-sm">This feature is coming..</Typography>
        <CardContent>
          <RichTextEditor
            onChange={(value) => {
              console.log(value);
            }}
            content={job?.externalJobDescription}
          />
        </CardContent>
      </BluebirdCard>
      <BluebirdCard>
        <Typography level="body-md" startDecorator={<InfoOutlined />} sx={{ paddingBottom: '1rem' }}>
          Quick Candidate Assignment
        </Typography>
        <CardContent>
          <CandidateAssignmentSection job={job} />
        </CardContent>
      </BluebirdCard>
    </Box>
  );
};

export default GeneralTab;

import { Company } from '@bluebird-monorepo/types';
import { Link } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

export function JobCountLink({ company }: { company: Company }) {
  const navigate = useNavigate();

  return (
    <Link
      component="button"
      onClick={() => navigate(`/companies/${company.id}/jobs`)}
      level="body-sm"
      sx={{ color: 'primary.500', fontWeight: 'md' }}
    >
      {company.jobCount}
    </Link>
  );
}

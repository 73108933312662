import { BluebirdDrawer, DashedButton } from '@bluebird-monorepo/bluebird-ui';
import { Box, Button, List, ListItem, ListItemDecorator, Typography } from '@mui/joy';
import { useState } from 'react';
import { useCalendarStore } from '../../store/calendar.store';
import { CustomCheckbox } from '../CalendarCheckbox/CustomCalendarCheckbox';

export function CalendarFilter() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { showNoAttendees, setShowNoAttendees, removedEventIds, events, restoreEvent } = useCalendarStore();

  const handleOpen = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);

  // Find full event details for removed events
  const removedEvents = events.filter((event) => removedEventIds.includes(event.id));

  return (
    <Box>
      <DashedButton onClick={handleOpen}>Filters</DashedButton>
      <BluebirdDrawer onClose={handleClose} open={isDrawerOpen} size="md" title="Calendar Filters">
        <Box>
          <Typography
            id="decorated-list-demo"
            level="body-xs"
            sx={{ fontWeight: 'lg', mb: 1, textTransform: 'uppercase' }}
          >
            Filters
          </Typography>
          <CustomCheckbox
            checked={showNoAttendees}
            label="Show meetings without attendees"
            onChange={(value) => setShowNoAttendees(value)}
          />
        </Box>

        {removedEvents.length > 0 && (
          <Box>
            <Typography
              id="decorated-list-demo"
              level="body-xs"
              sx={{ fontWeight: 'lg', mb: 1, textTransform: 'uppercase' }}
            >
              Show removed events
            </Typography>
            <List aria-labelledby="decorated-list-demo">
              {removedEvents.map((event) => (
                <ListItem key={event.id}>
                  <ListItemDecorator>📅</ListItemDecorator>
                  {event.title}
                  <Button onClick={() => restoreEvent(event.id)} size="sm" variant="soft" sx={{ ml: 'auto' }}>
                    Restore
                  </Button>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </BluebirdDrawer>
    </Box>
  );
}

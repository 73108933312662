import { useJobCandidateAssignments } from '@bluebird-monorepo/candidateJobAssignments';
import { useCandidates } from '@bluebird-monorepo/candidates';
import { type Job } from '@bluebird-monorepo/types';
import { Autocomplete, Avatar, Box, Button, Chip, Divider, Snackbar, Stack, Typography } from '@mui/joy';
import { FC, useState } from 'react';

interface CandidateOption {
  id: string;
  label: string;
  position: string;
  avatar?: string;
  firstName: string;
  lastName: string;
  currentOrganization?: string;
}

interface CandidateAssignmentSectionProps {
  job: Job;
}

export const CandidateAssignmentSection: FC<CandidateAssignmentSectionProps> = ({ job }) => {
  const { candidates } = useCandidates();
  const { assignments, createAssignment } = useJobCandidateAssignments(job.id);

  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateOption | null>(null);

  if (!assignments) return null;
  const assignedCandidates = assignments
    .filter((assignment) => assignment.jobId === job.id)
    .map((assignment) => assignment.candidateId);

  const isCandidateAlreadyAssigned = selectedCandidate?.id && assignedCandidates.includes(selectedCandidate.id);

  const getCandidateOptions = (): CandidateOption[] => {
    return (
      candidates?.map((candidate) => ({
        id: candidate.id,
        label: `${candidate.firstName} ${candidate.lastName}`,
        position: candidate.currentPosition || 'No position',
        avatar: candidate.avatar,
        firstName: candidate.firstName || '',
        lastName: candidate.lastName || '',
        currentOrganization: candidate.currentOrganization || '',
      })) || []
    );
  };

  const handleAssignCandidate = async () => {
    if (!selectedCandidate?.id) return;

    if (isCandidateAlreadyAssigned) {
      setErrorMessage('This candidate is already assigned to the job.');
      return;
    }

    try {
      createAssignment({
        id: `${job.id}_${selectedCandidate.id}`,
        jobId: job.id,
        candidateId: selectedCandidate.id,
        status: 'Applied',
        status_id: 1,
      });
      setSelectedCandidate(null);
    } catch (error) {
      setErrorMessage('Failed to assign candidate to job');
    }
  };

  return (
    <Stack direction="row" gap={1} width="100%">
      <Autocomplete
        onChange={(_, value) => setSelectedCandidate(value)}
        options={getCandidateOptions()}
        getOptionLabel={(option) => `${option.label} - ${option.position}`}
        renderOption={(props, option) => (
          <li {...props}>
            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'flex-start', gap: 2, py: 1.5, width: '100%' }}>
              <Avatar
                src={option.avatar}
                alt={option.label}
                sx={{
                  width: 48,
                  height: 48,
                  ml: 0.5,
                  border: '2px solid',
                  borderColor: 'background.level2',
                }}
              >
                {option.firstName[0]}
                {option.lastName[0]}
              </Avatar>
              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                <Typography
                  level="title-md"
                  sx={{
                    fontWeight: 600,
                    color: 'text.primary',
                    mb: 0.5,
                  }}
                >
                  {option.label}
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography
                    level="body-sm"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 500,
                    }}
                  >
                    {option.position.slice(0, 20)}
                  </Typography>
                  {option?.currentOrganization && (
                    <>
                      <Divider orientation="vertical" sx={{ mx: 1, my: 0.25 }} />
                      <Typography
                        level="body-sm"
                        sx={{
                          color: 'text.tertiary',
                          fontStyle: 'italic',
                        }}
                      >
                        {option?.currentOrganization.slice(0, 20)}
                      </Typography>
                    </>
                  )}
                </Stack>
                {assignedCandidates.includes(option.id) && (
                  <Chip size="sm" variant="soft" color="warning" sx={{ mt: 0.5 }}>
                    Already assigned
                  </Chip>
                )}
              </Box>
            </Box>
          </li>
        )}
        placeholder="Select a candidate..."
        sx={{
          width: '100%',
          '--ListItem-radius': '8px',
          '--ListItem-minHeight': '76px',
          '& .MuiAutocomplete-listbox': {
            p: 1,
            gap: 0.5,
          },
        }}
        value={selectedCandidate}
        slotProps={{
          listbox: {
            sx: {
              '--ListItem-paddingY': '4px',
              '--ListItem-paddingX': '12px',
              '& > li:hover': {
                backgroundColor: 'background.level2',
              },
            },
          },
        }}
      />
      <Button
        disabled={!selectedCandidate}
        onClick={handleAssignCandidate}
        sx={{ whiteSpace: 'nowrap' }}
        variant="outlined"
      >
        Add Candidate
      </Button>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        color="danger"
        onClose={() => setErrorMessage('')}
        open={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </Stack>
  );
};

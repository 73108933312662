import { Chip, FormLabel, ListDivider } from '@mui/joy';
import Autocomplete, { autocompleteClasses } from '@mui/joy/Autocomplete';
import Box from '@mui/joy/Box';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import React from 'react';

interface Item {
  description: string;
  name: string;
  id: string;
  domain?: string;
}

type Colors = {
  [domain: string]: string;
};

interface CustomSelectCalendarProps {
  colors: Colors;
  defaultValue?: string;
  items: Item[];
  label?: string;
  onChange: (value: string, item?: Item) => void;
}

export default function CustomSelectCalendar({
  colors,
  defaultValue = '',
  items,
  label = 'Select an option',
  onChange,
}: CustomSelectCalendarProps) {
  const defaultItem = items.find((item) => item.name === defaultValue);
  console.log(items);
  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        defaultValue={defaultItem}
        onChange={(_, value) =>
          onChange?.(
            value?.name || '',
            items.find((item) => item.name === value?.name),
          )
        }
        options={items}
        getOptionLabel={(option) => option.name}
        filterOptions={(options, { inputValue }) => {
          const searchTerm = inputValue.toLowerCase();
          return options.filter(
            (option) =>
              option.name?.toLowerCase().includes(searchTerm) || option.description?.toLowerCase().includes(searchTerm),
          );
        }}
        renderOption={(props, option, { selected }) => (
          <React.Fragment key={option.name}>
            <li {...props}>
              <ListItemContent
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography level="title-sm">{option.name}</Typography>
                  <Typography level="body-xs">{option.description}</Typography>
                </Box>
                {option.domain && (
                  <Chip
                    color={colors[option.domain] as 'primary' | 'success' | 'warning' | 'danger' | 'neutral'}
                    size="sm"
                    style={{ '--colors-role': colors[option.domain] } as any}
                    sx={{
                      bgcolor: `${'var(--colors-role)'}.softBg`,
                      borderRadius: '2px',
                      fontSize: 'xs',
                      minHeight: '20px',
                      ml: 2,
                      paddingInline: '4px',
                    }}
                    variant="outlined"
                  >
                    {option.domain}
                  </Chip>
                )}
              </ListItemContent>
            </li>
            {selected && <ListDivider inset="gutter" role="none" />}
          </React.Fragment>
        )}
        slotProps={{
          listbox: {
            sx: {
              [`& .${autocompleteClasses.option}`]: {
                padding: '8px 12px',
              },
            },
          },
          input: {
            sx: {
              padding: '8px 12px',
            },
          },
        }}
        sx={{
          minWidth: 240,
          [`& .${autocompleteClasses.listbox}`]: {
            '--ListItem-minHeight': '48px',
            '--ListItem-padding': '8px 12px',
            '--ListDivider-gap': '0px',
          },
          [`& .${autocompleteClasses.option}`]: {
            padding: 0,
          },
        }}
      />
    </Box>
  );
}

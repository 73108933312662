import { Company } from '@bluebird-monorepo/types';
import { Box, Stack } from '@mui/joy';
import React from 'react';
import FinancialContactCard from '../../FinancialContactCard';
import FinancialDataCard from '../../FinancialDataCard';
import { CompanyFilesCard } from './CompanyFilesCard';

interface FinanceTabProps {
  company: Company;
}

export const FinanceTab: React.FC<FinanceTabProps> = ({ company }) => {
  return (
    <Stack spacing={2}>
      <Box>
        {company.financeContact && <FinancialContactCard contact={company.financeContact} />}
        <FinancialDataCard company={company} />
      </Box>
      <CompanyFilesCard company={company} />
    </Stack>
  );
};

import { Company } from '@bluebird-monorepo/types';
import { AccessTime, Euro, TrendingUp, Work } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, Typography } from '@mui/joy';
import React from 'react';
import { CompanyDetailsCard } from './CompanyDetailsCard';
import { CompanyDetailsHeader } from './CompanyDetailsHeader';

interface GeneralTabProps {
  company: Company;
}

const MetricsCard: React.FC<{ title: string; value: string | number; icon: React.ReactNode; trend?: string }> = ({
  title,
  value,
  icon,
  trend,
}) => (
  <Card variant="soft" sx={{ height: '100%' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box sx={{ mr: 1 }}>{icon}</Box>
        <Typography level="body-sm">{title}</Typography>
      </Box>
      <Typography level="h3" sx={{ mb: trend ? 1 : 0 }}>
        {value}
      </Typography>
      {trend && (
        <Typography level="body-sm" color="success" startDecorator={<TrendingUp />}>
          {trend}
        </Typography>
      )}
    </CardContent>
  </Card>
);

export const GeneralTab: React.FC<GeneralTabProps> = ({ company }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
      }}
    >
      <CompanyDetailsHeader company={company} />
      <CompanyDetailsCard company={company} />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid xs={12} sm={6} md={3}>
          <MetricsCard
            title="Revenue"
            value={new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(15000)}
            icon={<Euro />}
            trend="+12% vs last year"
          />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <MetricsCard title="Active Jobs" value={company.jobCount || 0} icon={<Work />} />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <MetricsCard title="Time to Hire" value="120 days" icon={<AccessTime />} />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <MetricsCard title="Grade" value="A" icon={<TrendingUp />} trend="Top 10% of clients" />
        </Grid>
      </Grid>
    </Box>
  );
};

import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Firestore } from 'firebase/firestore';

export interface StatusDocument {
  id: string;
  name: string;
  displayName: string;
}

export class StatusRepository extends BaseRepository<StatusDocument> {
  constructor(db: Firestore) {
    super(db, 'statuses');
  }
}

import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}

type TimestampInput = Timestamp | FirestoreTimestamp | number | null | undefined;

/**
 * Converts a Firestore timestamp or timestamp-like object to milliseconds
 * @param timestamp Firestore Timestamp, timestamp-like object, or number
 * @returns number Milliseconds since epoch, or current time if input is invalid
 */
export function toMillis(timestamp: TimestampInput): number {
  if (timestamp == null) {
    return Date.now(); // Return current time for null/undefined
  }

  if (typeof timestamp === 'number') {
    return timestamp;
  }

  if (timestamp instanceof Timestamp) {
    return timestamp.toMillis();
  }

  // Handle timestamp-like object (from Firestore)
  if (typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
    return timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
  }

  console.warn('Invalid timestamp format, using current time:', timestamp);
  return Date.now(); // Fallback to current time
}

/**
 * Converts a Firestore timestamp or timestamp-like object to a dayjs object
 * @param timestamp Firestore Timestamp, timestamp-like object, or number
 * @returns dayjs.Dayjs
 */
export function toDayjs(timestamp: TimestampInput): dayjs.Dayjs {
  return dayjs(toMillis(timestamp));
}

/**
 * Formats a Firestore timestamp or timestamp-like object to a display string
 * @param timestamp Firestore Timestamp, timestamp-like object, or number
 * @param format Optional dayjs format string (defaults to 'HH:mm')
 * @returns string
 */
export function formatTimestamp(timestamp: TimestampInput, format = 'HH:mm'): string {
  return toDayjs(timestamp).format(format);
}

/**
 * Checks if a timestamp is in the past
 * @param timestamp Firestore Timestamp, timestamp-like object, or number
 * @returns boolean
 */
export function isPastTimestamp(timestamp: TimestampInput): boolean {
  return toMillis(timestamp) < Date.now();
}

/**
 * Formats a time range between two timestamps
 * @param startTime Firestore Timestamp, timestamp-like object, or number
 * @param endTime Firestore Timestamp, timestamp-like object, or number
 * @param format Optional dayjs format string (defaults to 'HH:mm')
 * @returns string
 */
export function formatTimeRange(startTime: TimestampInput, endTime: TimestampInput, format = 'HH:mm'): string {
  return `${formatTimestamp(startTime, format)} - ${formatTimestamp(endTime, format)}`;
}

/**
 * Formats a date range between two timestamps
 * @param startTime Firestore Timestamp, timestamp-like object, or number
 * @param endTime Firestore Timestamp, timestamp-like object, or number
 * @returns string
 */
export function formatDateRange(startTime: TimestampInput, endTime: TimestampInput): string {
  const start = toDayjs(startTime);
  const end = toDayjs(endTime);
  return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`;
}

/**
 * Groups events by a date format
 * @param events Array of events with timestamp properties
 * @param timestampKey Key to access the timestamp in each event (e.g., 'when.startTime')
 * @param format Optional dayjs format string for grouping (defaults to 'dddd')
 * @returns Record<string, T[]>
 */
export function groupEventsByDate<T extends { [key: string]: any }>(
  events: T[],
  timestampKey: string,
  format = 'dddd',
): Record<string, T[]> {
  if (!events) return {};

  return events.reduce((acc: Record<string, T[]>, event) => {
    // Safely access nested timestamp using the key path
    const timestamp = timestampKey.split('.').reduce((obj, key) => obj?.[key], event) as unknown as TimestampInput;
    const day = toDayjs(timestamp).format(format);

    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(event);
    return acc;
  }, {});
}

import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBc0_zgsLHDzMCTdsxWioVYmZ8d0Kpk7I0',
  appId: '1:853506315935:web:fa5803926d642273afb4aa',
  authDomain: 'bbit-dashboard.firebaseapp.com',
  measurementId: 'G-0WYEDJ52E7',
  messagingSenderId: '853506315935',
  projectId: 'bbit-dashboard',
  storageBucket: 'bbit-dashboard.appspot.com',
};

class FirebaseService {
  private static instance: FirebaseService;
  public app: FirebaseApp;
  public auth: Auth;
  public firestore: Firestore;
  public functions: Functions;
  public storage: FirebaseStorage;

  private constructor() {
    try {
      this.app = getApp();
    } catch {
      this.app = initializeApp(firebaseConfig);
    }
    this.auth = getAuth(this.app);
    this.firestore = getFirestore(this.app);
    this.functions = getFunctions(this.app);
    this.storage = getStorage(this.app);
  }

  static getInstance(): FirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }
}

export const firebaseService = FirebaseService.getInstance();

import { CssBaseline } from '@mui/joy';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import {
  THEME_ID as MATERIAL_THEME_ID,
  ThemeProvider as MaterialCssVarsProvider,
  createTheme as materialExtendTheme,
} from '@mui/material/styles';
import { pdfjs } from 'react-pdf';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import CalendarPage from './pages/calendar/CalendarPage';
import CandidateDetailPage from './pages/candidates/CandidateDetailPage';
import CandidatesPage from './pages/candidates/CandidatesPage';
import CompaniesPage from './pages/companies/CompaniesPage';
import CompanyDetailPage from './pages/companies/CompanyDetailPage';
import DashboardPage from './pages/DashboardPage';
import JobDetailsPage from './pages/jobs/JobDetailPage';
import JobsPage from './pages/jobs/JobsPage';
import PlacementDetailPage from './pages/placements/PlacementDetailPage';
import PlacementsPage from './pages/placements/PlacementPage';
import UserSettingsPage from './pages/settings/UserSettingsPage';
import SignInPage from './pages/SignInPage';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const materialTheme = materialExtendTheme();

function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        <Router>
          <Routes>
            <Route element={<DashboardPage />} path="/" />
            <Route element={<JobsPage />} path="/jobs" />
            <Route path="/jobs/:jobId" element={<JobDetailsPage />}>
              <Route index element={<Navigate to="general" replace />} />
              <Route path="general" element={<JobDetailsPage />} />
              <Route path="candidates" element={<JobDetailsPage />} />
              <Route path="notes" element={<JobDetailsPage />} />
              <Route path="qa" element={<JobDetailsPage />} />
              <Route path="events" element={<JobDetailsPage />} />
            </Route>

            <Route element={<CompaniesPage />} path="/companies" />
            <Route element={<CompanyDetailPage />} path="/companies/:companyId" />

            <Route element={<CandidatesPage />} path="/candidates" />
            <Route element={<CandidateDetailPage />} path="/candidates/:candidateId" />

            <Route element={<PlacementsPage />} path="/placements" />
            <Route element={<PlacementDetailPage />} path="/placements/:placementId" />

            <Route element={<SignInPage />} path="/sign-in" />

            <Route element={<CalendarPage />} path="/calendar" />

            <Route path="/user-settings/:userId" element={<UserSettingsPage />} />
          </Routes>
        </Router>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;

import { Candidate } from '@bluebird-monorepo/types';
import { Button, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

export function CandidatesTableRow({ candidate }: { candidate: Candidate }) {
  const navigate = useNavigate();

  return (
    <tr>
      <td>
        <Typography level="body-sm">
          {candidate.firstName} {candidate.lastName}
        </Typography>
      </td>
      <td>
        <Typography level="body-sm">{candidate.currentPosition || 'N/A'}</Typography>
      </td>
      <td>
        <Typography level="body-sm">{candidate.currentOrganization || 'N/A'}</Typography>
      </td>
      <td>
        <Typography level="body-sm">{candidate.city || 'N/A'}</Typography>
      </td>
      <td>
        <Typography level="body-sm">{candidate.score || 'N/A'}</Typography>
      </td>
      <td>
        <Typography level="body-sm">{candidate.status || 'New'}</Typography>
      </td>
      <td>
        <Button onClick={() => navigate(`/candidates/${candidate.id}`)} size="sm" variant="outlined">
          View
        </Button>
      </td>
    </tr>
  );
}

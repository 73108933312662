import { TableLayout } from '@bluebird-monorepo/bluebird-ui';
import { Box } from '@mui/joy';
import { Pagination } from '@mui/material';
import { useMemo, useState } from 'react';
import { useCandidates } from '../../hooks/useCandidates';
import { useFilteredAndSortedCandidates } from '../../hooks/useFilteredAndSortedCandidates';
import CandidatesTableHeader from './CandidatesTableHeader';
import { CandidatesTableRow } from './CandidatesTableRow';

interface CandidateTableProps {
  searchQuery?: string;
}

export function CandidateTable({ searchQuery = '' }: CandidateTableProps) {
  const [orderBy, setOrderBy] = useState<string>('firstName');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { candidates, isLoading } = useCandidates();

  // Filter candidates based on search query
  const filteredCandidates = useMemo(() => {
    if (!searchQuery) return candidates;

    const searchLower = searchQuery.toLowerCase();
    return candidates.filter((candidate) => {
      return (
        candidate.firstName?.toLowerCase().includes(searchLower) ||
        candidate.lastName?.toLowerCase().includes(searchLower) ||
        candidate.currentPosition?.toLowerCase().includes(searchLower) ||
        candidate.currentOrganization?.toLowerCase().includes(searchLower) ||
        candidate.city?.toLowerCase().includes(searchLower)
      );
    });
  }, [candidates, searchQuery]);

  // Apply sorting and filtering
  const sortedCandidates = useFilteredAndSortedCandidates(filteredCandidates, orderBy, order);

  // Apply pagination
  const paginatedCandidates = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedCandidates.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedCandidates, page, rowsPerPage]);

  const handleSort = (field: string) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
    // Reset to first page when sorting changes
    setPage(0);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <TableLayout
        header={<CandidatesTableHeader onSort={handleSort} order={order} orderBy={orderBy} />}
        rows={paginatedCandidates.map((candidate) => (
          <CandidatesTableRow candidate={candidate} key={candidate.id} />
        ))}
        tableProps={{
          variant: 'outlined',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2, alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(sortedCandidates.length / rowsPerPage)}
          page={page + 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </Box>
  );
}

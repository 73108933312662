import { Job } from '@bluebird-monorepo/types';
import { Box } from '@mui/joy';
import React from 'react';
import { CandidatesInJob } from './CandidatesInJob';

type CandidatesTabProps = {
  editableJob: Job;
};
const CandidatesTab: React.FC<CandidatesTabProps> = ({ editableJob }) => {
  return <Box>{editableJob && <CandidatesInJob job={editableJob} />}</Box>;
};

export default CandidatesTab;

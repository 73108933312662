import { Job } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import React from 'react';

type NotestabProps = {
  editableJob: Job;
};
const NotesTab: React.FC<NotestabProps> = ({ editableJob }) => {
  return (
    <Box>
      <Typography level="h4">Notes</Typography>
      Notes should be here.
    </Box>
  );
};

export default NotesTab;

import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';

import { Dot } from '@bluebird-monorepo/bluebird-ui';
import styled from '@emotion/styled';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Divider } from '@mui/joy';
import { Component, ReactElement } from 'react';

import { CandidateCard, Stage } from '../types';
import CandidateAssignedToJobList from './CandidateAssignedToJobList';
import Title from './Title';

const Container = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 15px;
`;

interface HeaderProps {
  bgColor: string;
  isDragging: boolean;
}

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 8px;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: 'blue';
  }
  border-radius: 0;
  text-align: center;
  color: var(--joy-palette-neutral-800);
  text-decoration-color: ${({ bgColor }) => bgColor};
`;

interface Props {
  bgColor: string;
  index: number;
  isCombineEnabled?: boolean;
  isScrollable?: boolean;
  isDraggingSelected?: boolean;
  cards: CandidateCard[];
  stage?: Stage;
  title: string;
  useClone?: boolean;
}

export default class Column extends Component<Props> {
  render(): ReactElement {
    const title: string = this.props.title;
    const cards: CandidateCard[] = this.props.cards;
    const index: number = this.props.index;
    const bgColor: string = this.props.bgColor;
    const stage: Stage | undefined = this.props.stage;
    const isDraggingSelected: boolean = this.props.isDraggingSelected || false;

    return (
      <Draggable draggableId={title?.toString()} index={index} isDragDisabled={true}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <Header bgColor={bgColor} isDragging={snapshot.isDragging}>
              <Title
                {...provided.dragHandleProps}
                aria-label={`${title} list`}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '0.5rem',
                  justifyContent: 'center',
                  textDecorationColor: bgColor,
                  textDecorationLine: 'underline',
                }}
              >
                {title} <Dot bgColor={bgColor} height={8} width={8} />
              </Title>
            </Header>
            <Divider inset="none" sx={{ mx: 2, my: 0 }}></Divider>
            <Droppable droppableId={stage?.id?.toString() || ''} type="CARD">
              {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
                <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                  <CandidateAssignedToJobList
                    internalScroll={this.props.isScrollable}
                    isCombineEnabled={Boolean(this.props.isCombineEnabled)}
                    listId={stage?.id?.toString() || title}
                    listType="CARD"
                    cards={cards}
                    style={{
                      backgroundColor: dropSnapshot.isDraggingOver ? 'rgba(0, 0, 0, 0.1)' : undefined,
                      opacity: isDraggingSelected ? 0.5 : 1,
                      transition: 'background-color 0.2s ease',
                    }}
                    useClone={Boolean(this.props.useClone)}
                    isDraggingSelected={isDraggingSelected}
                  />
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </Container>
        )}
      </Draggable>
    );
  }
}

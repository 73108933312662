import { DialogContent, DialogTitle, Divider, Sheet } from '@mui/joy';
import Drawer from '@mui/joy/Drawer';
import * as React from 'react';

interface DrawerFiltersProps {
  children: React.ReactNode;
  onClose: () => void; // Add onClose prop
  open: boolean;
  size?: 'lg' | 'md' | 'sm';
  title: string;
}

function BluebirdDrawer({
  children,
  onClose, // Destructure onClose prop
  open,
  size = 'lg',
  title,
}: DrawerFiltersProps) {
  const [isOpen, setIsOpen] = React.useState(open);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    onClose(); // Call the onClose callback
  };

  return (
    <Drawer
        anchor="right"
        onClose={handleClose} // Use handleClose
        open={isOpen}
        size={size}
        slotProps={{
          content: {
            sx: {
              bgcolor: 'transparent',
              boxShadow: 'none',
              p: { md: 3, sm: 0 },
            },
          },
        }}
        variant="plain"
      >
        <Sheet
          sx={{
            borderRadius: 'md',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
            overflow: 'auto',
            p: 2,
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          <Divider sx={{ mt: 'auto' }} />
          <DialogContent sx={{ gap: 2 }}>{children}</DialogContent>
        </Sheet>
      </Drawer>
  );
}
export { BluebirdDrawer };

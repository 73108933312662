import { Box, Stack, Typography } from '@mui/joy';

const FinancialDataCard = ({ company }: { company: any }) => {
  return (
    <Stack gap={1}>
      <Typography fontSize="1.2rem" fontWeight="bold" mb={2}>
        Financial Information
      </Typography>

      <Box display="grid" gap={1} gridTemplateColumns={{ xs: '1fr' }}>
        <Typography whiteSpace="normal">
          <strong>Tax Number:</strong> {company.invoicingDetails?.taxNumber}
        </Typography>
        <Typography whiteSpace="normal">
          <strong>KvK Number:</strong> {company.invoicingDetails?.kvkNumber}
        </Typography>
        <Typography whiteSpace="normal">
          <strong>Registered Name:</strong> {company.invoicingDetails?.registeredName}
        </Typography>
        <Typography whiteSpace="normal">
          <strong>Address:</strong> {company.invoicingDetails?.address}, {company.invoicingDetails?.city},{' '}
          {company.invoicingDetails?.zipCode}, {company.invoicingDetails?.country}
        </Typography>
      </Box>
    </Stack>
  );
};

export default FinancialDataCard;

import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { Candidate } from '@bluebird-monorepo/types';
import { Firestore, WhereFilterOp } from 'firebase/firestore';

export class CandidatesRepository extends BaseRepository<Candidate> {
  constructor(db: Firestore) {
    super(db, 'candidates');
  }

  async getByLinkedin(linkedinUrl: string): Promise<Candidate[]> {
    const constraints: { field: string; op: WhereFilterOp; value: any }[] = [
      { field: 'linkedin', op: '==', value: linkedinUrl },
    ];
    return this.getAllForExtension(constraints);
  }
}

import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { LoadingOverlay } from '@bluebird-monorepo/bluebird-ui';
import {
  CalendarFilter,
  EventDetailDialog,
  groupEventsByDate,
  toMillis,
  useCalendarEvents,
  useCalendarStore,
  WeekNavigation,
  WeekView,
} from '@bluebird-monorepo/calendar';
import { useCompanies } from '@bluebird-monorepo/companies';
import { useJobs } from '@bluebird-monorepo/jobs';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { SyncRounded } from '@mui/icons-material';
import { Box, Button, Modal, ModalDialog } from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';
import { httpsCallable } from 'firebase/functions';
import { useCallback, useMemo, useState } from 'react';
import { Layout } from '../../../layout/Layout';

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] as const;

function WeekViewContainer({ onEventClick }: { onEventClick: (event: CalendarEvent) => void }) {
  const { events, currentWeek, removeEvent, showNoAttendees } = useCalendarStore();
  const { jobs } = useJobs();

  console.log('WeekViewContainer events:', events);
  console.log('WeekViewContainer currentWeek:', currentWeek?.format('YYYY-MM-DD'));

  // Filter and group events
  const groupedEvents = useMemo(() => {
    // Filter events based on showNoAttendees setting
    const filteredEvents = showNoAttendees
      ? events
      : events.filter((event) => event.attendees && event.attendees.length > 0);

    // Initialize empty arrays for each day
    const initial = WEEKDAYS.reduce(
      (acc, day) => {
        acc[day] = [];
        return acc;
      },
      {} as Record<string, CalendarEvent[]>,
    );

    // Group events by day using our utility
    const grouped = groupEventsByDate(filteredEvents, 'when.startTime', 'dddd');

    // Only keep weekdays and ensure all weekdays exist
    return WEEKDAYS.reduce((acc, day) => {
      acc[day] = grouped[day] || [];
      return acc;
    }, initial);
  }, [events, showNoAttendees]);

  // Sort events within each day by start time
  const sortedGroupedEvents = useMemo(() => {
    const sorted = { ...groupedEvents };
    Object.keys(sorted).forEach((day) => {
      sorted[day].sort((a, b) => toMillis(a.when.startTime) - toMillis(b.when.startTime));
    });
    return sorted;
  }, [groupedEvents]);

  const handleRemoveEvent = useCallback(
    (e: React.MouseEvent, event: CalendarEvent) => {
      e.stopPropagation();
      removeEvent(event.id);
    },
    [removeEvent],
  );

  const getJobTitle = useCallback(
    (jobId: string) => {
      const job = jobs.find((j) => j.id === jobId);
      return job?.jobTitle;
    },
    [jobs],
  );

  return (
    <WeekView
      currentWeek={currentWeek}
      groupedEvents={sortedGroupedEvents}
      onEventClick={onEventClick}
      onRemoveEvent={handleRemoveEvent}
      getJobTitle={getJobTitle}
    />
  );
}

export function CalendarPage() {
  const { currentWeek, setCurrentWeek } = useCalendarStore();
  const queryClient = useQueryClient();

  console.log('Current Week:', currentWeek?.format('YYYY-MM-DD'));

  const { isLoading, updateEvent } = useCalendarEvents(
    currentWeek.startOf('week').toDate(),
    currentWeek.endOf('week').toDate(),
  );
  const { companies } = useCompanies();
  const { jobs } = useJobs();
  const { functions } = useFirebase();
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSync = async () => {
    console.log('Syncing calendar...');
    setIsSyncing(true);
    try {
      const fetchEvents = httpsCallable(functions, 'fetchCalendarEvents');
      const response = await fetchEvents({
        start: currentWeek.startOf('week').toISOString(),
        end: currentWeek.endOf('week').toISOString(),
      });
      console.log('Sync response:', response);
      await queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    } catch (error) {
      console.error('Error syncing calendar:', error);
    } finally {
      setIsSyncing(false);
    }
  };

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  const handleEventUpdate = async (params: { id: string; data: Partial<CalendarEvent> }) => {
    return updateEvent(params);
  };

  const handleNextWeek = useCallback(() => {
    console.log('Next week clicked');
    const nextWeek = currentWeek.add(1, 'week');
    console.log('New week will be:', nextWeek.format('YYYY-MM-DD'));
    setCurrentWeek(nextWeek);
  }, [currentWeek, setCurrentWeek]);

  const handlePreviousWeek = useCallback(() => {
    console.log('Previous week clicked');
    const prevWeek = currentWeek.subtract(1, 'week');
    console.log('New week will be:', prevWeek.format('YYYY-MM-DD'));
    setCurrentWeek(prevWeek);
  }, [currentWeek, setCurrentWeek]);

  const handleEventClick = useCallback((event: CalendarEvent) => {
    setSelectedEvent(event);
    setOpen(true);
  }, []);

  return (
    <Layout
      title="Calendar"
      subtitle="If you see no events, connect your Google Calendar - if you don't see updated events, click on the sync button."
    >
      {(isLoading || isSyncing) && <LoadingOverlay />}
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <WeekNavigation currentWeek={currentWeek} onNextWeek={handleNextWeek} onPreviousWeek={handlePreviousWeek} />
            <Button
              color="primary"
              onClick={handleSync}
              startDecorator={<SyncRounded />}
              loading={isSyncing}
              variant="soft"
              size="sm"
            >
              Sync Calendar
            </Button>
          </Box>
          <CalendarFilter />
        </Box>

        <WeekViewContainer onEventClick={handleEventClick} />

        <Modal onClose={() => setOpen(false)} open={open}>
          <ModalDialog size="lg" sx={{ width: '25%' }}>
            {selectedEvent && (
              <EventDetailDialog
                companies={companies}
                event={selectedEvent}
                jobs={jobs}
                onClose={() => setOpen(false)}
                onEventUpdate={handleEventUpdate}
                open={open}
              />
            )}
          </ModalDialog>
        </Modal>
      </Box>
    </Layout>
  );
}

export default CalendarPage;

import { Company } from '@bluebird-monorepo/types';
import { Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

export function CompanyName({ company }: { company: Company }) {
  const navigate = useNavigate();

  const handleCompanyClick = () => {
    navigate(`/companies/${company.id}`);
  };

  return (
    <Typography
      level="body-sm"
      onClick={handleCompanyClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          color: 'primary.main',
          textDecoration: 'underline',
        },
      }}
    >
      <strong>{company.name}</strong>
    </Typography>
  );
}

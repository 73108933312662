import { BaseRepository } from '@bluebird-monorepo/bluebird-firebase';
import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { Firestore, serverTimestamp, WhereFilterOp } from 'firebase/firestore';

export class CandidateJobAssignmentRepository extends BaseRepository<CandidateJobAssignment> {
  constructor(db: Firestore) {
    super(db, 'candidateJobAssignments');
  }

  async upsertAssignment(id: string, assignment: Partial<CandidateJobAssignment>, owner: number): Promise<void> {
    const cleanAssignment = {
      ...assignment,
      owner,
      updatedAt: serverTimestamp(),
    };
    console.log('cleanAssignment', cleanAssignment);
    await this.update(id, cleanAssignment);
  }

  async getAssignmentsByJob(jobId: string): Promise<CandidateJobAssignment[]> {
    try {
      const constraints: { field: string; op: WhereFilterOp; value: any }[] = [
        { field: 'jobId', op: '==', value: jobId },
      ];
      return this.getAllForExtension(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching assignments by job');
    }
  }

  async getAssignmentsByOwner(ownerId: string): Promise<CandidateJobAssignment[]> {
    try {
      const constraints: { field: string; op: WhereFilterOp; value: any }[] = [
        { field: 'owner', op: '==', value: ownerId },
      ];
      return this.getAllForExtension(constraints);
    } catch (error) {
      throw this.handleError(error, 'Error fetching assignments by owner');
    }
  }
}

import { DayColumn, Dot } from '@bluebird-monorepo/bluebird-ui';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { EventCard } from './EventCard';
interface WeekViewProps {
  groupedEvents: Record<string, CalendarEvent[]>;
  currentWeek: dayjs.Dayjs;
  onEventClick: (event: CalendarEvent) => void;
  onRemoveEvent: (event: React.MouseEvent, eventToBeRemoved: CalendarEvent) => void;
  getJobTitle: (jobId: string) => string | undefined;
}

export function WeekView({ groupedEvents, currentWeek, onEventClick, onRemoveEvent, getJobTitle }: WeekViewProps) {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {groupedEvents &&
        Object.entries(groupedEvents)?.map(([day, events]) => (
          <DayColumn key={day}>
            {/* Day Header */}
            <Typography level="title-lg" sx={{ alignItems: 'center', display: 'flex', gap: 1, mb: 1 }}>
              {day} <Dot bgColor="#ff8300cc" height={7.5} width={7.5} />
            </Typography>
            <Typography fontSize="sm" sx={{ color: '#888', mb: 2 }}>
              {currentWeek.startOf('week').add(Object.keys(groupedEvents).indexOf(day), 'day').format('MMM DD')}
            </Typography>

            {/* Event Cards */}
            {events.length > 0 ? (
              events.map((calendarEvent) => (
                <EventCard
                  event={calendarEvent}
                  key={calendarEvent.id}
                  onEventClick={(calendarEvent) => onEventClick(calendarEvent)}
                  onRemoveEvent={(e, calendarEvent) => onRemoveEvent(e, calendarEvent)}
                  jobTitle={calendarEvent.jobId ? getJobTitle(calendarEvent.jobId) : undefined}
                />
              ))
            ) : (
              <Typography fontSize="sm" sx={{ color: '#888' }}>
                No meetings for this day
              </Typography>
            )}
          </DayColumn>
        ))}
    </Box>
  );
}

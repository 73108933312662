import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';

import styled from '@emotion/styled';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import React, { CSSProperties, ReactElement } from 'react';

import { useSelectionStore } from '../store/useSelectionStore';
import { CandidateCard } from '../types';
import CandidateAssignedToJobItem from './CandidateAssignedToJobItem';
import Title from './Title';

export const getBackgroundColor = (isDraggingOver: boolean, isDraggingFrom: boolean): string => {
  if (isDraggingOver) {
    return 'grey';
  }
  if (isDraggingFrom) {
    return '#b0b1b5';
  }
  return '#f9f9f9';
};

interface WrapperProps {
  isDraggingFrom: boolean;
  isDraggingOver: boolean;
  isDropDisabled: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: 15px;
  border: 2px;
  padding-bottom: 0;
  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;
  width: 285px;
  border-radius: 15px;
`;

const scrollContainerHeight = 650;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: 15px;
  > * {
    margin-bottom: 15px;
  }
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

const Container = styled.div``;

interface Props {
  ignoreContainerClipping?: boolean;
  internalScroll?: boolean;
  isCombineEnabled?: boolean;
  isDropDisabled?: boolean;
  isDraggingSelected?: boolean;
  listId?: string;
  listType?: string;
  cards: CandidateCard[];
  scrollContainerStyle?: CSSProperties;
  style?: CSSProperties;
  title?: string;
  useClone?: boolean;
}

interface CardListProps {
  cards: CandidateCard[];
  isDraggingSelected?: boolean;
  listId: string;
}

function InnerCardList(props: CardListProps): ReactElement {
  const { cards, isDraggingSelected, listId } = props;
  const selectedItems = useSelectionStore((state) => state.selectedItems);
  const setColumnItems = useSelectionStore((state) => state.setColumnItems);

  // Update column items whenever cards change
  React.useEffect(() => {
    const columnItems = cards.map((card) => `${card.jobId}_${card.candidateId}`);
    setColumnItems(listId, columnItems);
  }, [cards, setColumnItems, listId]);

  return (
    <>
      {cards.map((card: CandidateCard, index: number) => {
        const id = `${card.jobId}_${card.candidateId}`;
        const isSelected = selectedItems.has(id);

        return (
          <Draggable
            draggableId={'candidateId:' + card.candidateId + '&&jobId:' + card.jobId}
            index={index}
            key={card.id}
          >
            {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
              <CandidateAssignedToJobItem
                isDragging={dragSnapshot.isDragging}
                isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                key={card.id}
                provided={dragProvided}
                card={card}
                columnId={listId}
                style={{
                  opacity: isDraggingSelected && !isSelected ? 0.5 : 1,
                  transform: isDraggingSelected && isSelected ? 'scale(0.95)' : 'none',
                  transition: 'opacity 0.2s ease, transform 0.2s ease',
                }}
              />
            )}
          </Draggable>
        );
      })}
    </>
  );
}

const InnerCardListMemo = React.memo<CardListProps>(InnerCardList);

interface InnerListProps {
  dropProvided: DroppableProvided;
  cards: CandidateCard[];
  title: null | string | undefined;
  isDraggingSelected?: boolean;
  listId: string;
}

function InnerList(props: InnerListProps) {
  const { dropProvided, cards, isDraggingSelected, listId } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;

  return (
    <Container>
      {title}
      <DropZone ref={dropProvided.innerRef}>
        <InnerCardListMemo cards={cards} isDraggingSelected={isDraggingSelected} listId={listId} />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
}

export default function CandidateAssignedToJobList(props: Props): ReactElement {
  const {
    ignoreContainerClipping,
    internalScroll,
    isCombineEnabled,
    isDropDisabled,
    isDraggingSelected,
    listId = 'LIST',
    listType,
    cards,
    scrollContainerStyle,
    style,
    title,
    useClone,
  } = props;
  return (
    <Droppable
      droppableId={listId?.toString()}
      ignoreContainerClipping={ignoreContainerClipping}
      isCombineEnabled={isCombineEnabled}
      isDropDisabled={isDropDisabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <CandidateAssignedToJobItem
                isClone
                isDragging={snapshot.isDragging}
                provided={provided}
                card={cards[descriptor.source.index]}
                columnId={listId}
              />
            )
          : undefined
      }
      type={listType}
    >
      {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
        <Wrapper
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={Boolean(isDropDisabled)}
          style={style}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                dropProvided={dropProvided}
                cards={cards}
                title={title}
                isDraggingSelected={isDraggingSelected}
                listId={listId}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              dropProvided={dropProvided}
              cards={cards}
              title={title}
              isDraggingSelected={isDraggingSelected}
              listId={listId}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
}

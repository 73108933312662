import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { Job } from '@bluebird-monorepo/types';
import { useUser } from '@bluebird-monorepo/users';
import { CalendarToday } from '@mui/icons-material';
import { Box, Stack, Tooltip } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationColumn } from './columns/LocationColumn';
import { ResponsibleColumn } from './columns/ResponsibleColumn';
import { StatusColumn } from './columns/StatusColumn';
import { WorkingOnColumn } from './columns/WorkingOnColumn';

export function JobsTableRow({ job }: { job: Job }) {
  const navigate = useNavigate();
  const [showCopied, setShowCopied] = useState(false);
  const { auth } = useFirebase();
  const { user } = useUser(auth);

  const handleRowClick = () => {
    navigate(`/jobs/${job.id}`);
  };

  const handleCopyReference = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent row click event
    try {
      const copyText = `${user?.calendlyUrl || ''}?${user?.answerNumber || ''}=${job.referenceNumber}`;
      await navigator.clipboard.writeText(copyText);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy reference number:', err);
    }
  };

  return (
    <tr
      style={{
        transition: 'background-color 0.2s ease',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = 'var(--joy-palette-background-level1)')}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'white')}
    >
      <ResponsibleColumn job={job} />
      <WorkingOnColumn job={job} />
      <StatusColumn job={job} />
      <td style={{ cursor: 'pointer' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title={showCopied ? 'Copied!' : 'Click to copy'} placement="top">
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{
                  backgroundColor: 'background.level1',
                  px: 1,
                  py: 0.5,
                  borderRadius: 'sm',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'background.level2',
                  },
                }}
                onClick={handleCopyReference}
              >
                <Typography
                  level="body-xs"
                  sx={{
                    fontWeight: '600',
                    color: 'text.secondary',
                  }}
                >
                  #{job.referenceNumber}
                </Typography>
                <CalendarToday
                  sx={{
                    fontSize: '14px',
                    color: 'text.secondary',
                  }}
                />
              </Stack>
            </Tooltip>
            <Typography
              onClick={handleRowClick}
              level="body-sm"
              sx={{
                fontWeight: '600',
                color: 'primary.main',
              }}
            >
              {job.jobTitle}
            </Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: '500',
            color: 'text.primary',
          }}
        >
          {job.companyName}
        </Typography>
      </td>
      <LocationColumn job={job} />
      <td>
        <Typography level="body-xs">{job.candidates}</Typography>
      </td>
      <td>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CalendarToday sx={{ fontSize: 'small', color: 'text.secondary' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
              {job.eventStats?.totalEvents || 0} total
            </Typography>
            {job.eventStats?.scheduledEvents && job.eventStats.scheduledEvents > 0 && (
              <Typography level="body-sm" startDecorator={<CalendarToday />}>
                {job.eventStats.scheduledEvents} upcoming
              </Typography>
            )}
          </Box>
        </Box>
      </td>
    </tr>
  );
}

import { CustomCheckbox } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdCard, RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { Job } from '@bluebird-monorepo/types';
import { formatCurrency, languageCountryMap, languageMap } from '@bluebird-monorepo/utils';
import styled from '@emotion/styled';
import { PlaceOutlined, Savings } from '@mui/icons-material';
import { Box, CardContent, Chip, Divider, List, ListItem, Stack, Typography, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

interface JobInfoCardProps {
  job: Job;
}

const StyledInfoBox = styled.div`
  color: #414c4c;
  border-radius: 7px;
  padding: 2.5px 5px;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
`;

const JobInfoCard: FC<JobInfoCardProps> = ({ job }) => {
  console.log(job);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm')); // 0-600px
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600-900px
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900-1200px

  // Layout configuration based on screen size
  const layoutConfig = {
    gridColumns: {
      xs: '1fr',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)',
    },
    spacing: {
      xs: 2,
      sm: 3,
      md: 4,
    },
    fontSize: {
      title: {
        xs: 14,
        sm: 15,
        md: 16,
      },
      body: {
        xs: 13,
        sm: 14,
        md: 16,
      },
      subtitle: {
        xs: 11,
        sm: 12,
        md: 12.8,
      },
    },
  };

  function getCountryCode(lang: any): string {
    return (
      languageCountryMap.find((mappedValue) => {
        if (mappedValue.languageCode == lang) {
          return mappedValue;
        } else {
          return false;
        }
      })?.countryCode || ''
    );
  }

  const [isResponsibilitiesCollapsed, setResponsibilitiesCollapsed] = useState(true);

  return (
    <BluebirdCard>
      <CardContent>
        <Stack gap={layoutConfig.spacing}>
          {/* Top Info Grid */}
          <Box
            sx={{
              display: 'grid',
              gap: { xs: 2, sm: 2.5, md: 3 },
              gridTemplateColumns: layoutConfig.gridColumns,
              width: 'fit-content',
            }}
          >
            {/* Location */}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: { xs: 'start', sm: 'start' },
              }}
            >
              <Typography
                sx={{
                  alignItems: 'center',
                  color: '#8f989f',
                  display: 'flex',
                  fontSize: layoutConfig.fontSize.body,
                  gap: '0.25rem',
                }}
              >
                <PlaceOutlined sx={{ fontSize: '1.25rem' }} /> {job?.location}
              </Typography>
            </Box>

            {/* Salary */}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: { xs: 'start', sm: 'center' },
              }}
            >
              <Typography
                sx={{
                  alignItems: 'center',
                  color: '#8f989f',
                  display: 'flex',
                  fontSize: layoutConfig.fontSize.body,
                  gap: '0.5rem',
                }}
              >
                <Savings sx={{ fontSize: '1.25rem' }} />
                {job?.salaryRange ? (
                  <>
                    {formatCurrency(job.salaryRange.min)} - {formatCurrency(job.salaryRange.max)}
                  </>
                ) : (
                  'No salary'
                )}
              </Typography>
            </Box>

            {/* Contract Type */}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: { xs: 'start', sm: 'center' },
              }}
            >
              <Typography
                sx={{
                  alignItems: 'center',
                  color: '#8f989f',
                  display: 'flex',
                  fontSize: layoutConfig.fontSize.body,
                  gap: '0.5rem',
                }}
              >
                Type:
              </Typography>
              <StyledInfoBox>{job?.contractType}</StyledInfoBox>
            </Box>
          </Box>

          {/* Description Sections */}
          <Stack spacing={{ xs: 2, sm: 3, md: 4 }}>
            {/* Role Description */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Role description
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  color: '#212529bf',
                  fontSize: layoutConfig.fontSize.body,
                }}
              >
                <RawHTML children={job?.roleDescription || 'No role description'} />
              </Typography>
            </Box>

            {/* Product Description */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Product
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  color: '#212529bf',
                  fontSize: layoutConfig.fontSize.body,
                }}
              >
                <RawHTML children={job?.productDescription || 'No product description'} />
              </Typography>
            </Box>

            {/* Preferred Languages */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 4, md: 8 },
                mt: 2,
              }}
            >
              <Box sx={{ flex: { xs: '1', sm: '0 0 200px' } }}>
                <Typography
                  level="h4"
                  sx={{
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    mb: 1,
                  }}
                >
                  Preferred Languages
                </Typography>
                <Typography
                  sx={{
                    color: '#212529bf',
                    fontSize: layoutConfig.fontSize.subtitle,
                  }}
                >
                  What are the preferred languages for this role?
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <List>
                  {job?.preferredLanguages?.map((lang, index) => (
                    <ListItem key={index} sx={{ gap: 1 }}>
                      <ReactCountryFlag countryCode={getCountryCode(lang.toLowerCase())} svg />
                      {languageMap[lang.toLowerCase() as keyof typeof languageMap]}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>

            <Divider />

            {/* Company Info */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Unique about the company
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  color: '#212529bf',
                  fontSize: layoutConfig.fontSize.body,
                }}
              >
                <RawHTML children={job?.uniqueAboutCompany || 'No unique about the company'} />
              </Typography>
            </Box>

            {/* Assistance Section */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 4, md: 8 },
                mt: 2,
              }}
            >
              <Box sx={{ flex: { xs: '1', sm: '0 0 200px' } }}>
                <Typography
                  level="h4"
                  sx={{
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    mb: 1,
                  }}
                >
                  Assistance
                </Typography>
                <Typography
                  sx={{
                    color: '#212529bf',
                    fontSize: layoutConfig.fontSize.subtitle,
                  }}
                >
                  Does the company offer the following services?
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Stack spacing={1}>
                  <CustomCheckbox
                    checked={true}
                    color={job?.offersRelocation ? 'default' : 'red'}
                    label="Offers relocation"
                  />
                  <CustomCheckbox
                    checked={true}
                    color={job?.providesVisaSponsorship ? 'default' : 'red'}
                    label="Provides visa sponsorship"
                  />
                </Stack>
              </Box>
            </Box>

            <Divider />

            {/* Tech Stack */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Tech stack
              </Typography>
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1.5}
                sx={{
                  '& .MuiChip-root': {
                    fontSize: layoutConfig.fontSize.body,
                  },
                }}
              >
                {job?.techStack?.map((tech, index) => (
                  <Chip key={index} sx={{ px: 1, py: 0.2 }} variant="soft">
                    {tech}
                  </Chip>
                ))}
                {!job?.techStack?.length && <Typography>No added technologies</Typography>}
              </Stack>
            </Box>

            {/* Extra Information */}
            {job?.extraInformation && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  level="h4"
                  sx={{
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    mb: { xs: 1, sm: 1.5, md: 2 },
                  }}
                >
                  Extra information you have to know
                </Typography>
                <RawHTML
                  style={{
                    color: '#212529bf',
                    fontSize: 'var(--joy-fontSize-md)',
                  }}
                  children={job?.extraInformation}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </BluebirdCard>
  );
};

export default JobInfoCard;

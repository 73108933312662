import { Button } from '@mui/joy';
import { styled } from '@mui/joy/styles';

const gradients = {
  create: {
    default: 'linear-gradient(45deg, var(--joy-palette-primary-500), var(--joy-palette-primary-600))',
    hover: 'linear-gradient(45deg, var(--joy-palette-primary-600), var(--joy-palette-primary-700))',
  },
  connect: {
    default: 'linear-gradient(45deg, var(--joy-palette-success-500), var(--joy-palette-success-600))',
    hover: 'linear-gradient(45deg, var(--joy-palette-success-600), var(--joy-palette-success-700))',
  },
  delete: {
    default: 'linear-gradient(45deg, var(--joy-palette-danger-500), var(--joy-palette-danger-600))',
    hover: 'linear-gradient(45deg, var(--joy-palette-danger-600), var(--joy-palette-danger-700))',
  },
  export: {
    default: 'linear-gradient(45deg, var(--joy-palette-neutral-500), var(--joy-palette-neutral-600))',
    hover: 'linear-gradient(45deg, var(--joy-palette-neutral-600), var(--joy-palette-neutral-700))',
  },
  warning: {
    default: 'linear-gradient(45deg, var(--joy-palette-warning-500), var(--joy-palette-warning-600))',
    hover: 'linear-gradient(45deg, var(--joy-palette-warning-600), var(--joy-palette-warning-700))',
  },
};

export const StyledButton = styled(Button)(
  ({ customVariant = 'create' }: { customVariant?: 'create' | 'connect' | 'delete' | 'export' | 'warning' }) => ({
    boxShadow: 'sm',
    background: gradients[customVariant].default,
    '&:hover': {
      background: gradients[customVariant].hover,
    },
    color: 'white',
  }),
);

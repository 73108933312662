import styled from '@emotion/styled';

interface DotProps {
  height: number;
  width: number;
  bgColor: string;
}

export const Dot = styled.span<DotProps>`
  height: ${({ height }) => height + 'px' || '10px'}; // default 10px
  width: ${({ width }) => width + 'px' || '10px'}; // default 10px
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  display: inline-block;
`;

import { Job } from '@bluebird-monorepo/types';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import Link from '@mui/joy/Link';

type Order = 'asc' | 'desc';

type JobsTableHeaderProps = {
  onSort: (property: string) => void;
  order: Order;
  orderBy: string;
};

export function JobsTableHeader({ onSort, order, orderBy }: JobsTableHeaderProps) {
  const createSortHandler = (property: string) => () => {
    onSort(property);
  };
  const renderSortIcon = (column: keyof Job) => {
    return orderBy === column ? (
      order === 'asc' ? (
        <ArrowDropUpRounded color="primary" sx={{ height: 30, width: 30 }} />
      ) : (
        <ArrowDropDownRounded color="primary" sx={{ height: 30, width: 30 }} />
      )
    ) : null;
  };
  return (
    <thead>
      <tr>
        <th
          onClick={createSortHandler('responsible')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '10%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Responsible
            {renderSortIcon('responsible')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('workingOn')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '15%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Working On
            {renderSortIcon('workingOn')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('state')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '15%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Status
            {renderSortIcon('status')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('jobTitle')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '25%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Job Title
            {renderSortIcon('jobTitle')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('companyName')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '15%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Company
            {renderSortIcon('companyName')}
          </Link>
        </th>

        <th
          onClick={createSortHandler('location')}
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '15%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Location
            {renderSortIcon('location')}
          </Link>
        </th>

        <th
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '5%',
          }}
        >
          Candidates
        </th>
        <th
          style={{
            padding: '12px 6px',
            verticalAlign: 'middle',
            width: '10%',
          }}
        >
          <Link color="primary" component="button" fontWeight="lg" underline="none">
            Events
          </Link>
        </th>
      </tr>
    </thead>
  );
}

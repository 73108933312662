import { useFirebase } from '@bluebird-monorepo/bluebird-firebase';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { TechnologiesRepository } from '../repositories/technologies.repository';

export const useTechnologies = () => {
  const { firestore } = useFirebase();
  const repository = useMemo(() => new TechnologiesRepository(firestore), [firestore]);

  const query = useQuery({
    queryKey: ['technologies'],
    queryFn: () => repository.getAll(),
  });

  return {
    ...query,
    technologies: query.data || [],
  };
};

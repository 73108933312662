import styled from '@emotion/styled';

export default styled.h4`
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  margin: 0.25rem;
  &:focus {
    outline: 2px solid #b3d1ff;
    outline-offset: 2px;
  }
`;

import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { create } from 'zustand';

interface CandidateJobAssignmentState {
  assignments: CandidateJobAssignment[];
  setAssignments: (assignments: CandidateJobAssignment[]) => void;
  addAssignment: (assignment: CandidateJobAssignment) => void;
  updateAssignment: (
    id: string,
    assignment: Partial<CandidateJobAssignment>
  ) => void;
  removeAssignment: (id: string) => void;
}

export const useCandidateJobAssignmentStore =
  create<CandidateJobAssignmentState>((set) => ({
    assignments: [],
    setAssignments: (assignments) => set({ assignments }),
    addAssignment: (assignment) =>
      set((state) => ({ assignments: [...state.assignments, assignment] })),
    updateAssignment: (id, updatedAssignment) =>
      set((state) => ({
        assignments: state.assignments.map((assignment) =>
          assignment.id === id
            ? { ...assignment, ...updatedAssignment }
            : assignment
        ),
      })),
    removeAssignment: (id) =>
      set((state) => ({
        assignments: state.assignments.filter(
          (assignment) => assignment.id !== id
        ),
      })),
  }));

import { useStorage } from '@bluebird-monorepo/bluebird-firebase';
import { FileUpload } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { Company } from '@bluebird-monorepo/types';
import { AttachFile } from '@mui/icons-material';
import { Box, CardContent, Divider, Typography } from '@mui/joy';
import React from 'react';

interface CompanyFilesCardProps {
  company: Company;
}

export const CompanyFilesCard: React.FC<CompanyFilesCardProps> = ({ company }) => {
  const { uploadFiles } = useStorage();

  const handleSaveFiles = async (files: File[]) => {
    try {
      await uploadFiles(files, `companies/${company.id}/files`);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <BluebirdCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
        <Typography level="h4" startDecorator={<AttachFile />}>
          Files & Documents
        </Typography>
      </Box>
      <Divider inset="none" />
      <CardContent>
        <FileUpload onSave={handleSaveFiles} />
      </CardContent>
    </BluebirdCard>
  );
};
